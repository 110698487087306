import React, { useState } from 'react';
import classnames from 'classnames';
import { CloseSmallIcon } from 'DesignSystem/Icons';
import TextField from './TextField';
import styles from './TextField.module.scss';

export interface TextInputProps {
  onClose?: () => void;
  onFocus?: () => void;
  role?: string;
  showCloseIcon?: boolean;
  textInputWrapperClass?: string;
  [key: string]: unknown;
}

export const TextInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<TextInputProps>
>(
  (
    {
      children,
      onClose,
      onFocus,
      showCloseIcon = false,
      textInputWrapperClass,
      ...rest
    }: React.PropsWithChildren<TextInputProps>,
    ref
  ): JSX.Element => {
    const [focused, setFocus] = useState(false);
    const classes = classnames(styles['input-wrapper'], textInputWrapperClass, {
      [styles['input-focused']]: focused,
      [styles['reduced-padding']]: showCloseIcon,
    });

    const onFocusHandler = () => {
      onFocus?.();
      setFocus(true);
    };

    return (
      // Below error should be fixed when TextField is converted to typescript
      // @ts-expect-error FIXME: expect children prop
      <TextField {...rest}>
        {(inputProps: React.HTMLProps<HTMLInputElement>) => (
          <div className={classes}>
            <div className={styles['input-inner-flex']}>
              {children && (
                <span className={styles['input-children']}>{children}</span>
              )}
              <input
                {...inputProps}
                ref={ref}
                onBlur={() => setFocus(false)}
                onFocus={onFocusHandler}
              />
              {showCloseIcon && (
                <button
                  className={classnames(styles['text-input-close-button'], {
                    [styles.hide]: !inputProps.value,
                  })}
                  data-testid="text-input-close-button"
                  onClick={onClose}
                  type="button"
                >
                  <CloseSmallIcon />
                </button>
              )}
            </div>
          </div>
        )}
      </TextField>
    );
  }
);
