import React from 'react';
import Image from 'Image';
import { Row } from 'react-bootstrap';

export const Press = (): JSX.Element => (
  <Row className="dark-background justify-content-center press-logos">
    <Image
      alt="Inc."
      height="28"
      src="/static/images/press/inc-logo-white.png"
      width="81"
    />
    <Image
      alt="Forbes"
      height="24"
      src="/static/images/press/forbes-logo-white.png"
      width="103"
    />
    <Image
      alt="Fast Company"
      height="28"
      src="/static/images/press/fastcompany-logo-white.png"
      width="182"
    />
    <Image
      alt="TechCrunch"
      height="28"
      src="/static/images/press/techcrunch-logo-white.png"
      width="186"
    />
    <Image
      alt="The Huffington Post"
      height="28"
      src="/static/images/press/huffingtonpost-logo-white.png"
      width="68"
    />
  </Row>
);
