import React, { useEffect, useReducer } from 'react';
import { createContext } from 'use-context-selector';
import { Action, ISearchContext } from '../types';
import searchContextReducer, {
  initSearchContextActions,
} from './searchContextReducer';
import { FOCUS } from './Constants';

// use of "any" is unavoidable here to make the search context agnostic to the results
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchContext = createContext<ISearchContext<any>>({
  searchFocus: FOCUS.NONE,
  searchTerm: '',
  status: 'idle',
});

export interface SearchProviderProps {
  debounceRate?: number;
}

/**
 * @deprecated Please see components/DocumentLibrary/index.tsx for an example of
 * how to implement search with SearchInput
 */
export const SearchProvider: React.FC<SearchProviderProps> = <ResultType,>({
  children,
  debounceRate = 0,
}: React.PropsWithChildren<SearchProviderProps>): JSX.Element => {
  const [contextValue, dispatch] = useReducer<
    React.Reducer<ISearchContext<ResultType>, Action<ResultType>>
  >(searchContextReducer, {
    searchFocus: FOCUS.NONE,
    searchTerm: '',
    status: 'idle',
  });

  /*
    The debounceRate here is not used for the Tax address search components.
    Instead, we handle debouncing the function ourselves in
    BaseAddressSearchInput.
  */
  useEffect(() => {
    // Install the search actions to the reducer.
    initSearchContextActions(dispatch, debounceRate);
  }, [debounceRate]);

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};
