import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Dropdown } from 'react-bootstrap';
import classnames from 'classnames';
import { CaretDown } from 'DesignSystem/Icons';
import baseButtonStyles from 'DesignSystem/Buttons/BaseButton/index.module.scss';
import styles from './BaseSplitButton.module.scss';
import PrimaryButton from '../PrimaryButton';

const ButtonSizes = ['lg', 'md', 'sm', 'xs'];

export const Toggle = React.forwardRef(
  ({ Button, dataTestid, onClick, size }, ref) => {
    return (
      <Button
        buttonRef={ref}
        className={classnames(
          baseButtonStyles['ds-split-button'],
          baseButtonStyles.toggle
        )}
        dataTestid={dataTestid}
        onClick={onClick}
        size={size}
        type="button"
      >
        <CaretDown />
      </Button>
    );
  }
);

Toggle.displayName = 'Toggle';

Toggle.defaultProps = {
  onClick: null,
};

Toggle.propTypes = {
  Button: PropTypes.oneOf([PrimaryButton]).isRequired,
  dataTestid: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(ButtonSizes).isRequired,
};

export const SplitButtonMenuItem = ({
  children,
  className,
  eventKey,
  id,
  onSelect,
  tabIndex,
}) => {
  return (
    <Dropdown.Item
      className={classnames(className, styles['menu-item'])}
      eventKey={eventKey}
      id={id}
      onSelect={onSelect}
      role="menuitem"
      tabIndex={tabIndex}
    >
      {children}
    </Dropdown.Item>
  );
};

SplitButtonMenuItem.defaultProps = {
  children: null,
  className: '',
  id: undefined,
  onSelect: null,
};

SplitButtonMenuItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  eventKey: PropTypes.string.isRequired,
  id: PropTypes.string,
  onSelect: PropTypes.func,
  tabIndex: PropTypes.string.isRequired,
};

export const BaseSplitButton = ({
  Button,
  children,
  dataTestIdButton,
  dataTestIdToggle,
  disabled,
  icon,
  id,
  onClick,
  size,
  title,
}) => {
  return (
    <ButtonToolbar>
      <Dropdown className={styles.dropdown} id={id}>
        <Button
          className={baseButtonStyles['ds-split-button']}
          dataTestid={dataTestIdButton}
          disabled={disabled}
          icon={icon}
          onClick={onClick}
          size={size}
        >
          {title}
        </Button>
        <Dropdown.Toggle
          as={Toggle}
          Button={Button}
          dataTestid={dataTestIdToggle}
          size={size}
        />
        <Dropdown.Menu className={styles.menu} role="menu">
          {children}
        </Dropdown.Menu>
      </Dropdown>
    </ButtonToolbar>
  );
};

BaseSplitButton.defaultProps = {
  children: null,
  disabled: false,
  icon: null,
  onClick: null,
};

BaseSplitButton.propTypes = {
  Button: PropTypes.oneOf([PrimaryButton]).isRequired,
  children: PropTypes.arrayOf(SplitButtonMenuItem),
  dataTestIdButton: PropTypes.string.isRequired,
  dataTestIdToggle: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(ButtonSizes).isRequired,
  title: PropTypes.string.isRequired,
};
