import { useDecision } from '@optimizely/react-sdk';
import { SnapEngageChat } from 'Chat/SnapEngageChat';
import { ZoomChat } from 'Chat/ZoomChat';
import React from 'react';

/**
 * ChatProviderSelector uses Optimizely to select which chat provider to load. It
 * loads SnapEngage by default and Zoom Chat if the feature is enabled.
 */
export const ChatProviderSelector: React.FC = () => {
  const [
    { variationKey: chatVariationKey },
    clientReady,
    didTimeout,
  ] = useDecision('dbx_zoom_chat');

  // Return nothing until the client is ready or the decision has timed out.
  if (!clientReady && !didTimeout) {
    return null;
  }
  if (chatVariationKey === 'on') {
    return <ZoomChat />;
  }
  return <SnapEngageChat />;
};
