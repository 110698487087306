import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { trackEvent } from 'Tracking/Actions';

export const MEMBERS_AREA_TABS = {
  'document-library': {
    path: '/document-library',
    title: 'Document Library',
    trackingKey: 'Document Library',
  },
  'my-account': {
    path: '/my-account',
    title: 'Account Settings',
    trackingKey: 'Account Settings',
  },
  'my-documents': {
    path: '/my-documents',
    title: 'My Documents',
    trackingKey: 'My Documents',
  },
  'my-faxes': {
    path: '/my-faxes',
    title: 'Online Fax',
    trackingKey: 'Online Fax',
  },
  'my-fillable-fields': {
    path: '/myFillableFields.php',
    title: 'Fillable Fields',
    trackingKey: 'Templates',
  },
};

export const MembersAreaLayoutTabs = ({ activeKey }) => {
  const dispatch = useDispatch();
  const faxNumber = useSelector(
    ({ UserHandler }) => UserHandler.userInfo.faxNumber
  );
  const hasPaidAccess = useSelector(
    ({ UserHandler }) => UserHandler.userInfo.hasPaidAccess
  );
  const isCurrentFaxUser = faxNumber && hasPaidAccess;

  const onTabClick = (eventKey) => {
    if (eventKey !== activeKey) {
      dispatch(
        trackEvent(
          `${MEMBERS_AREA_TABS[eventKey].trackingKey} Tab`,
          MEMBERS_AREA_TABS[activeKey].trackingKey
        )
      );
      window.location.assign(MEMBERS_AREA_TABS[eventKey].path);
    }
  };

  const tab = (eventKey) => (
    <Tab eventKey={eventKey} title={MEMBERS_AREA_TABS[eventKey].title} />
  );

  return (
    <Tabs
      activeKey={activeKey}
      className="members-area-tabs"
      id="members-area-tabs"
      onSelect={onTabClick}
    >
      {tab('my-documents')}
      {tab('my-fillable-fields')}
      {tab('document-library')}
      {isCurrentFaxUser && tab('my-faxes')}
      {tab('my-account')}
    </Tabs>
  );
};

MembersAreaLayoutTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
};
