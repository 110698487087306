import { gql } from '@apollo/client';

const IsCancelledFragment = gql`
  fragment IsCancelledFragment on User {
    isCancelled
  }
`;
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default IsCancelledFragment;
