import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const PrintIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M11.5555556,16 L4.44444444,16 L4.44444444,10.3064699 L11.5555556,10.3064699 L11.5555556,16 Z M13.3333333,13.153235 L13.3333333,8.40862656 L2.66666667,8.40862656 L2.66666667,13.153235 L1.77777778,13.153235 C0.795938223,13.153235 3.64153152e-13,12.3035415 3.64153152e-13,11.2553916 L3.64153152e-13,5.56186152 C3.64153152e-13,4.51371158 0.795938223,3.66401816 1.77777778,3.66401816 L2.66666667,3.66401816 L2.66666667,5.56186152 L13.3333333,5.56186152 L13.3333333,3.66401816 L14.2222222,3.66401816 C15.2040618,3.66401816 16,4.51371158 16,5.56186152 L16,11.2553916 C16,12.3035415 15.2040618,13.153235 14.2222222,13.153235 L13.3333333,13.153235 Z M4.44444444,3.66401816 L4.44444444,3.55271368e-15 L11.5555556,3.55271368e-15 L11.5555556,3.66401816 L4.44444444,3.66401816 Z"
        fillRule="nonzero"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default PrintIcon;
