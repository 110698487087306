import React from 'react';
import Image from 'Image';
import classNames from 'classnames';
import styles from './freemium-menu-icon.module.scss';

const FreemiumMenuIcon = ({
  credits,
  isCurrentSubscriber,
}: {
  credits: number;
  isCurrentSubscriber: boolean;
}): JSX.Element => {
  const iconSrc = isCurrentSubscriber
    ? '/static/images/freemium-menu/icon-paid.png'
    : '/static/images/freemium-menu/icon-free.png';
  const creditsBadgeClassNames = classNames(
    styles['freemium-menu-icon-credits-badge'],
    {
      'zero-credits': credits === 0,
    }
  );

  return (
    <div className={styles['freemium-menu-icon-credits-badge-wrapper']}>
      {!isCurrentSubscriber && (
        <span className={creditsBadgeClassNames}>{credits}</span>
      )}
      <Image
        alt="Menu Icon"
        className={styles['freemium-menu-icon']}
        src={iconSrc}
      />
    </div>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FreemiumMenuIcon;
