import React, { ReactNode } from 'react';
import { Footer, RedesignedFooter } from 'Footer';
import { LayoutType } from 'layouts';
import filterPropsWithPropTypes from 'layouts/filterPropsWithPropTypes';
import { useFeature } from '@optimizely/react-sdk';
import Header from 'Header';
import DefaultLayoutProps from './DefaultLayoutProps';
import styles from './index.module.scss';
import SiteWrapper from '../SiteWrapper';

export interface DefaultLayoutProps {
  children?: ReactNode;
  countryIsoAlpha2?: string;
  defaultDocumentTypeFromCookie?: string;
  dbxConsentCookie?: string;
  documentTypeFromUrlQuery?: string;
  hideAccount?: boolean;
  loggedIn?: boolean;
  title?: string;
  trackUnbounce?: boolean;
}

const DefaultLayout: LayoutType<DefaultLayoutProps> = ({
  children = null,
  countryIsoAlpha2 = '',
  dbxConsentCookie,
  defaultDocumentTypeFromCookie = '',
  documentTypeFromUrlQuery = '',
  hideAccount = false,
  loggedIn = false,
  title = '',
  trackUnbounce = false,
}: DefaultLayoutProps = {}) => {
  const [isDropboxDesignSystemEnabled] = useFeature('dropbox_design_system');

  return (
    <SiteWrapper
      {...{
        countryIsoAlpha2,
        dbxConsentCookie,
        defaultDocumentTypeFromCookie,
        documentTypeFromUrlQuery,
        loggedIn,
        title,
        trackUnbounce,
      }}
    >
      <Header hideAccount={hideAccount} />
      <main
        className={styles['default-layout-main-container']}
        data-testid="default-layout"
      >
        {children}
      </main>
      {isDropboxDesignSystemEnabled ? <RedesignedFooter /> : <Footer />}
    </SiteWrapper>
  );
};

DefaultLayout.getLayoutProps = (props: Partial<Record<string, unknown>>) =>
  filterPropsWithPropTypes(props, DefaultLayoutProps);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default DefaultLayout;
