import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const EraseIcon = ({ ...props }: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <path
      clipRule="evenodd"
      d="M2.20432 9.38866L6.35296 13.0837L12.7993 5.84587L8.65066 2.15088L2.20432 9.38866ZM4.92044 14.692C5.83693 15.5083 7.22126 15.4499 8.01242 14.5616L14.4588 7.32387C15.2499 6.43557 15.1483 5.05375 14.2318 4.23747L10.0832 0.542478C9.16669 -0.273797 7.78237 -0.215415 6.99121 0.672878L0.544863 7.91066C-0.246296 8.79895 -0.144693 10.1808 0.771799 10.9971L4.92044 14.692Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4.46867 5.73761L10.0327 10.6932L8.61682 12.283L3.05278 7.32734L4.46867 5.73761Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M2 15C2 14.4477 2.44772 14 3 14L12 14C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16L3 16C2.44772 16 2 15.5523 2 15Z"
      fillRule="evenodd"
    />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default EraseIcon;
