import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TertiaryButton } from 'DesignSystem/Buttons';
import styles from './TooltipTertiaryButton.module.scss';

export const TooltipTertiaryButton = ({
  className,
  closeTooltipOnClick,
  copy,
  dataTestid,
  enableCloseTooltip,
  onClick,
}) => {
  const tertiaryClasses = classNames(
    styles['ds-tooltip-tertiary-btn'],
    className
  );

  const handleOnClick = () => {
    onClick();

    if (enableCloseTooltip) {
      closeTooltipOnClick();
    }
  };

  return (
    <div className="ds-tooltip-btn-row">
      <TertiaryButton
        className={tertiaryClasses}
        dataTestid={dataTestid}
        onClick={handleOnClick}
      >
        {copy}
      </TertiaryButton>
    </div>
  );
};

TooltipTertiaryButton.defaultProps = {
  className: '',
  closeTooltipOnClick: () => {},
  enableCloseTooltip: false,
  onClick: () => {},
};

TooltipTertiaryButton.propTypes = {
  className: PropTypes.string,
  closeTooltipOnClick: PropTypes.func,
  copy: PropTypes.string.isRequired,
  dataTestid: PropTypes.string.isRequired,
  enableCloseTooltip: PropTypes.bool,
  onClick: PropTypes.func,
};
