import PropTypes from 'prop-types';
import { withTypography } from './withTypography';

export const HeadingG = withTypography('h6', { className: 'ds-heading-g' });

HeadingG.defaultProps = {
  className: '',
  dataTestid: '',
};

// Also accepts all <h6 /> attributes
HeadingG.propTypes = {
  className: PropTypes.string,
  dataTestid: PropTypes.string,
};
