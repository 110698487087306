import React, { useState } from 'react';
import { Banner } from 'DesignSystem/Banner';
import { ProductContent } from 'DesignSystem/Typography';
import { useRouter } from 'next/router';
import { handleAutocreateCtaClick } from 'MyDocuments/AutocreateModal';
import useTrack from 'lib/tracking/useTrack';
import styles from './index.module.scss';

export const AutocreateBanner = ({
  eligibleDocumentNames,
}: {
  eligibleDocumentNames: string[];
}): JSX.Element => {
  const router = useRouter();
  const [isCtaDisabled, setCtaDisabled] = useState(false);
  const track = useTrack();
  return (
    <Banner className={styles.banner} isCloseable={false}>
      <>
        <ProductContent
          className={styles['auto-create-banner-text']}
          variation="small"
        >
          <b>Fill faster with autofill.</b> Create 2024 tax forms in seconds
          using information from last year&apos;s filing.
        </ProductContent>
        <button
          className={styles['auto-create-banner-link']}
          data-testid="auto-create-banner-link"
          disabled={isCtaDisabled}
          onClick={() => {
            void track({
              action: 'My Documents',
              category: 'Autofill | Autocreate Banner',
            });
            void handleAutocreateCtaClick(
              setCtaDisabled,
              router,
              track,
              eligibleDocumentNames.length,
              true
            );
          }}
          type="button"
        >
          Autofill forms ›
        </button>
      </>
    </Banner>
  );
};
