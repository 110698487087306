import classNames from 'classnames';
import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';
import styles from './FillableFieldIcon.module.scss';

const FillableFieldIcon = ({
  className,
  ...props
}: BaseIconProps): JSX.Element => (
  <BaseIcon
    {...props}
    className={classNames(styles['fillable-field-icon'], className)}
  >
    <rect
      fillOpacity="0"
      height="9"
      rx="1"
      strokeWidth="2"
      width="14"
      x="1"
      y="3.5"
    />
    <line strokeLinecap="round" strokeWidth="2" x1="4" x2="12" y1="9" y2="9" />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FillableFieldIcon;
