import React from 'react';
import { Banner } from 'DesignSystem/Banner';
import { ProductContent } from 'DesignSystem/Typography';
import { SoloLink } from 'DesignSystem/Links';
import classnames from 'classnames';
import Image from 'Image';
import useTrack from 'lib/tracking/useTrack';
import styles from './UpdatedFormsBanner.module.scss';
import { hideBannerFromUser } from '../Utils';

export const UpdatedFormsBanner = (): JSX.Element => {
  const track = useTrack();
  const trackEvent = (trackingCategory: string) => () =>
    track({
      action: 'My Documents',
      category: `${trackingCategory} | Updated Forms Banner`,
    });

  const handleCloseClick = () => {
    hideBannerFromUser('updatedFormsBanner');
  };

  return (
    <Banner
      className={styles['updated-forms-banner']}
      onCloseClick={handleCloseClick}
    >
      <div className={styles['updated-forms-banner-message-wrapper']}>
        <Image
          alt="Forms Icon"
          className={styles['updated-forms-banner-message-icon']}
          src="/static/images/banners/updated-forms-illustration-2x.png"
        />
        <ProductContent variation="small">
          Try our updated forms!
        </ProductContent>
      </div>
      <div className={styles['updated-forms-banner-links-wrapper']}>
        <SoloLink
          className={classnames(
            styles['updated-forms-banner-link'],
            styles['updated-forms-banner-link-divide-border'],
            styles['updated-forms-banner-link-with-static-width']
          )}
          dataTestid="create-lease-agreement-link"
          href="/builder/static/lease-agreement-v4"
          onClick={trackEvent('Create Lease Agreement')}
          target="_self"
          variation="small"
        >
          Lease Agreement
        </SoloLink>
        <SoloLink
          className={classnames(
            styles['updated-forms-banner-link'],
            styles['release-of-liability-link']
          )}
          dataTestid="create-release-of-liability-link"
          href="/builder/static/release-of-liability-v2"
          onClick={trackEvent('Create Release of Liability')}
          target="_self"
          variation="small"
        >
          Release of Liability
        </SoloLink>
        <SoloLink
          className={classnames(
            styles['updated-forms-banner-link'],
            styles['meeting-minutes-link'],
            styles['updated-forms-banner-link-with-static-width']
          )}
          dataTestid="create-meeting-minutes-link"
          href="/builder/static/corporate-minutes-v2"
          onClick={trackEvent('Create Meeting Minutes')}
          target="_self"
          variation="small"
        >
          Meeting Minutes
        </SoloLink>
        <SoloLink
          className={styles['updated-forms-banner-link']}
          dataTestid="view-all-forms-link"
          href="/document-library"
          onClick={trackEvent('View All Forms')}
          target="_self"
          variation="small"
        >
          View All Forms
        </SoloLink>
      </div>
    </Banner>
  );
};
