import PropTypes from 'prop-types';
import { withTypography } from './withTypography';

export const EditorialTypes = {
  Default: 'default',
  Smallest: 'smallest',
};

export const EditorialContent = withTypography('div', {
  className: 'ds-editorial',
});

EditorialContent.defaultProps = {
  className: '',
  variation: 'default',
};

EditorialContent.propTypes = {
  className: PropTypes.string,
  variation: PropTypes.oneOf(Object.values(EditorialTypes)),
};
