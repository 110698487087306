import { isomorphicGetCookie } from 'IsomorphicUtils';
import { setCookieClientSide } from 'lib/cookies';

type ExperimentVariation = {
  id: string;
  variationKey: string;
};

export type ExperimentMap = Record<string, ExperimentVariation>;

/**
 * Adds an experiment to the optimizelyExperiments cookie. This cookie should
 * have the json structure:
 * {
 *  <experiment_key>: {id: <experiment_id>, variationKey: <variationKey>},
 *  ...
 * }
 *
 * Creates the cookie if it doesn't already exist.
 * @param experimentKey: the Optimizely experiment key
 * @param experimentId the Optimizely experiment id
 * @param variationKey: the Optimizely variation key
 */
const addExperimentToCookie = (
  experimentKey: string,
  experimentId: string,
  variationKey: string
): void => {
  const cookieValue = isomorphicGetCookie('optimizelyExperiments');
  const experiments: ExperimentMap = JSON.parse(cookieValue || '{}');
  experiments[experimentKey] = { id: experimentId, variationKey };

  setCookieClientSide('optimizelyExperiments', JSON.stringify(experiments), {
    expires: 365 * 5,
  });
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default addExperimentToCookie;
