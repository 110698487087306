import { isomorphicGetCookie } from 'IsomorphicUtils';
import classNames from 'classnames';
import { chatClient } from 'lib/chat/ChatClient';
import { getChatInitData } from 'lib/chat/getChatInitData';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';

/**
 * Match the iFrame path to the windows path to allow SnapEngage
 * to target specific pages
 */
const getIframeSrc = () => {
  const thisUrl = new URL(window.location.href);
  return `https://snapengage.dropbox.com${thisUrl.pathname}`;
};

export const SnapEngageChat: React.FC = () => {
  const [frameSrc, setFrameSrc] = useState<string>();
  const frameRef = useRef<HTMLIFrameElement>(null);

  const userId = parseInt(isomorphicGetCookie('userid') as string, 10);
  const userToken = isomorphicGetCookie('userToken') as string;

  useEffect(() => {
    // The iframe src depends on the window object, which is not available server-side,
    // so this has to be called inside a useEffect for it to be used client-side only.
    setFrameSrc(getIframeSrc());
    getChatInitData(userId, userToken)
      .then((chatData) => {
        chatClient.loadChatData(chatData);
      })
      .catch((err: Error) => {
        chatClient.reportError(err);
      });
  }, [userId, userToken]);

  return frameSrc ? (
    <iframe
      ref={frameRef}
      className={classNames(styles.iframe, styles.hidden, styles.minimized)}
      data-testid="snapengage-iframe"
      id="snapengage-iframe"
      onLoad={() => {
        if (frameRef.current) {
          chatClient.loadIframe(frameRef.current);
        }
      }}
      sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
      src={frameSrc}
      title="Live Chat Box"
    />
  ) : null;
};
