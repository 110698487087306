import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './BaseIcon.module.scss';

export interface BaseIconProps {
  children?: ReactNode;
  className?: string;
  dataTestid?: string;
  height?: number;
  onClick?: () => void;
  role?: string;
  scale?: number;
  transform?: string;
  viewBox?: string;
  width?: number;
}

const BaseIcon = ({
  children,
  className = styles['ds-icon-inherit-color'],
  dataTestid,
  height = 16,
  scale = 1,
  transform = '',
  viewBox = '',
  width = 16,
  ...rest
}: BaseIconProps): JSX.Element => {
  const viewBoxToUse = viewBox || `0 0 ${width} ${height}`;
  // ds-icon is an empty global class for backwards compatibility
  const classes = classnames('ds-icon', className);
  return (
    <svg
      className={classes}
      data-testid={dataTestid || ''}
      height={`${height}px`}
      style={{
        transform: transform || `scale(${scale})`,
      }}
      viewBox={viewBoxToUse}
      width={`${width}px`}
      {...rest}
    >
      {children}
    </svg>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default BaseIcon;
