import * as Types from '../../graphql/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserSubscriptionDetailsOptimizelyQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserSubscriptionDetailsOptimizelyQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    hasPaidAccess?: boolean | null;
    isCancelled?: boolean | null;
  } | null;
};

export const GetUserSubscriptionDetailsOptimizelyDocument = gql`
  query getUserSubscriptionDetailsOptimizely {
    user {
      id
      hasPaidAccess
      isCancelled
    }
  }
`;

/**
 * __useGetUserSubscriptionDetailsOptimizelyQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionDetailsOptimizelyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionDetailsOptimizelyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionDetailsOptimizelyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSubscriptionDetailsOptimizelyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserSubscriptionDetailsOptimizelyQuery,
    GetUserSubscriptionDetailsOptimizelyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserSubscriptionDetailsOptimizelyQuery,
    GetUserSubscriptionDetailsOptimizelyQueryVariables
  >(GetUserSubscriptionDetailsOptimizelyDocument, options);
}
export function useGetUserSubscriptionDetailsOptimizelyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSubscriptionDetailsOptimizelyQuery,
    GetUserSubscriptionDetailsOptimizelyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserSubscriptionDetailsOptimizelyQuery,
    GetUserSubscriptionDetailsOptimizelyQueryVariables
  >(GetUserSubscriptionDetailsOptimizelyDocument, options);
}
export type GetUserSubscriptionDetailsOptimizelyQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionDetailsOptimizelyQuery
>;
export type GetUserSubscriptionDetailsOptimizelyLazyQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionDetailsOptimizelyLazyQuery
>;
export type GetUserSubscriptionDetailsOptimizelyQueryResult = Apollo.QueryResult<
  GetUserSubscriptionDetailsOptimizelyQuery,
  GetUserSubscriptionDetailsOptimizelyQueryVariables
>;
