import { UAParser } from 'ua-parser-js';

export enum UserDevice {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Tablet = 'tablet',
  Unknown = 'unknown',
}

const getUserDevice = (userAgent: string | undefined): UserDevice => {
  if (userAgent) {
    const parser = new UAParser(userAgent);
    const deviceType = parser.getDevice().type;
    // For some reason, ua-parser-js treats an undefined deviceType as desktop.
    if (!deviceType) {
      return UserDevice.Desktop;
    }
    if (deviceType === 'mobile' || deviceType === 'tablet') {
      return deviceType as UserDevice;
    }
  }
  return UserDevice.Unknown;
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default getUserDevice;
