import React from 'react';
import PropTypes from 'prop-types';
import { TooltipCloseButton } from './TooltipCloseButton';
import { TooltipTertiaryButton } from './TooltipTertiaryButton';
import styles from '../ElementWithTooltip.module.scss';

export const TooltipBody = ({
  children,
  closeIconDataTestid,
  closeTooltipOnClick,
  showCloseButton,
  tertiaryBtn,
}) => (
  <div className={styles['ds-tooltip-body']}>
    {showCloseButton && (
      <TooltipCloseButton
        dataTestid={closeIconDataTestid}
        onClick={closeTooltipOnClick}
      />
    )}
    {children}
    {tertiaryBtn && (
      <TooltipTertiaryButton
        {...tertiaryBtn}
        closeTooltipOnClick={closeTooltipOnClick}
      />
    )}
  </div>
);

TooltipBody.defaultProps = {
  children: null,
  closeIconDataTestid: '',
  showCloseButton: false,
  tertiaryBtn: null,
};

TooltipBody.propTypes = {
  children: PropTypes.node,
  closeIconDataTestid: PropTypes.string,
  closeTooltipOnClick: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  tertiaryBtn: PropTypes.shape({
    className: PropTypes.string,
    copy: PropTypes.string,
    dataTestid: PropTypes.string.isRequired,
    enableCloseTooltip: PropTypes.bool,
    onClick: PropTypes.func,
  }),
};
