interface SourceInfo {
  experimentKey: string;
  variationKey: string;
}

export interface DecisionInfo {
  experimentKey?: string;
  ruleKey?: string;
  sourceInfo?: SourceInfo;
  variationKey?: string;
  [key: string]: unknown;
}

interface ExperimentInfo {
  experimentKey: string;
  variationKey: string;
}

/**
 * Returns experiment key and variation key given an Optimizely decision and test type.
 * The Optimizely decision contains the experiment and variation key, however these keys
 * are retrieved differently depending on what the Optimizely test type is.
 *
 * See https://docs.developers.optimizely.com/full-stack/docs/decision-notification-listener#section-type-parameter
 * for more information on legacy api "decisionInfo" and "type" parameters
 * See https://docs.developers.optimizely.com/full-stack/v4.0/docs/decision-notification-listener
 * for more information on flags api "decisionInfo" and "type" parameters
 *
 * @param decisionInfo An Optimizely decision object that contains experiment information for the user.
 * @param optimizelyTestType The Optimizely test type, this can either be "flag", the only type for flags api,
 * or it can be a legacy api type; "ab-test", "feature-test", "feature", "feature-variable", or "all-feature-variables".
 */

const getExperimentInfoFromDecision = (
  decisionInfo: DecisionInfo,
  optimizelyTestType: string
): ExperimentInfo => {
  let experimentKey: string;
  let variationKey: string;
  if (
    optimizelyTestType === 'ab-test' ||
    optimizelyTestType === 'feature-test'
  ) {
    experimentKey = decisionInfo.experimentKey as string;
    variationKey = decisionInfo.variationKey as string;
  } else if (optimizelyTestType === 'flag') {
    experimentKey = decisionInfo.ruleKey as string;
    variationKey = decisionInfo.variationKey as string;
  } else {
    const { sourceInfo } = <{ sourceInfo: SourceInfo }>decisionInfo;
    experimentKey = sourceInfo.experimentKey;
    variationKey = sourceInfo.variationKey;
  }
  return { experimentKey, variationKey };
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default getExperimentInfoFromDecision;
