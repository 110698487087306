import React, { useState } from 'react';
import { CloseSmallIcon } from 'DesignSystem/Icons';
import classNames from 'classnames';
import baseIconsStyles from 'DesignSystem/Icons/BaseIcon.module.scss';
import { useFeature } from '@optimizely/react-sdk';
import styles from './Alert.module.scss';

export const AlertTypes = {
  Error: 'error',
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
};

interface AlertProps {
  boldText?: string;
  children?: React.ReactNode;
  className?: string;
  closeButton?: boolean;
  dataTestid: string;
  style?: Record<string, string>;
  text?: string | JSX.Element;
  type: typeof AlertTypes[keyof typeof AlertTypes];
}

export const Alert = ({
  boldText = '',
  children = null,
  className = '',
  closeButton = false,
  dataTestid = '',
  style = {},
  text,
  type,
}: AlertProps): JSX.Element | null => {
  const [isOpen, setIsOpen] = useState(true);
  const boldTextDisplayed = text && boldText ? `${boldText} ` : boldText;
  const [isDropboxDesignSystemEnabled] = useFeature('dropbox_design_system');

  if (!isOpen) {
    return null;
  }
  return (
    <div
      className={classNames(
        styles['ds-alert'],
        styles[`ds-alert-${type}`],
        { [styles.dropbox]: isDropboxDesignSystemEnabled },
        className
      )}
      data-testid={dataTestid || ''}
      style={{ ...style }}
    >
      <div className={styles['alert-text']}>
        <b>{boldTextDisplayed}</b>
        {text}
        {children}
      </div>
      {closeButton && (
        <CloseSmallIcon
          className={classNames(
            styles['ds-icon-default'],
            baseIconsStyles['ds-icon-default'],
            baseIconsStyles['cursor-pointer'],
            { [styles.dropbox]: isDropboxDesignSystemEnabled }
          )}
          dataTestid={dataTestid.length ? `${dataTestid}-close-button` : ''}
          onClick={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};
