import styles from 'MyDocuments/AutocreateModal.module.scss';
import React, { useCallback, useEffect, useState } from 'react';
import Image from 'Image';
import { CheckmarkBoldIcon } from 'DesignSystem/Icons';
import { PrimaryButton, SecondaryButton } from 'DesignSystem/Buttons';
import { FormSwiftModal, ModalBody } from 'DesignSystem/FormSwiftModal';
import { HeadingF, ProductContent } from 'DesignSystem/Typography';
import { setCookieClientSide } from 'lib/cookies';
import { useDecision } from '@optimizely/react-sdk';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import { NextRouter, useRouter } from 'next/router';
import useTrack, { TrackArgs } from 'lib/tracking/useTrack';
import { ErrorMessageConstants } from 'ErrorMessage/ErrorMessageUtils';

const {
  publicRuntimeConfig,
}: Record<string, Record<string, string>> = getConfig();

export type EligibleDocumentName = string;
export type AutocreatedSessionMetadata = {
  /* eslint-disable camelcase */
  document_type: string;
  session_hash: string;
  session_id: string;
  /* eslint-enable camelcase */
};
type BaseResponse<TData> = {
  status: string;
  message: string | null;
  data: TData | null;
};
type EligibleDocumentsResponse = BaseResponse<{
  /* eslint-disable camelcase */
  eligible_document_names: EligibleDocumentName[];
  /* eslint-enable camelcase */
}>;
type AutocreateResponse = BaseResponse<{
  /* eslint-disable camelcase */
  autocreated_sessions: AutocreatedSessionMetadata[];
  /* eslint-enable camelcase */
}>;

export const SEEN_AUTOCREATE_MODAL_COOKIE = 'seenAutocreateModal';
export const SHOW_AUTOCREATE_SUCCESS_BANNER_COOKIE =
  'showAutocreateSuccessBanner';
export const AUTOCREATE_SPLIT_TEST_NAME =
  'auto_create_tax_docs_for_current_year';
export const AUTOCREATE_MODAL_TITLE =
  'Autofill tax forms to save time and avoid errors';
export const getAutocreateModalDescription = (
  eligibleFormsCount: number
): string =>
  `Use business and payee information from last year’s forms to get a head start on your 2024 filing. We found ${
    eligibleFormsCount > 1 ? `${eligibleFormsCount} forms` : '1 form'
  } to autofill:`;
export const CONFIRM_AUTOCREATE_CTA_TEXT = 'Autofill forms';
export const CANCEL_AUTOCREATE_CTA_TEXT = 'No thanks';
export const MAX_ELIGIBLE_FORMS_TO_SHOW = 6;

export const getMoreEligibleFormsText = (
  moreEligibleFormsCount: number
): string => {
  if (moreEligibleFormsCount === 1) {
    return 'And 1 more form';
  }
  return `And ${moreEligibleFormsCount} more forms`;
};

export const fetchAutocreateEligibleDocumentNames = async (): Promise<
  EligibleDocumentName[]
> => {
  const payloadQueryParams = new URLSearchParams({
    user_id: Cookies.get('userid') || '',
    user_token: Cookies.get('userToken') || '',
  }).toString();
  try {
    const response = await fetch(
      `${publicRuntimeConfig.API_URL}/tax/autocreate/eligible-documents?${payloadQueryParams}`
    );
    const { data }: EligibleDocumentsResponse = await response.json();
    return data?.eligible_document_names || [];
  } catch (e) {
    // Swallow error
  }
  return [];
};

export const useIsAutocreateEligible = (): {
  loading: boolean;
  isAutocreateEligible: boolean;
  eligibleDocumentNames: string[];
} => {
  const [decision] = useDecision(AUTOCREATE_SPLIT_TEST_NAME);
  const isInAutocreateTest = decision.variationKey === 'on';
  const [eligibleDocumentNames, setEligibleDocumentNames] = useState<string[]>(
    []
  );
  const [
    isLoadingAutocreateEligibility,
    setIsLoadingAutoCreateEligibility,
  ] = useState(true);

  const getEligibilityStatus = useCallback(async (): Promise<void> => {
    if (!isInAutocreateTest) {
      setIsLoadingAutoCreateEligibility(false);
      return;
    }
    const fetchedEligibleDocumentNames = await fetchAutocreateEligibleDocumentNames();
    setEligibleDocumentNames(fetchedEligibleDocumentNames);
    setIsLoadingAutoCreateEligibility(false);
  }, [isInAutocreateTest]);

  useEffect(() => {
    void getEligibilityStatus();
  }, [getEligibilityStatus]);

  return {
    eligibleDocumentNames,
    isAutocreateEligible: eligibleDocumentNames.length > 0,
    loading: isLoadingAutocreateEligibility,
  };
};

export const handleAutocreateCtaClick = async (
  setCtaDisabled: (isDisabled: boolean) => void,
  router: NextRouter,
  track: ({ action, category }: TrackArgs) => Promise<boolean>,
  numberOfEligibleDocuments = 0,
  clickedFromBanner = false
): Promise<void> => {
  setCtaDisabled(true);
  const payloadQueryParams = new URLSearchParams({
    user_id: Cookies.get('userid') || '',
    user_token: Cookies.get('userToken') || '',
  }).toString();
  try {
    const response = await fetch(
      `${publicRuntimeConfig.API_URL}/tax/autocreate?${payloadQueryParams}`,
      { method: 'POST' }
    );
    const { data }: AutocreateResponse = await response.json();
    const autocreatedSessions = data?.autocreated_sessions || [];
    if (autocreatedSessions.length > 0) {
      const {
        document_type: documentType,
        session_hash: sessionHash,
        session_id: sessionId,
      } = autocreatedSessions[0];
      setCookieClientSide(SHOW_AUTOCREATE_SUCCESS_BANNER_COOKIE, 'true');
      void track({
        action: `${autocreatedSessions.length} | ${documentType}`,
        category: `Success | Autocreate ${
          clickedFromBanner ? 'Banner' : 'Modal'
        }`,
      });
      await router.push({
        pathname: `/builder/tax/${documentType}/${sessionId}/${sessionHash}`,
        query: { showAutocreateSuccessModal: true },
      });
      return;
    }
  } catch (e) {
    // Swallow error
  }
  setCtaDisabled(false);
  void track({
    action: `${numberOfEligibleDocuments}`,
    category: `Error | Autocreate ${clickedFromBanner ? 'Banner' : 'Modal'}`,
  });

  await router.push({
    query: { error: ErrorMessageConstants.AUTOCREATE_FAILURE },
  });
};

export const AutocreateModal = (): JSX.Element | null => {
  const router = useRouter();
  const track = useTrack();
  const {
    eligibleDocumentNames,
    isAutocreateEligible,
  } = useIsAutocreateEligible();
  const [showModal, setShowModal] = useState(false);
  const [isCtaDisabled, setCtaDisabled] = useState(false);

  const onRequestModalClose = () => {
    setShowModal(false);
  };

  const moreFormsToShow = (eligibleDocumentNames || []).slice(
    MAX_ELIGIBLE_FORMS_TO_SHOW
  );

  useEffect(() => {
    if (isAutocreateEligible) {
      setShowModal(Cookies.get(SEEN_AUTOCREATE_MODAL_COOKIE) !== 'true');
      setCookieClientSide(SEEN_AUTOCREATE_MODAL_COOKIE, 'true');
    }
  }, [isAutocreateEligible]);

  useEffect(() => {
    if (showModal) {
      void track({
        action: `${eligibleDocumentNames.length}`,
        category: 'View | Autocreate Modal',
      });
    }
  }, [showModal, eligibleDocumentNames, track]);

  return (
    <FormSwiftModal
      aria={{
        label: 'Auto create tax forms modal',
      }}
      className={styles['auto-create-modal']}
      closeOnOverlayClick
      handleCloseModal={() => {
        void track({
          action: `${eligibleDocumentNames.length}`,
          category: 'Exit | Autocreate Modal',
        });
        onRequestModalClose();
      }}
      isCloseButtonVisible={false}
      isOpen={showModal}
      mobileFriendly
    >
      <ModalBody className={styles['auto-create-modal-body']}>
        <Image
          alt="Auto create illustration"
          className={styles['auto-create-modal-illustration-img']}
          src="/static/images/auto-create/autocreate-2023-2024-illustration.png"
        />
        <div className={styles['description-section']}>
          <HeadingF>{AUTOCREATE_MODAL_TITLE}</HeadingF>
          <ProductContent dataTestid="autocreate-modal-description">
            {getAutocreateModalDescription(eligibleDocumentNames?.length || 0)}
          </ProductContent>
          <div className={styles['description-section-form-list']}>
            {(eligibleDocumentNames || [])
              .slice(0, MAX_ELIGIBLE_FORMS_TO_SHOW)
              .map((eligibleFormName) => (
                <div
                  key={eligibleFormName}
                  className={styles['description-section-form-detail']}
                >
                  <CheckmarkBoldIcon />
                  <ProductContent
                    className={styles['description-section-form-name']}
                  >
                    {eligibleFormName}
                  </ProductContent>
                </div>
              ))}
          </div>
          {moreFormsToShow.length > 0 && (
            <ProductContent
              className={styles['description-section-form-list-more']}
              dataTestid="autocreate-modal-description-more"
              title={moreFormsToShow.toString()}
            >
              {getMoreEligibleFormsText(moreFormsToShow.length)}
            </ProductContent>
          )}
        </div>
        <div className={styles['auto-create-modal-actions']}>
          <SecondaryButton
            className={styles['auto-create-modal-cancel-cta']}
            dataTestid="auto-create-modal-cancel-cta"
            onClick={() => {
              void track({
                action: `${eligibleDocumentNames.length}`,
                category: 'No Thanks | Autocreate Modal',
              });
              onRequestModalClose();
            }}
          >
            {CANCEL_AUTOCREATE_CTA_TEXT}
          </SecondaryButton>
          <PrimaryButton
            dataTestid="auto-create-modal__confirm-cta"
            disabled={isCtaDisabled}
            onClick={async () => {
              void track({
                action: `${eligibleDocumentNames.length}`,
                category: 'Autofill Forms | Autocreate Modal',
              });
              await handleAutocreateCtaClick(
                setCtaDisabled,
                router,
                track,
                eligibleDocumentNames.length
              );
              setShowModal(false);
            }}
          >
            {CONFIRM_AUTOCREATE_CTA_TEXT}
          </PrimaryButton>
        </div>
      </ModalBody>
    </FormSwiftModal>
  );
};
