import React from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

/* eslint-disable */
const GoogleTagManagerFragment = () => (
  <React.Fragment>
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${publicRuntimeConfig.GTM_KEY}');
        `,
      }}
    />
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${publicRuntimeConfig.GTM_KEY}`}
        height="0"
        width="0"
        style={{
          display: 'none',
          visibility: 'hidden',
        }}
      />
    </noscript>
  </React.Fragment>
);

const UnbounceAnalyticsFragment = () => (
  <script
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `
        var _ubaq = _ubaq || [];
        _ubaq.push(['trackGoal', 'convert']);
        (function() {
        var ub_script = document.createElement('script');
        ub_script.type = 'text/javascript';
        ub_script.src =
          ('https:' == document.location.protocol ? 'https://' : 'http://') +
          'd3pkntwtp2ukl5.cloudfront.net/uba.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(ub_script, s);
        }) ();
      `,
    }}
  />
);

/* eslint-enable */

const TrackingFooter = ({ trackUnbounce }) => (
  <>
    {publicRuntimeConfig.GTM_KEY && <GoogleTagManagerFragment />}
    {trackUnbounce && <UnbounceAnalyticsFragment />}
  </>
);

TrackingFooter.propTypes = {
  trackUnbounce: PropTypes.bool,
};

TrackingFooter.defaultProps = {
  trackUnbounce: false,
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default TrackingFooter;
