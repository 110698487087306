import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkLine } from '@dropbox/dig-icons/assets';
import { setUpdatingSubscriptionModalOpen } from 'layouts/GlobalModals/GlobalModalsReducer';
import { useGetUserSubscriptionPreviewQuery } from 'lib/graphql/MyAccount/__generated__/MyAccountQueries.generated';
import { useReactivateCustomerMutation } from 'lib/graphql/MyAccount/__generated__/MyAccountMutations.generated';
import { useOptimizelyClient } from 'lib/optimizely/useOptimizelyClient';
import { useFormatUserSubscriptionPrice } from 'hooks/useFormatUserSubscriptionPrice';
import useTrack from 'lib/tracking/useTrack';
import { ReactivateCTA } from 'components/ReactivateCTA';
import styles from './ReactivatePlanDetailsRowRedesign.module.scss';

const PLAN_HANDLE = 'legal_monthly_12_reactivation';

export const ReactivatePlanDetails = (): JSX.Element => {
  const { data: reactivationPreviewData } = useGetUserSubscriptionPreviewQuery({
    skip: !PLAN_HANDLE,
    variables: { planHandle: PLAN_HANDLE },
  });

  const nextBillingManifest =
    reactivationPreviewData?.user?.subscriptionPreview?.nextBillingManifest;
  const subscriptionSubtotal =
    (nextBillingManifest?.subtotalInCents || 0) / 100;
  const taxExclusive = !reactivationPreviewData?.user?.subscription
    ?.taxIncluded;
  const planInterval =
    reactivationPreviewData?.user?.subscription?.intervalUnit;

  const formattedSubscriptionSubtotal = useFormatUserSubscriptionPrice({
    omitDecimalIfWholeNumber: true,
    price: subscriptionSubtotal,
  });

  const copyRows = [
    'Complete and e-file your tax documents quickly and securely',
    'Create, edit, and sign unlimited documents',
    'Access 1,000s of professional templates',
    'Download any files via Word or PDF',
  ];

  return (
    <div className={styles['reactivate-plan-details']}>
      <Text isBold size="xlarge">
        Reactivate Plan
      </Text>
      <Text size="large">
        To get full access to FormSwift, you’ll need to reactivate your plan.
        This will enable you to:
      </Text>
      <div className={styles['reactivate-plan-benefits']}>
        {copyRows.map((copy) => {
          return (
            <div key={copy}>
              <UIIcon
                className={styles['benefits-checkmark']}
                size="medium"
                src={CheckmarkLine}
              />
              <Text size="large"> {copy}</Text>
            </div>
          );
        })}
      </div>
      <span className={styles['section-divider']} />
      <div className={styles['reactivate-plan-detail-row']}>
        <Text isBold size="large">
          Price
        </Text>
        <Text size="large">
          {formattedSubscriptionSubtotal}/{planInterval}{' '}
          {taxExclusive ? '(plus tax)' : null}
        </Text>
      </div>
    </div>
  );
};

export const ReactivatePlanDetailsRowRedesign = (): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const track = useTrack();
  const optimizely = useOptimizelyClient();
  const [reactivatePlan] = useReactivateCustomerMutation();

  const reactivateSubscription = async () => {
    void track({
      action: 'Account Settings',
      category: 'Reactivate Now V2',
    });
    dispatch(setUpdatingSubscriptionModalOpen(true));

    try {
      const response = await reactivatePlan({
        variables: { planHandle: PLAN_HANDLE },
      });

      if (response.data?.reactivateCustomer?.ok) {
        await optimizely?.onReady({ timeout: 3000 });
        void optimizely?.track('reactivated_subscription');
        dispatch(setUpdatingSubscriptionModalOpen(false));
        void router.push('/my-account?show_reactivation_success=1');
      } else {
        dispatch(setUpdatingSubscriptionModalOpen(false));
        void router.push(
          `/my-account?error=${encodeURIComponent('Reactivation failed')}`
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch(setUpdatingSubscriptionModalOpen(false));
        void router.push(`/my-account?error=${encodeURIComponent(e.message)}`);
      }
    }
  };
  return (
    <div className={styles['reactivate-plan-container']}>
      <ReactivatePlanDetails />
      <ReactivateCTA reactivateSubscription={reactivateSubscription} />
    </div>
  );
};
