import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { RootState } from 'store/RootState';
import styles from './UpdatingSubscriptionLoadingModal.module.scss';

export const UpdatingSubscriptionLoadingModal = (): JSX.Element => {
  const isUpdatingSubscriptionLoadingModalOpen = useSelector(
    (state: RootState) =>
      state.GlobalModalsHandler?.isUpdatingSubscriptionModalOpen
  );

  return (
    <Modal
      ariaHideApp={false}
      fullScreen={false}
      open={isUpdatingSubscriptionLoadingModalOpen}
      width="standard"
    >
      <Modal.Header />
      <Modal.Body
        className={styles['updating-subscription-modal-body-container']}
      >
        <Spinner aria-valuetext="Loading large" size="medium" />
        <Text isBold size="xlarge">
          Updating subscription...
        </Text>
        <div>
          <Text>Please wait, this could take up to 15 seconds.</Text>
          <br />
          <Text>
            <Text isBold>Do not refresh</Text> your browser.
          </Text>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};
