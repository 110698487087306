import PropTypes from 'prop-types';
import { withTypography } from './withTypography';

export const HeadingD = withTypography('h5', { className: 'ds-heading-d' });

HeadingD.defaultProps = {
  className: '',
};

// Also accepts all <h5 /> attributes
HeadingD.propTypes = {
  className: PropTypes.string,
};
