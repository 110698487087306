import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const ArrowRightIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fillRule="nonzero" transform="translate(0.000000, 3.000000)">
        <path d="M12.5213203,6 L1,6 C0.44771525,6 -1.00097708e-12,5.55228475 -1.00097708e-12,5 C-1.00097708e-12,4.44771525 0.44771525,4 1,4 L12.6218254,4 L10.7431458,2.12132034 C10.3526215,1.73079605 10.3526215,1.09763107 10.7431458,0.707106781 C11.13367,0.316582489 11.766835,0.316582489 12.1573593,0.707106781 L15.6928932,4.24264069 C16.0834175,4.63316498 16.0834175,5.26632996 15.6928932,5.65685425 L12.1573593,9.19238816 C11.766835,9.58291245 11.13367,9.58291245 10.7431458,9.19238816 C10.3526215,8.80186386 10.3526215,8.16869888 10.7431458,7.77817459 L12.5213203,6 Z" />
      </g>
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default ArrowRightIcon;
