import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const CloseSmallIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M8,6.58662318 L11.2949286,3.2924746 C11.685314,2.90218164 12.3178791,2.9025566 12.7078024,3.29331209 C13.0977257,3.68406758 13.0973511,4.31723245 12.7069657,4.7075254 L9.41371149,8 L12.7069657,11.2924746 C13.0973511,11.6827676 13.0977257,12.3159324 12.7078024,12.7066879 C12.3178791,13.0974434 11.685314,13.0978184 11.2949286,12.7075254 L8,9.41337682 L4.70507138,12.7075254 C4.314686,13.0978184 3.68212085,13.0974434 3.29219757,12.7066879 C2.90227429,12.3159324 2.90264889,11.6827676 3.29303427,11.2924746 L6.5862885,8 L3.29303427,4.7075254 C2.90264889,4.31723245 2.90227429,3.68406758 3.29219757,3.29331209 C3.68212085,2.9025566 4.314686,2.90218164 4.70507138,3.2924746 L8,6.58662318 Z"
        fillRule="nonzero"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default CloseSmallIcon;
