import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const SearchIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M11.4645043,9.69673737 L15.6338835,13.8661165 C16.1220388,14.3542719 16.1220388,15.1457281 15.6338835,15.6338835 C15.1457281,16.1220388 14.3542719,16.1220388 13.8661165,15.6338835 L9.69673737,11.4645043 C8.7086961,12.118904 7.52385292,12.5 6.25,12.5 C2.79822031,12.5 0,9.70177969 0,6.25 C0,2.79822031 2.79822031,0 6.25,0 C9.70177969,0 12.5,2.79822031 12.5,6.25 C12.5,7.52385292 12.118904,8.7086961 11.4645043,9.69673737 Z M6.25,10.5 C8.59721019,10.5 10.5,8.59721019 10.5,6.25 C10.5,3.90278981 8.59721019,2 6.25,2 C3.90278981,2 2,3.90278981 2,6.25 C2,8.59721019 3.90278981,10.5 6.25,10.5 Z"
        fillRule="nonzero"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default SearchIcon;
