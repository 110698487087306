import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import { useFeature } from '@optimizely/react-sdk';
import { GlobalHeader } from '@dropbox/dig-components/global_header';
import { FormswiftWordmark } from '@dropbox/dig-logos';
import AccountMenu from './AccountMenu';
import Logo from './Logo';

const Header = (props) => {
  const [isDropboxDesignSystemEnabled] = useFeature('dropbox_design_system');
  const { hideAccount } = props;

  if (isDropboxDesignSystemEnabled) {
    return (
      <GlobalHeader.Wrapper isSticky={false} shouldAlwaysShowLogo>
        <GlobalHeader.Logo
          data-testid="formswift-dig-logo"
          href="/"
          src={FormswiftWordmark}
        />
        <GlobalHeader.UtilityNav>
          {!hideAccount && <AccountMenu {...props} />}
        </GlobalHeader.UtilityNav>
      </GlobalHeader.Wrapper>
    );
  }

  return (
    <header className="header" id="pageTop">
      <Container>
        <Row className="align-items-start justify-content-between">
          <Logo {...props} />
          {!hideAccount && <AccountMenu {...props} />}
        </Row>
      </Container>
    </header>
  );
};

Header.propTypes = {
  hideAccount: PropTypes.bool,
};

Header.defaultProps = {
  hideAccount: false,
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default Header;
