import React, { ReactNode, Ref } from 'react';
import classnames from 'classnames';
import BaseButtonIcon from './BaseButtonIcon';
import styles from './index.module.scss';
import { ButtonSize, ButtonType, IconPosition } from '../types';

type ButtonOption = 'square-padding' | 'icon-only';

export interface BaseButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  buttonRef?: Ref<HTMLButtonElement>;
  buttonType: ButtonType;
  children?: ReactNode;
  className?: string;
  dataTestid: string;
  icon?: ReactNode;
  iconPosition?: IconPosition;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  options?: ButtonOption[];
  size: ButtonSize;
}

export const BaseButton = ({
  buttonRef = null,
  buttonType,
  children,
  className = '',
  dataTestid,
  icon = null,
  iconPosition = 'left',
  onClick = () => {},
  options = [],
  size,
  ...buttonProps
}: BaseButtonProps): JSX.Element => {
  const classes = classnames(
    styles[`ds-btn-${buttonType}`],
    styles[size],
    className,
    ...options.map((option) => styles[`option-${option}`])
  );

  return (
    // @ts-expect-error Types of property `type` are incompatible...
    // eslint-disable-next-line react/button-has-type
    <button
      ref={buttonRef}
      className={classes}
      data-testid={dataTestid}
      onClick={onClick}
      {...buttonProps}
    >
      <BaseButtonIcon iconPosition={iconPosition} position="left">
        {icon}
      </BaseButtonIcon>
      {children}
      <BaseButtonIcon iconPosition={iconPosition} position="right">
        {icon}
      </BaseButtonIcon>
    </button>
  );
};
