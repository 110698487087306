import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

export const SendForSignatureIcon = ({
  ...props
}: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <path
      clipRule="evenodd"
      d="M0 1H11.5L16 8L11.5 15H0V1ZM2 3V13H10.4081L13.6224 8L10.4081 3H2ZM3.54289 6.20711C3.15237 5.81658 3.15237 5.18342 3.54289 4.79289C3.93342 4.40237 4.56658 4.40237 4.95711 4.79289L6.75 6.58579L8.54289 4.79289C8.93342 4.40237 9.56658 4.40237 9.95711 4.79289C10.3476 5.18342 10.3476 5.81658 9.95711 6.20711L8.16421 8L9.95711 9.79289C10.3476 10.1834 10.3476 10.8166 9.95711 11.2071C9.56658 11.5976 8.93342 11.5976 8.54289 11.2071L6.75 9.41421L4.95711 11.2071C4.56658 11.5976 3.93342 11.5976 3.54289 11.2071C3.15237 10.8166 3.15237 10.1834 3.54289 9.79289L5.33579 8L3.54289 6.20711Z"
      fillRule="evenodd"
    />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default SendForSignatureIcon;
