import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import buttonStyles from 'DesignSystem/Buttons/BaseButton/index.module.scss';

export const IconButton = (props) => {
  const { className, icon, ...buttonProps } = props;
  const classes = classnames(buttonStyles['ds-btn-icon'], className);
  return (
    <button className={classes} type="button" {...buttonProps}>
      {icon}
    </button>
  );
};

IconButton.defaultProps = {
  className: '',
};

// Also accepts all <button /> attributes
IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
};
