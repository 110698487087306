import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

export const EyeIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props} height={12}>
    <path d="M10 6C10 7.10457 9.10457 8 8 8C6.89543 8 6 7.10457 6 6C6 4.89543 6.89543 4 8 4C9.10457 4 10 4.89543 10 6Z" />
    <path
      clipRule="evenodd"
      d="M8 12C11.2133 12 15 10 16 6C15 2 11.2133 0 8 0C4.78671 0 1 2 0 6C1 10 4.78671 12 8 12ZM8 10C10.2091 10 12 8.20914 12 6C12 3.79086 10.2091 2 8 2C5.79086 2 4 3.79086 4 6C4 8.20914 5.79086 10 8 10Z"
      fillRule="evenodd"
    />
  </BaseIcon>
);
