import { KeyboardEvent } from 'react';
import { FOCUS } from 'DesignSystem/SearchBar/SearchContext/Constants';
import { SetFocusDown, SetFocusTo, SetFocusUp } from '../types';

const handleArrowDown = (
  isLastItem: boolean,
  setFocusDown: SetFocusDown,
  setFocusTo: SetFocusTo
): void => {
  if (isLastItem) {
    setFocusTo(FOCUS.INPUT);
  } else {
    setFocusDown();
  }
};

const handleArrowUp = (
  index: number,
  setFocusTo: SetFocusTo,
  setFocusUp: SetFocusUp
): void => {
  if (index === 0) {
    setFocusTo(FOCUS.INPUT);
  } else {
    setFocusUp();
  }
};

const createKeyDownHandler = (
  index: number,
  isLastItem: boolean,
  setFocusDown: SetFocusDown,
  setFocusTo: SetFocusTo,
  setFocusUp: SetFocusUp
) => (e: KeyboardEvent): void => {
  switch (e.key) {
    case 'ArrowDown':
      e.preventDefault();
      handleArrowDown(isLastItem, setFocusDown, setFocusTo);
      break;
    case 'ArrowUp':
      e.preventDefault();
      handleArrowUp(index, setFocusTo, setFocusUp);
      break;
    case 'Escape':
      setFocusTo(FOCUS.INPUT);
      break;
    default:
      break;
  }
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default createKeyDownHandler;
