import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const CaretUp = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props} height={5} width={10}>
    <path clipRule="evenodd" d="M0 5H10L5 0L0 5Z" fillRule="evenodd" />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default CaretUp;
