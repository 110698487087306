import {
  Action,
  ISearchContext,
  SearchFocus,
  SearchMethod,
  StatusType,
} from 'DesignSystem/SearchBar/types';
import { debounce } from 'lodash';
import { FOCUS } from './Constants';

const searchContextReducer = <ResultType,>(
  state: ISearchContext<ResultType>,
  action: Action<ResultType>
): ISearchContext<ResultType> => {
  switch (action.type) {
    case 'initSearchContextActions':
      return { ...state, ...action.actions };
    case 'setFocusDown':
      if (state.searchFocus === FOCUS.INPUT) {
        return { ...state, searchFocus: 0 };
      }
      if (typeof state.searchFocus === 'number') {
        return { ...state, searchFocus: state.searchFocus + 1 };
      }
      return state;
    case 'setFocusTo':
      return { ...state, searchFocus: action.searchFocus };
    case 'setFocusUp': {
      if (state.searchFocus === 0) {
        return { ...state, searchFocus: FOCUS.INPUT };
      }
      if (typeof state.searchFocus === 'number') {
        return { ...state, searchFocus: state.searchFocus - 1 };
      }
      return state;
    }
    case 'setResults':
      return { ...state, results: action.results };
    case 'setSearchMethod':
      return { ...state, search: action.search };
    /*
      This action/reducer pair is not used on the Tax address search components.
      Instead of using a hook with useEffect state listeners to determine when
      we search, we handle debouncing the query and updating status ourselves in
      BaseAddressSearchInput and call the debounced function onChange.
    */
    case 'setSearchTerm':
      return {
        ...state,
        results: !action.searchTerm ? undefined : state.results,
        searchTerm: action.searchTerm,
      };
    case 'setStatus':
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export const initSearchContextActions = <ResultType,>(
  dispatch: (action: Action<ResultType>) => void,
  debounceRate = 0
): void =>
  dispatch({
    actions: {
      setFocusDown: () => dispatch({ type: 'setFocusDown' }),
      setFocusTo: (searchFocus: SearchFocus) =>
        dispatch({ searchFocus, type: 'setFocusTo' }),
      setFocusUp: () => dispatch({ type: 'setFocusUp' }),
      setResults: (results?: ResultType) =>
        dispatch({ results, type: 'setResults' }),
      /*
        This action/reducer pair is not used on the Tax address search components.
        Instead of using a hook with useEffect state listeners to determine when
        we search, we handle debouncing the query and updating status ourselves in
        BaseAddressSearchInput and call the debounced function onChange.
      */
      setSearchMethod: (search: SearchMethod) => {
        dispatch({
          search: debounce(search, debounceRate),
          type: 'setSearchMethod',
        });
      },
      setSearchTerm: (searchTerm: string) =>
        dispatch({ searchTerm, type: 'setSearchTerm' }),
      setStatus: (status: StatusType) =>
        dispatch({ status, type: 'setStatus' }),
    },
    type: 'initSearchContextActions',
  });

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default searchContextReducer;
