import React, { Dispatch, useEffect, useMemo } from 'react';
import { enums, OptimizelyProvider } from '@optimizely/react-sdk';
import { getCookies } from 'lib/dataFetchers';
import * as Sentry from '@sentry/nextjs';
import { NextPage, NextPageContext } from 'next';
import getPageTrackingEvent from 'lib/optimizely/getPageTrackingEvent';
import { OnReadyResult } from '@optimizely/react-sdk/dist/client';
import { useDispatch } from 'react-redux';
import { trackEvent, trackUserAdditionalData } from 'Tracking/Actions';
import isServerSide from 'Utils/isServerSide';
import { useCountryData } from 'lib/useCountryData';
import { useGetUserSubscriptionDetailsOptimizelyQuery } from 'lib/optimizely/__generated__/optimizelyQueries.generated';
import { getCachedOptimizelyDatafile } from 'lib/dataFetchers/getOptimizelyData';
import getOptimizelyClient, {
  getCachedOptimizelyClient,
} from './getOptimizelyClient';
import { OptimizelyData, OptimizelyDatafile } from './types';
import addExperimentToCookieWhenLoggedOut from './addExperimentToCookieWhenLoggedOut';
import getExperimentInfoFromDecision, {
  DecisionInfo,
} from './getExperimentInfoFromDecision';
import getUserDevice from './getUserDevice';

export interface OptimizelyProps {
  optimizelyData: OptimizelyData & { optimizelyUserId: string };
}

export interface CookieProps {
  cookies: ReturnType<typeof getCookies>;
}

export interface DecisionPayload {
  userId: string;
  attributes: { [key: string]: unknown };
  decisionInfo: DecisionInfo;
  type: string;
}

export type TOptimizelyExperiment = {
  experimentId: string;
  experimentKey: string;
  variationKey: string;
};

export const dispatchAndRegister = (
  // TODO: Consider fixing linting error next time editing this file
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  { experimentId, experimentKey, variationKey }: TOptimizelyExperiment
): void => {
  dispatch(
    trackEvent(
      `Experiment Decided: ${experimentKey} (${experimentId})`,
      `${variationKey}`
    )
  );
  dispatch(
    trackUserAdditionalData(
      `${window.location.hostname}${window.location.pathname}`,
      {
        [`exp_${experimentId}`]: variationKey,
      }
    )
  );

  // @ts-expect-error mixpanel not defined on window
  if (typeof window?.mixpanel?.register === 'function') {
    // @ts-expect-error mixpanel not defined on window
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.mixpanel.register({
      [`${experimentKey} (${experimentId})`]: variationKey,
    });
  }
};

// https://docs.developers.optimizely.com/full-stack/docs/set-up-mixpanel
export const onDecision = (
  datafile: OptimizelyDatafile,
  // TODO: Consider fixing linting error next time editing this file
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
) => ({ decisionInfo, type }: DecisionPayload): void => {
  try {
    if (isServerSide()) {
      return;
    }
    const { experimentKey, variationKey } = getExperimentInfoFromDecision(
      decisionInfo,
      type
    );
    // If there is no variation key, the user is not bucketed into the split test.
    if (!variationKey) return;

    // @ts-expect-error Optimizely data file is Record<string, unknown>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const experiment = datafile?.experiments?.filter(
      (experimentData: Record<string, unknown>) =>
        experimentData.key === experimentKey
    )?.[0];
    const { id: experimentId }: { id: string } = experiment ?? {};

    addExperimentToCookieWhenLoggedOut(
      experimentKey,
      experimentId,
      variationKey
    );
    dispatchAndRegister(dispatch, {
      experimentId,
      experimentKey,
      variationKey,
    });
  } catch (e) {
    Sentry.captureException(e);
  }
};

const withOptimizelyProvider = <P extends object>(
  WrappedComponent: NextPage<P>
): ((
  props: P &
    OptimizelyProps &
    CookieProps & { device: string; query: Record<string, string> }
) => JSX.Element) => {
  const WrappedComponentWithOptimizelyProvider = (
    props: P &
      OptimizelyProps &
      CookieProps & {
        device: string;
        pathname?: string;
        asPath?: string;
        query: Record<string, string>;
      }
  ) => {
    const { asPath, device, optimizelyData, ...rest } = props;
    const { isoAlpha2 } = useCountryData();
    const { cookies, pathname, query } = rest;
    const { optimizelyExperiment, optimizelyVariation } = query ?? {};
    const { datafile = {}, optimizelyUserId } = optimizelyData;
    const dispatch = useDispatch();
    const notificationListeners = useMemo(
      () => [
        {
          callback: onDecision(datafile, dispatch),
          notificationType: enums.NOTIFICATION_TYPES.DECISION,
        },
      ],
      [datafile, dispatch]
    );

    const optimizelyClient = useMemo(
      () =>
        getCachedOptimizelyClient() ||
        getOptimizelyClient(datafile, {
          notificationListeners,
        }),
      [datafile, notificationListeners]
    );

    if (optimizelyVariation && optimizelyExperiment && optimizelyUserId) {
      // Forced bucketing takes place immediately and does not need to exist within `onReady`.
      optimizelyClient.setForcedVariation(
        optimizelyExperiment,
        optimizelyUserId,
        optimizelyVariation
      );
    }

    useEffect(() => {
      optimizelyClient
        .onReady({ timeout: 3000 })
        .then((result: OnReadyResult) => {
          if (!result.success) {
            Sentry.captureMessage(result.reason || '');
            return;
          }
          if (pathname) {
            optimizelyClient?.track(getPageTrackingEvent(pathname, query));
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }, [
      optimizelyClient,
      optimizelyExperiment,
      optimizelyUserId,
      optimizelyVariation,
      pathname,
      query,
    ]);

    const { data } = useGetUserSubscriptionDetailsOptimizelyQuery();
    const isCancelled = data?.user?.isCancelled;
    const hasPaidAccess = data?.user?.hasPaidAccess;

    const userId = cookies?.userid;
    const optimizelyOptOut = cookies?.optimizelyOptOut;

    const userAttributes = {
      asPath,
      countryIsoAlpha2: isoAlpha2,
      device,
      has_paid_access: !!hasPaidAccess,
      is_cancelled: !!isCancelled,
      is_logged_in: !!userId,
      is_opted_out: !!optimizelyOptOut,
      pathname,
      targetedDeliveryOverride: !!cookies?.targetedDeliveryOverride,
      ...(userId && { userId }), // Conditionally set userId. Optimizely will throw an error for undefined values.
    };

    return (
      <OptimizelyProvider
        isServerSide
        optimizely={optimizelyClient}
        user={{
          attributes: userAttributes,
          id: optimizelyUserId,
        }}
      >
        <WrappedComponent {...props} />
      </OptimizelyProvider>
    );
  };

  (WrappedComponentWithOptimizelyProvider as NextPage).getInitialProps = async (
    context: NextPageContext & { optimizelyUserId: string }
  ) => {
    const { asPath, optimizelyUserId, pathname, query } = context;
    const cookies = getCookies(context);
    const optimizelyData = {
      datafile: getCachedOptimizelyDatafile(),
      optimizelyUserId,
    };

    const userAgent = context.req
      ? context.req.headers['user-agent']
      : navigator.userAgent;
    const device = getUserDevice(userAgent);

    return {
      ...((await WrappedComponent.getInitialProps?.(context)) ?? {}),
      asPath,
      cookies,
      device,
      optimizelyData,
      pathname,
      query,
    };
  };

  return WrappedComponentWithOptimizelyProvider;
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default withOptimizelyProvider;
