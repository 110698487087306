import { useEffect } from 'react';
import {
  useSetSearchTerm,
  useSearchMethod,
  useSearchTerm,
} from 'DesignSystem/SearchBar';

/**
 * @deprecated Please see components/DocumentLibrary/index.tsx for an example of
 * how to implement search with SearchInput
 */
const useSearchTermSync = (fieldValue: string, showButton = true): void => {
  const setSearchTerm = useSetSearchTerm();
  const search = useSearchMethod();
  const searchTerm = useSearchTerm();
  const autocomplete = !showButton;

  useEffect(() => {
    setSearchTerm?.(fieldValue ?? '');
  }, [fieldValue, setSearchTerm]);

  useEffect(() => {
    if (searchTerm && autocomplete) {
      search?.(searchTerm);
    }
  }, [autocomplete, search, searchTerm]);
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default useSearchTermSync;
