import { DefaultLayout, withLayout } from 'layouts';
import { withOptimizelyProvider } from 'lib/optimizely';
import { withApollo } from './apollo';

const withFormSwiftProviders = (Component, Layout = DefaultLayout) => {
  const PageWithLayout = withLayout(Component, Layout);
  const PageWithOptimizely = withOptimizelyProvider(PageWithLayout);
  return withApollo()(PageWithOptimizely);
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default withFormSwiftProviders;
