import PropTypes from 'prop-types';
import DefaultLayoutProps from 'layouts/DefaultLayout/DefaultLayoutProps';

// We define layout props in a separate file to avoid eslint warnings
// around referencing propTypes on a component. There is a chance that,
// unused propTypes are removed at build time which would introduce
// unexpected behavior for our proptypes extractor method.
export default {
  ...DefaultLayoutProps,
  activeKey: PropTypes.string.isRequired,
  hideAccount: PropTypes.bool,
};
