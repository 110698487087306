import { gql, useQuery } from '@apollo/client';

export const GET_PLAN_DATA = gql`
  query getPlan($plan: String!) {
    plan(plan: $plan) {
      afterTrialCharge
      trialCharge
      trialDuration
    }
  }
`;

export const usePlanData = (plan) => {
  const { data: planData } = useQuery(GET_PLAN_DATA, {
    variables: { plan },
    skip: !plan,
  });

  if (planData && planData.plan) {
    const { afterTrialCharge, trialCharge, trialDuration } = planData.plan;
    return {
      afterTrialCharge: afterTrialCharge || 0,
      plan,
      trialCharge: trialCharge || 0,
      trialDuration: trialDuration || 0,
    };
  }

  return { plan };
};
