import PropTypes from 'prop-types';

// We define layout props in a separate file to avoid eslint warnings
// around referencing propTypes on a component. There is a chance that
// unused propTypes are removed at build time which would introduce
// unexpected behavior for our proptypes extractor method.
export default {
  children: PropTypes.node,
  countryIsoAlpha2: PropTypes.string,
  dbxConsentCookie: PropTypes.string,
  hideAccount: PropTypes.bool,
  loggedIn: PropTypes.bool,
  title: PropTypes.string,
  trackUnbounce: PropTypes.bool,
};
