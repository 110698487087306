import React from 'react';
import classNames from 'classnames';
import { useUser } from 'hooks';
import { useFeature } from '@optimizely/react-sdk';
import SignInButton from './SignInButton';
import LoggedInMenu from './LoggedInMenu';
import EmailAndIsFreemiumFragment from './gql/EmailAndIsFreemiumFragment';

const AccountMenu = () => {
  const [isDropboxDesignSystemEnabled] = useFeature('dropbox_design_system');
  const { data } = useUser(
    'EmailAndIsFreemiumFragment',
    EmailAndIsFreemiumFragment
  );

  const user = data?.user;
  const isSignedIn = !!user;
  // The following should fix itself after useUser is moved to typescript
  // eslint-disable @typescript-eslint/no-unsafe-member-access
  const email = user?.email;
  const isFreemium = user?.isFreemium;
  // eslint-enable @typescript-eslint/no-unsafe-member-access

  const accountMenuClassNames = classNames('header-account-menu', {
    'freemium-account-menu': isFreemium,
  });

  return (
    <div
      className={
        isDropboxDesignSystemEnabled
          ? 'header-navigation'
          : accountMenuClassNames
      }
    >
      {isSignedIn ? (
        <LoggedInMenu email={email} isFreemium={isFreemium} />
      ) : (
        <SignInButton />
      )}
    </div>
  );
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default AccountMenu;
