import React from 'react';
import { TertiaryButton } from './TertiaryButton';

type AlternativeTertiaryButtonProps = Omit<
  React.ComponentProps<typeof TertiaryButton>,
  'variation'
>;

export const AlternativeTertiaryButton = (
  props: AlternativeTertiaryButtonProps
): JSX.Element => <TertiaryButton variation="alternative" {...props} />;
