import React from 'react';
import styles from './FooterBottom.module.scss';

const FooterBottom = (): JSX.Element => (
  <div className={`container-fluid ${styles['footer-row']}`}>
    <p>&copy; Dropbox, Inc</p>
  </div>
);
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FooterBottom;
