interface IRandomComponent {
  component: JSX.Element | null;
  conditional: () => boolean;
  priority: number;
}

type IRandomComponents = Array<IRandomComponent>;
interface ComponentRandomizerProps {
  componentsToRandomize: IRandomComponents;
}

// Chooses components by highest priority in descending order
// This does NOT work correctly and presently will only randomize
// amongst components with the highest shared priority
const ComponentRandomizer = ({
  componentsToRandomize,
}: ComponentRandomizerProps): JSX.Element | null => {
  const availableComponents = componentsToRandomize.filter((component) =>
    component.conditional()
  );

  if (availableComponents.length > 0) {
    const maxPriority: number = Math.max(
      ...availableComponents.map((component) => component.priority)
    );

    // Only uses the components that matches the highest priority
    const maxPriorityComponents: IRandomComponents = availableComponents.filter(
      (component) => component.priority === maxPriority
    );

    return maxPriorityComponents[
      Math.floor(Math.random() * maxPriorityComponents.length)
    ].component;
  }

  return null;
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default ComponentRandomizer;
