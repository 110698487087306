import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const UploadIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M7,3.49129842 L5.15079137,5.07633438 C4.73146553,5.43575653 4.10016555,5.387195 3.7407434,4.96786915 C3.38132125,4.54854331 3.42988278,3.91724333 3.84920863,3.55782118 L8,5.34128297e-13 L12.1507914,3.55782118 C12.5701172,3.91724333 12.6186788,4.54854331 12.2592566,4.96786915 C11.8998345,5.387195 11.2685345,5.43575653 10.8492086,5.07633438 L9,3.49129842 L9,10 C9,10.5522847 8.55228475,11 8,11 C7.44771525,11 7,10.5522847 7,10 L7,3.49129842 Z M14,14 L14,9.5 C14,8.94771525 14.4477153,8.5 15,8.5 C15.5522847,8.5 16,8.94771525 16,9.5 L16,16 L0,16 L0,9.5 C0,8.94771525 0.44771525,8.5 1,8.5 C1.55228475,8.5 2,8.94771525 2,9.5 L2,14 L14,14 Z"
        fillRule="nonzero"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default UploadIcon;
