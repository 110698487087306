import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const DownloadIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M5.15079 5.92367L7 7.5087V1C7 0.447715 7.44772 0 8 0C8.55228 0 9 0.447715 9 1V7.5087L10.8492 5.92367C11.2685 5.56424 11.8998 5.6128 12.2593 6.03213C12.6187 6.45146 12.5701 7.08276 12.1508 7.44218L8 11L3.84921 7.44218C3.42989 7.08276 3.38132 6.45146 3.74075 6.03213C4.10017 5.6128 4.73147 5.56424 5.15079 5.92367ZM14 9.5V14H2V9.5C2 8.94771 1.55228 8.5 1 8.5C0.447715 8.5 0 8.94771 0 9.5V16H16V9.5C16 8.94771 15.5523 8.5 15 8.5C14.4477 8.5 14 8.94771 14 9.5Z"
        fillRule="nonzero"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default DownloadIcon;
