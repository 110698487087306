import React, { useState } from 'react';
import { Button } from '@dropbox/dig-components/buttons';
import { Checkbox } from '@dropbox/dig-components/controls';
import { Text } from '@dropbox/dig-components/typography';
import { InTextLink } from 'DesignSystem/Links';
import {
  useGetUserSubscriptionPreviewQuery,
  useGetUserSubscriptionDetailsQuery,
} from 'lib/graphql/MyAccount/__generated__/MyAccountQueries.generated';
import useTrack from 'lib/tracking/useTrack';
import { useFormatUserSubscriptionPrice } from 'hooks/useFormatUserSubscriptionPrice';
import styles from './ReactivateCTA.module.scss';

const PLAN_HANDLE = 'legal_monthly_12_reactivation';

const CancelTerms = () => {
  const { data } = useGetUserSubscriptionDetailsQuery();
  const currency = data?.user?.subscription?.currency ?? 'USD';
  return (
    <>
      <InTextLink
        dataTestid="reactivation-cancel-link"
        href="https://help.formswift.com/knowledge/how-do-i-cancel-my-trial-or-membership"
      >
        cancel
      </InTextLink>
      . Charges{' '}
      <InTextLink dataTestid="reactivation-terms-link" href="/terms">
        won&apos;t be refunded
      </InTextLink>{' '}
      unless it&apos;s legally required. All amounts shown are in {currency}.
    </>
  );
};

const TaxExclusivePricing = ({
  formattedSubscriptionSubtotal,
  formattedSubscriptionTax,
  formattedSubscriptionTotal,
}: {
  formattedSubscriptionSubtotal: string;
  formattedSubscriptionTax: string;
  formattedSubscriptionTotal: string;
}) => {
  return (
    <div className={styles['reactivate-cta-agreement-text']}>
      <Text>
        By clicking the &quot;Reactivate&quot; button, you agree to be charged{' '}
        {formattedSubscriptionTotal} ({formattedSubscriptionSubtotal} subtotal,{' '}
        {formattedSubscriptionTax} tax) immediately for your subscription, and{' '}
        {formattedSubscriptionSubtotal} plus applicable taxes per month
        thereafter until you <CancelTerms />
      </Text>
    </div>
  );
};

const TaxInclusivePricing = ({
  formattedSubscriptionTotal,
}: {
  formattedSubscriptionTotal: string;
}) => {
  return (
    <div className={styles['reactivate-cta-agreement-text']}>
      <Text>
        By clicking the &quot;Reactivate&quot; button, you agree to be charged{' '}
        {formattedSubscriptionTotal} immediately for your subscription, and
        every month thereafter until you <CancelTerms />
      </Text>
    </div>
  );
};

export const ReactivateCTA = ({
  reactivateSubscription,
}: {
  reactivateSubscription: () => Promise<void>;
}): JSX.Element => {
  const track = useTrack();
  const [termsAgreed, setTermsAgreed] = useState({
    hideErrorMessage: true,
    isChecked: false,
  });

  const {
    data: reactivationPreviewData,
    error,
    loading,
  } = useGetUserSubscriptionPreviewQuery({
    skip: !PLAN_HANDLE,
    variables: { planHandle: PLAN_HANDLE },
  });

  const nextBillingManifest =
    reactivationPreviewData?.user?.subscriptionPreview?.nextBillingManifest;
  const subscriptionTax = (nextBillingManifest?.totalTaxInCents || 0) / 100;
  const subscriptionSubtotal =
    (nextBillingManifest?.subtotalInCents || 0) / 100;
  const subscriptionTotal = (nextBillingManifest?.totalInCents || 0) / 100;
  const taxExclusive = !reactivationPreviewData?.user?.subscription
    ?.taxIncluded;

  const formattedSubscriptionTax = useFormatUserSubscriptionPrice({
    omitDecimalIfWholeNumber: true,
    price: subscriptionTax,
  });
  const formattedSubscriptionSubtotal = useFormatUserSubscriptionPrice({
    omitDecimalIfWholeNumber: true,
    price: subscriptionSubtotal,
  });
  const formattedSubscriptionTotal = useFormatUserSubscriptionPrice({
    omitDecimalIfWholeNumber: true,
    price: subscriptionTotal,
  });

  const toggleCheckbox = () => {
    if (!termsAgreed.isChecked) {
      void track({
        action: 'Reactivation',
        category: 'Checked Box within Modal V2',
      });
    }
    setTermsAgreed((state) => ({
      ...state,
      hideErrorMessage: true,
      isChecked: !state.isChecked,
    }));
  };

  const clickReactivateNow = async () => {
    if (!termsAgreed.isChecked) {
      setTermsAgreed((state) => ({ ...state, hideErrorMessage: false }));
      return;
    }
    await reactivateSubscription();
  };

  return (
    <div className={styles['reactivate-cta-container']}>
      {taxExclusive ? (
        <TaxExclusivePricing
          formattedSubscriptionSubtotal={formattedSubscriptionSubtotal}
          formattedSubscriptionTax={formattedSubscriptionTax}
          formattedSubscriptionTotal={formattedSubscriptionTotal}
        />
      ) : (
        <TaxInclusivePricing
          formattedSubscriptionTotal={formattedSubscriptionTotal}
        />
      )}
      <div className={styles['reactivate-cta-agree-to-terms-container']}>
        <Checkbox
          checked={termsAgreed.isChecked}
          data-testid="reactivate-plan-checkbox"
          onChange={toggleCheckbox}
        />
        <Text>
          I agree to the{' '}
          <InTextLink
            dataTestid="reactivation-terms-link"
            href="/terms"
            target="_self"
          >
            FormSwift Terms
          </InTextLink>{' '}
          and the <strong>Automatic Renewal Terms</strong> above. Learn about
          how we use and protect your data in our{' '}
          <InTextLink
            dataTestid="reactivation-privacy-link-"
            href="/privacy"
            target="_self"
          >
            Privacy Policy
          </InTextLink>
          .
        </Text>
      </div>
      <Text
        className={styles['terms-of-use-checkbox-error-message']}
        hidden={termsAgreed.hideErrorMessage}
        variant="label"
      >
        Please agree to the terms of service
      </Text>
      <div className={styles['reactivate-cta-button']}>
        <Button
          disabled={!!error || loading}
          onClick={clickReactivateNow}
          variant="primary"
        >
          Reactivate
        </Button>
      </div>
    </div>
  );
};
