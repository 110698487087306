import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

interface PageLeftProps extends BaseIconProps {
  fill?: string;
}

const PageLeft = ({ ...rest }: PageLeftProps): JSX.Element => (
  <BaseIcon {...rest} height={10} width={5}>
    <polygon fillRule="evenodd" points="5 0 5 10 0 5" strokeWidth="1" />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default PageLeft;
