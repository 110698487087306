import React from 'react';
import { useDisclosure, useUser } from 'hooks';
import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { NotificationLine } from '@dropbox/dig-icons/assets';
import { NotificationBadge } from '@dropbox/dig-components/dist/badges';
import { Menu } from '@dropbox/dig-components/dist/menu';
import FreemiumMenuDataFragment from '../gql/FreemiumMenuDataFragment';
import FreemiumMenuCta from './FreemiumMenuCta';
import { FreemiumMenuDownloads } from './FreemiumMenuDownloads.dig';
import styles from './FreemiumMenu.module.scss';

export const FreemiumMenu = ({
  userEmail,
}: {
  userEmail: string;
}): JSX.Element => {
  const { close, isOpen, open } = useDisclosure({
    defaultIsOpen: false,
  });
  const { data } = useUser(
    'FreemiumMenuDataFragment',
    FreemiumMenuDataFragment
  );
  // The following should fix itself after useUser is moved to typescript
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const user = data?.user;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const credits = user?.credits;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const isCurrentSubscriber = user?.isCurrentSubscriber;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const planId: string = user?.planId;

  let planType = 'Free';
  if (planId && planId.includes('monthly')) {
    planType = 'Monthly';
  } else if (planId && planId.includes('annual')) {
    planType = 'Annual';
  }

  return (
    <Menu.Wrapper onMouseEnter={open} onMouseLeave={close}>
      {({ getContentProps, getTriggerProps }) => (
        <>
          <span className={styles['freemium-notification-button']}>
            <IconButton
              {...getTriggerProps()}
              data-testid="freemium-notification-button"
              onClick={(e: React.MouseEvent) => e.preventDefault()}
              variant="borderless"
            >
              <UIIcon src={NotificationLine} />
            </IconButton>
            <NotificationBadge
              className={styles['notification-dot']}
              status="alert"
            />
          </span>
          <Menu.Content {...getContentProps()} open={isOpen}>
            <Menu.Segment>
              <Menu.Row withSubtitle={`${planType} plan`}>{userEmail}</Menu.Row>
              <Menu.Row className={styles['downloads-remaining-row']}>
                <FreemiumMenuDownloads
                  credits={credits}
                  isCurrentSubscriber={isCurrentSubscriber}
                />
              </Menu.Row>
              <FreemiumMenuCta isCurrentSubscriber={isCurrentSubscriber} />
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
