import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const ClockIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      id="clock-icon"
      stroke="none"
      strokeWidth="1"
    >
      <path
        d="M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M8,15.0588235 C11.8984806,15.0588235 15.0588235,11.8984806 15.0588235,8 C15.0588235,4.10151941 11.8984806,0.941176471 8,0.941176471 C4.10151941,0.941176471 0.941176471,4.10151941 0.941176471,8 C0.941176471,11.8984806 4.10151941,15.0588235 8,15.0588235 Z M8.47058824,3.23362019 L8.47058824,7.77571167 L11.0731519,9.88577512 C11.2750344,10.0494541 11.3060045,10.3458002 11.1423255,10.5476827 C10.9786466,10.7495651 10.6823005,10.7805352 10.480418,10.6168563 L7.52941176,8.22428833 L7.52941176,3.23362019 C7.52941176,2.97372149 7.74010129,2.76303196 8,2.76303196 C8.25989871,2.76303196 8.47058824,2.97372149 8.47058824,3.23362019 Z"
        fill="#8C99AD"
        fillRule="nonzero"
        id="Combined-Shape"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default ClockIcon;
