import React from 'react';
import styles from './index.module.scss';
import { IconPosition } from '../types';

interface BaseButtonIconProps {
  children?: React.ReactNode;
  iconPosition: IconPosition;
  position: IconPosition;
}

const BaseButtonIcon = ({
  children,
  iconPosition,
  position,
}: BaseButtonIconProps): JSX.Element | null =>
  children && iconPosition === position ? (
    <span className={styles[`ds-icon-${position}`]}>{children}</span>
  ) : null;

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default BaseButtonIcon;
