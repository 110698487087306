import { useCallback, useState } from 'react';

// Useful hooks from chakra-ui
// useControllable: https://github.com/chakra-ui/chakra-ui/blob/main/packages/hooks/src/use-controllable.ts
// useDisclosure: https://github.com/chakra-ui/chakra-ui/blob/main/packages/hooks/src/use-disclosure.ts

/**
 * Useful hook for handling both controlled and uncontrolled state in
 * the same component.
 * @param prop
 * @param state
 */
function useControllableProp<T>(prop: T | undefined, state: T) {
  const isControlled = prop !== undefined;
  const value = isControlled && typeof prop !== 'undefined' ? prop : state;
  return [isControlled, value];
}

interface UseDisclosureProps {
  defaultIsOpen?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  onToggle?: () => void;
}

/**
 * Useful hook for handling modal/alert/tooltip state
 *
 * Example usage (uncontrolled):
 *
 * const ContentWithModal = (props) => {
 *   const { isOpen, open, close } = useDisclosure({ defaultIsOpen: true });
 *   return (
 *     <div>
 *       Some Content
 *       { isOpen && <MyModal onClose={close} /> }
 *       <button onClick={open}>Open Modal</button>
 *     </div>
 *   );
 * }
 *
 * @param {Object} props
 * @param props.defaultIsOpen
 * @param props.isOpen Specify to use controlled component state.
 * @param props.onClose Called on close.
 * @param props.onOpen Called on open.
 * @param props.onToggle Called on open or close.
 *
 * @returns Use these functions to trigger opening and closing.
 */
export function useDisclosure(
  props: UseDisclosureProps = {}
): {
  isControlled: boolean;
  isOpen: boolean;
  close: () => void;
  open: () => void;
  toggle: () => void;
} {
  const {
    defaultIsOpen = false,
    isOpen: isOpenProp,
    onClose: onCloseProp,
    onOpen: onOpenProp,
    onToggle: onToggleProp,
  } = props;

  const [isOpenState, setIsOpen] = useState(defaultIsOpen);
  const [isControlled, isOpen] = useControllableProp(isOpenProp, isOpenState);

  const close = useCallback(() => {
    if (!isControlled) {
      setIsOpen(false);
    }
    if (onCloseProp) {
      onCloseProp();
    }
    if (onToggleProp) {
      onToggleProp();
    }
  }, [isControlled, onCloseProp, onToggleProp]);

  const open = useCallback(() => {
    if (!isControlled) {
      setIsOpen(true);
    }
    if (onOpenProp) {
      onOpenProp();
    }
    if (onToggleProp) {
      onToggleProp();
    }
  }, [isControlled, onOpenProp, onToggleProp]);

  const toggle = useCallback(() => {
    const action = isOpen ? close : open;
    action();
  }, [isOpen, open, close]);

  return {
    close,
    isControlled,
    isOpen: !!isOpen,
    open,
    toggle,
  };
}
