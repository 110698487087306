import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const AddFillableFieldsIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M1,3 L15,3 C15.5522847,3 16,3.44771525 16,4 L16,12 C16,12.5522847 15.5522847,13 15,13 L1,13 C0.44771525,13 0,12.5522847 0,12 L0,4 C0,3.44771525 0.44771525,3 1,3 Z M2,5 L2,11 L14,11 L14,5 L2,5 Z M4,8 L12,8 C12.5522847,8 13,8.44771525 13,9 C13,9.55228475 12.5522847,10 12,10 L4,10 C3.44771525,10 3,9.55228475 3,9 C3,8.44771525 3.44771525,8 4,8 Z"
        fill="#8C99AD"
        fillRule="nonzero"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default AddFillableFieldsIcon;
