import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const TypeIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <path
      clipRule="evenodd"
      d="M11.672 12H13.336L8.792 0.432007H6.592L2.048 12H3.72C3.90134 12 4.05334 11.9493 4.176 11.848C4.304 11.7467 4.38934 11.6347 4.432 11.512L5.296 9.15201H10.088L10.952 11.512C11.0053 11.6507 11.0907 11.768 11.208 11.864C11.3307 11.9547 11.4853 12 11.672 12ZM9.53601 7.63201H5.848L7.256 3.80001C7.32534 3.62401 7.39467 3.41867 7.464 3.18401C7.53867 2.94934 7.61334 2.69601 7.688 2.42401C7.76267 2.69601 7.83734 2.94934 7.912 3.18401C7.992 3.41334 8.064 3.61334 8.128 3.78401L9.53601 7.63201Z"
      fillRule="evenodd"
    />
    <path d="M1 14C0.447715 14 0 14.4477 0 15C0 15.5523 0.447715 16 1 16H15C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14H1Z" />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default TypeIcon;
