import React from 'react';
import PropTypes from 'prop-types';

export const ModalContent = ({ children, className }) => (
  <div className={className}>{children}</div>
);

ModalContent.defaultProps = {
  children: null,
  className: '',
};

ModalContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
