import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PrimaryButton } from '../PrimaryButton';
import { TrashIcon } from '../../Icons';
import { ElementWithTooltip } from '../../Tooltips';
import styles from './TrashButton.module.scss';

export const TrashButton = ({
  children,
  className,
  dataTestid,
  mouseHandlers,
  onClick,
  tooltipClassName,
  tooltipSize,
  tooltipText,
  ...buttonProps
}) => {
  const classes = classnames(className, styles['trash-button']);
  return (
    <ElementWithTooltip
      className={tooltipClassName}
      size={tooltipSize}
      targetElement={
        <PrimaryButton
          className={classes}
          dataTestid={dataTestid}
          onClick={onClick}
          options={children ? [] : ['square-padding']}
          size="sm"
          variation="destructive"
          {...mouseHandlers}
          {...buttonProps}
        >
          <TrashIcon />
          {children && <span>{children}</span>}
        </PrimaryButton>
      }
      tooltipId="deleteButtonTooltip"
    >
      <p className={styles['trash-button-tooltip']}>{tooltipText}</p>
    </ElementWithTooltip>
  );
};

TrashButton.defaultProps = {
  children: null,
  className: '',
  tooltipClassName: '',
  tooltipSize: undefined,
  tooltipText: 'Delete Document Permanently',
};

TrashButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestid: PropTypes.string.isRequired,
  mouseHandlers: PropTypes.shape({
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onMouseOut: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  tooltipClassName: PropTypes.string,
  tooltipSize: PropTypes.string,
  tooltipText: PropTypes.string,
};
