import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const EsignTextIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      id="esign-text-icon"
      stroke="none"
      strokeWidth="1"
    >
      <path
        clipRule="evenodd"
        d="M11.672 11.9996H13.336L8.792 0.431641H6.592L2.048 11.9996H3.72C3.90134 11.9996 4.05334 11.949 4.176 11.8476C4.304 11.7463 4.38934 11.6343 4.432 11.5116L5.296 9.15164H10.088L10.952 11.5116C11.0053 11.6503 11.0907 11.7676 11.208 11.8636C11.3307 11.9543 11.4853 11.9996 11.672 11.9996ZM9.53601 7.63164H5.848L7.256 3.79964C7.32534 3.62364 7.39467 3.41831 7.464 3.18364C7.53867 2.94897 7.61334 2.69564 7.688 2.42364C7.76267 2.69564 7.83734 2.94897 7.912 3.18364C7.992 3.41297 8.064 3.61297 8.128 3.78364L9.53601 7.63164Z"
        fill="#37455B"
        fillRule="evenodd"
      />
      <path
        d="M1 13.9996C0.447715 13.9996 0 14.4473 0 14.9996C0 15.5519 0.447715 15.9996 1 15.9996H15C15.5523 15.9996 16 15.5519 16 14.9996C16 14.4473 15.5523 13.9996 15 13.9996H1Z"
        fill="#37455B"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default EsignTextIcon;
