import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const EsignDateIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      id="esign-text-icon"
      stroke="none"
      strokeWidth="1"
    >
      <path
        clipRule="evenodd"
        d="M2.3999 0H3.9999V2.39999H7.1999V0H8.7999V2.39999H11.9999V0H13.5999V2.39999H16V16H0V2.39999H2.3999V0ZM4.8001 6.40002H1.6001V9.60003H4.8001V6.40002ZM9.6001 6.40002H6.4001V9.60003H9.6001V6.40002ZM14.4001 6.40002H11.2001V9.60003H14.4001V6.40002ZM4.8001 11.2H1.6001V14.4H4.8001V11.2ZM9.6001 11.2H6.4001V14.4H9.6001V11.2ZM14.4001 11.2H11.2001V14.4H14.4001V11.2Z"
        fill="#37455B"
        fillRule="evenodd"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default EsignDateIcon;
