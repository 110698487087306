import React from 'react';
import styles from './index.module.scss';

const FreemiumMenuHeaderText = ({
  credits,
  isCurrentSubscriber,
}: {
  credits: number;
  isCurrentSubscriber: boolean;
}): JSX.Element => {
  let headerText;

  if (isCurrentSubscriber) {
    headerText = 'Unlimited Downloads';
  } else if (credits === 1) {
    headerText = '1 download remaining';
  } else {
    headerText = `${credits} downloads remaining`;
  }

  return <p className={styles['freemium-menu-header-text']}>{headerText}</p>;
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FreemiumMenuHeaderText;
