import React from 'react';
import { PrimaryButton } from '..';

interface ConfirmButtonProps
  extends Omit<React.ComponentProps<typeof PrimaryButton>, 'variation'> {
  mouseHandlers: {
    onBlur: React.FocusEventHandler<HTMLButtonElement>;
    onFocus: React.FocusEventHandler<HTMLButtonElement>;
    onMouseOut: React.MouseEventHandler<HTMLButtonElement>;
    onMouseOver: React.MouseEventHandler<HTMLButtonElement>;
  };
}

export const ConfirmButton = ({
  className,
  mouseHandlers,
  onClick,
  size = 'xs',
  ...buttonProps
}: ConfirmButtonProps): JSX.Element => (
  <PrimaryButton
    className={className}
    onClick={onClick}
    size={size}
    variation="dark"
    {...mouseHandlers}
    {...buttonProps}
  >
    Confirm
  </PrimaryButton>
);
