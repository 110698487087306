import PropTypes from 'prop-types';
import { withTypography } from './withTypography';

export const ProductTypes = {
  Default: 'default',
  None: '',
  Small: 'small',
  Smallest: 'smallest',
};

export const ProductContent = withTypography('p', { className: 'ds-product' });

ProductContent.defaultProps = {
  className: '',
  dataTestid: '',
  variation: 'default',
};

// Also accepts all <p /> attributes
ProductContent.propTypes = {
  className: PropTypes.string,
  dataTestid: PropTypes.string,
  variation: PropTypes.oneOf(Object.values(ProductTypes)),
};
