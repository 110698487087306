import React, { useState } from 'react';
import { CloseSmallIcon } from 'DesignSystem/Icons';
import classnames from 'classnames';
import styles from './index.module.scss';

export interface BannerProps {
  buttonColor?: string;
  children: React.ReactNode;
  className?: string;
  isCloseable?: boolean;
  onCloseClick?: () => void;
}

export const Banner = ({
  buttonColor = '#000',
  children,
  className,
  isCloseable = true,
  onCloseClick,
}: BannerProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    onCloseClick?.();
  };

  return (
    <>
      {isVisible &&
        (isCloseable ? (
          <div
            className={classnames(className, styles['closable-banner-wrapper'])}
          >
            {children}
            <button
              className={styles['banner-button']}
              data-testid="close-button"
              onClick={handleClose}
              style={{
                color: buttonColor,
              }}
              type="button"
            >
              <CloseSmallIcon />
            </button>
          </div>
        ) : (
          <div className={className}>{children}</div>
        ))}
    </>
  );
};
