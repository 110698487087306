import React from 'react';
import { Banner } from '@dropbox/dig-components/banner';
import { Link, Text } from '@dropbox/dig-components/typography';
import { ThemeProvider } from '@dropbox/dig-foundations';

export const CSATSurveyBanner = (): JSX.Element => {
  return (
    <ThemeProvider
      _dangerouslyIncludeAdditionalClassName="dig-theme--FormSwift"
      mode="bright"
    >
      {({ getThemeProps }) => (
        <div {...getThemeProps()}>
          <Banner style={{ display: 'flex', justifyContent: 'center' }}>
            <Text isBold>Please let us know how we&apos;re doing by </Text>
            <Banner.Actions>
              <Link
                data-testid="csat-survey-link"
                hasNoUnderline
                href="https://formswift.typeform.com/to/QCWvUf05"
                isBold
              >
                answering a few questions
              </Link>
            </Banner.Actions>
            <Text isBold>!</Text>
          </Banner>
        </div>
      )}
    </ThemeProvider>
  );
};
