import PropTypes from 'prop-types';
import { withTypography } from './withTypography';

export const HeadingC = withTypography('h4', { className: 'ds-heading-c' });

HeadingC.defaultProps = {
  className: '',
};

// Also accepts all <h4 /> attributes
HeadingC.propTypes = {
  className: PropTypes.string,
};
