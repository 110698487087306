import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Banner } from '@dropbox/dig-components/banner';
import { Text } from '@dropbox/dig-components/typography';
import { Button } from '@dropbox/dig-components/buttons';
import { setReactivationModalOpen } from 'layouts/GlobalModals/GlobalModalsReducer';
import useTrack from 'lib/tracking/useTrack';

export const MembersAreaReactivationBannerRedesign = (): JSX.Element => {
  const dispatch = useDispatch();
  const track = useTrack();
  const openReactivationModal = () => {
    void track({
      action: 'Reactivation',
      category: 'Clicked Banner v2',
    });
    dispatch(setReactivationModalOpen(true));
  };

  useEffect(() => {
    void track({
      action: 'Reactivation',
      category: 'Shown Banner v2',
    });
  }, [track]);

  return (
    <Banner
      style={{ display: 'flex', justifyContent: 'center' }}
      type="warning"
      withLeftIcon={false}
    >
      <Banner.Message>
        <Text>
          Reactivate your account today to export or print your documents.
        </Text>
      </Banner.Message>
      <Banner.Actions>
        <Button
          aria-label="reactivate subscription"
          onClick={openReactivationModal}
          size="small"
          variant="primary"
        >
          Reactivate
        </Button>
      </Banner.Actions>
    </Banner>
  );
};
