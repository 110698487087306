import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Overlay, Tooltip } from 'react-bootstrap';
import classnames from 'classnames';
import { TooltipBody } from './components/TooltipBody';
import {
  TooltipDisplayEvents,
  TooltipPlacements,
  TooltipSizes,
} from './TooltipConstants';
import styles from './ElementWithTooltip.module.scss';

export const ElementWithTooltip = ({
  children,
  className,
  closeIconDataTestid,
  displayEvent,
  placement,
  showByDefault,
  showCloseButton,
  size,
  targetClassName,
  targetElement,
  tertiaryBtn,
  tooltipId,
  wrapperClassName,
}) => {
  const [show, setShow] = useState(showByDefault);
  const target = useRef(null);

  const toggleTooltip = (value) => (event) => {
    const { type } = event;
    const clickEvent = type === 'click' && displayEvent === 'click';
    const hoverEvent =
      (type === 'mouseenter' || type === 'mouseleave') &&
      displayEvent === 'hover';

    if (clickEvent || hoverEvent) {
      setShow(value);
    }
  };

  const closeTooltip = () => {
    setShow(false);
  };

  const tooltipClasses = classnames(
    styles['ds-tooltip'],
    styles[`ds-tooltip-${size}`],
    className
  );

  const targetClasses = classnames(
    styles['ds-tooltip-target'],
    targetClassName
  );

  const wrapperClasses = classnames(
    styles['ds-tooltip-wrapper'],
    wrapperClassName
  );

  return (
    <div className={wrapperClasses}>
      <div
        ref={target}
        className={targetClasses}
        onClick={toggleTooltip(!show)}
        onMouseEnter={toggleTooltip(true)}
        onMouseLeave={toggleTooltip(false)}
      >
        {targetElement}
      </div>
      <Overlay placement={placement} show={show} target={target}>
        <Tooltip
          className={tooltipClasses}
          data-testid={tooltipId}
          id={tooltipId}
        >
          <TooltipBody
            closeIconDataTestid={closeIconDataTestid}
            closeTooltipOnClick={closeTooltip}
            showCloseButton={showCloseButton}
            tertiaryBtn={tertiaryBtn}
          >
            {children}
          </TooltipBody>
        </Tooltip>
      </Overlay>
    </div>
  );
};

ElementWithTooltip.defaultProps = {
  children: null,
  className: '',
  closeIconDataTestid: '',
  displayEvent: 'hover',
  placement: 'bottom',
  showByDefault: false,
  showCloseButton: false,
  size: 'sm',
  targetClassName: '',
  tertiaryBtn: null,
  wrapperClassName: '',
};

ElementWithTooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeIconDataTestid: PropTypes.string,
  displayEvent: PropTypes.oneOf(TooltipDisplayEvents),
  placement: PropTypes.oneOf(TooltipPlacements),
  showByDefault: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  size: PropTypes.oneOf(TooltipSizes),
  targetClassName: PropTypes.string,
  targetElement: PropTypes.node.isRequired,
  tertiaryBtn: PropTypes.shape({
    className: PropTypes.string,
    copy: PropTypes.string,
    dataTestid: PropTypes.string.isRequired,
    enableCloseTooltip: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  tooltipId: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
};
