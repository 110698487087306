import React from 'react';
import { trackEvent } from 'Tracking/Actions';
import { useDispatch } from 'react-redux';
import { Banner } from 'DesignSystem/Banner';
import { Col, Row } from 'react-bootstrap';
import { useRouter } from 'next/router';
import styles from './MembersAreaReactivationBanner.module.scss';

export interface ReactivationBannerProps {
  page: string;
}

export const MembersAreaReactivationBanner = ({
  page,
}: ReactivationBannerProps): JSX.Element => {
  const dispatch = useDispatch();
  const router = useRouter();

  const onReactivationBannerClick = () => {
    dispatch(trackEvent('Reactivate Banner', page));
    void router.push('/my-account?subscription_settings_scroll=1');
  };

  return (
    <Row className={styles['reactivation-banner-row']}>
      <Col className={styles['reactivation-banner-col']}>
        <Banner isCloseable={false}>
          <div className={styles['reactivation-banner']}>
            <p>
              <button onClick={onReactivationBannerClick} type="button">
                Reactivate
              </button>{' '}
              your account today to export your documents!
            </p>
          </div>
        </Banner>
      </Col>
    </Row>
  );
};
