import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const pathData = [
  'M6 0.5C6 0.223858 6.22386 0 6.5 0H9.5C9.77614 0 10 0.223858 10 0.5V2H13.5C13.7761 2 14 2.22386',
  '14 2.5C14 2.77614 13.7761 3 13.5 3H9.5H6.5H2.5C2.22386 3 2 2.77614 2 2.5C2 2.22386 2.22386 2',
  '2.5 2H6V0.5ZM7 1H9V2H7V1ZM2.99534 5.08504C2.94552 4.50132 3.40588 4 3.99171 4H12.0083C12.5937 4',
  '13.0539 4.50062 13.0047 5.08396L12.1622 15.084C12.1186 15.6019 11.6855 16 11.1657',
  '16H4.84516C4.32584 16 3.89294 15.6025 3.84878 15.085L2.99534 5.08504ZM8.00001 5.5C8.27616 5.5',
  '8.50001 5.72386 8.50001 6V14C8.50001 14.2761 8.27616 14.5 8.00001 14.5C7.72387 14.5 7.50001',
  '14.2761 7.50001 14V6C7.50001 5.72386 7.72387 5.5 8.00001 5.5ZM10.9707 6.02692C10.9866 5.74076',
  '10.7589 5.5 10.4723 5.5C10.2073 5.5 9.98851 5.70699 9.97382 5.97154L9.52929 13.9731C9.51339',
  '14.2592 9.74115 14.5 10.0277 14.5C10.2927 14.5 10.5115 14.293 10.5262 14.0285L10.9707',
  '6.02692ZM5.52775 5.5C5.79271 5.5 6.01151 5.70699 6.02621 5.97154L6.47074 13.9731C6.48664',
  '14.2592 6.25888 14.5 5.97228 14.5C5.70732 14.5 5.48852 14.293 5.47382 14.0285L5.02929',
  '6.02692C5.01339 5.74076 5.24115 5.5 5.52775 5.5Z',
].join(' ');

const TrashIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path d={pathData} />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default TrashIcon;
