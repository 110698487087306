import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Icon } from 'DesignSystem/Icons/Icon';
import {
  useSearchFocus,
  useSetFocusDown,
  useSetFocusTo,
  useSetFocusUp,
} from 'DesignSystem/SearchBar/SearchContext/selectorHooks';
import styles from './AutoCompleteItem.module.scss';
import { AutoCompleteDropdownEntry } from '../types';
import createKeyDownHandler from './createKeyDownHandler';

export type IconPosition = 'left' | 'right';
export type IconDisplay = 'always' | 'hover';

export interface AutoCompleteDropdownItemProps<ItemType> {
  containerClass?: string;
  entry: AutoCompleteDropdownEntry<ItemType>;
  iconClassName?: string;
  iconDisplay?: IconDisplay;
  iconName?: string;
  iconPosition?: IconPosition;
  index: number;
  isLastItem: boolean;
  onSelect: (item: ItemType | undefined | null) => void;
  role?: string;
}

const AutoCompleteItem = <ItemType,>({
  containerClass,
  entry,
  iconClassName,
  iconDisplay,
  iconName,
  iconPosition,
  index,
  isLastItem,
  onSelect,
  role,
}: AutoCompleteDropdownItemProps<ItemType>): JSX.Element => {
  const itemRef = useRef<HTMLButtonElement>(null);
  const searchFocus = useSearchFocus();
  const setFocusDown = useSetFocusDown();
  const setFocusTo = useSetFocusTo();
  const setFocusUp = useSetFocusUp();

  const iconClasses = classNames(
    { [styles['icon-right']]: iconPosition === 'right' },
    { [styles['icon-left']]: iconPosition === 'left' },
    { [styles['hover-display']]: iconDisplay === 'hover' },
    { [styles['always-display']]: iconDisplay === 'always' },
    iconClassName
  );

  const containerClasses = classNames(
    containerClass,
    styles['autocomplete-dropdown-item'],
    {
      [styles['reverse-order']]: iconPosition === 'right',
    }
  );

  const onKeyDown = createKeyDownHandler(
    index,
    isLastItem,
    setFocusDown,
    setFocusTo,
    setFocusUp
  );

  useEffect(() => {
    if (searchFocus === index) {
      itemRef.current?.focus();
    }
  }, [searchFocus, index]);

  return (
    <button
      ref={itemRef}
      className={containerClasses}
      data-testid={entry.key}
      onClick={() => onSelect(entry.item)}
      onKeyDown={onKeyDown}
      role={role}
      type="button"
    >
      {iconName && (
        <span className={iconClasses}>
          <Icon
            dataTestid={entry.key ? `${entry.key}-icon` : undefined}
            type={iconName}
          />
        </span>
      )}
      {entry.content}
    </button>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default AutoCompleteItem;
