import React from 'react';
import BaseIcon, { BaseIconProps } from 'DesignSystem/Icons/BaseIcon';

const ItalicIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props} viewBox="0 0 6 9">
    <path d="M0.828613 8.44141C0.837077 8.44141 0.851888 8.44352 0.873047 8.44775C1 8.46045 1.09098 8.4668 1.146 8.4668C1.64111 8.4668 1.99235 7.86165 2.19971 6.65137C2.22087 6.52865 2.23779 6.43555 2.25049 6.37207L2.96143 2.354C3.00374 2.12972 3.03548 1.93506 3.05664 1.77002C3.08203 1.60075 3.09473 1.46745 3.09473 1.37012C3.09473 1.19661 3.03125 1.07178 2.9043 0.995605C2.77734 0.915202 2.5721 0.875 2.28857 0.875H2.14258L2.23779 0.329102C2.61019 0.354492 2.94027 0.373535 3.22803 0.38623C3.52002 0.398926 3.76546 0.405273 3.96436 0.405273C4.31982 0.405273 4.65837 0.401042 4.97998 0.392578C5.3016 0.379883 5.61686 0.362956 5.92578 0.341797L5.81152 0.875C5.7819 0.875 5.74382 0.872884 5.69727 0.868652C5.65495 0.864421 5.62321 0.862305 5.60205 0.862305C5.30583 0.862305 5.09212 0.936361 4.96094 1.08447C4.83398 1.22835 4.71338 1.60921 4.59912 2.22705L3.91357 6.01025C3.82048 6.51383 3.75488 6.90104 3.7168 7.17188C3.68294 7.43848 3.66602 7.63949 3.66602 7.7749C3.66602 8.03727 3.72314 8.21501 3.8374 8.30811C3.95589 8.39697 4.18441 8.44141 4.52295 8.44141H4.61182L4.51025 9C3.91781 8.96191 3.49251 8.93864 3.23438 8.93018C2.98047 8.91748 2.76465 8.91113 2.58691 8.91113C2.33301 8.91113 1.75537 8.93864 0.854004 8.99365L0.727051 9L0.828613 8.44141Z" />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default ItalicIcon;
