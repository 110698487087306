import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const CloseIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M7.99139215,6.57717859 L14.2746831,0.311104672 C14.6657428,-0.0788834754 15.2989072,-0.0780148095 15.6888953,0.313044892 C16.0788835,0.704104593 16.0780148,1.33726898 15.6869551,1.72725712 L9.40560705,7.99139349 L15.668745,14.2545314 C16.0592693,14.6450557 16.0592693,15.2782207 15.668745,15.668745 C15.2782207,16.0592693 14.6450557,16.0592693 14.2545314,15.668745 L7.98945326,9.40366683 L1.68695511,15.6888953 C1.29589541,16.0788835 0.662731024,16.0780148 0.272742877,15.6869551 C-0.11724527,15.2958954 -0.116376605,14.662731 0.274683097,14.2727429 L6.57523837,7.98945193 L0.292893219,1.70710678 C-0.0976310729,1.31658249 -0.0976310729,0.683417511 0.292893219,0.292893219 C0.683417511,-0.0976310729 1.31658249,-0.0976310729 1.70710678,0.292893219 L7.99139215,6.57717859 Z"
        fillRule="nonzero"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default CloseIcon;
