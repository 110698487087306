import React from 'react';
import * as Icons from 'DesignSystem/Icons';

interface IconLib {
  [index: string]: React.ComponentType;
}

const IconLib = (Icons as unknown) as IconLib;

interface BaseIconProps {
  children?: React.ReactNode;
  className?: string;
  dataTestid?: string;
  height?: number;
  onClick?: React.MouseEventHandler<HTMLOrSVGElement>;
  scale?: number;
  transform?: string;
  viewBox?: string;
  width?: string;
}

interface IconProps extends BaseIconProps {
  type: string;
}

export const Icon = ({ type, ...rest }: IconProps): JSX.Element | null => {
  const IconType = IconLib[type];
  return IconType ? <IconType {...rest} /> : null;
};
