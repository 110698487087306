import React from 'react';
import { Banner } from 'DesignSystem/Banner';
import { InTextLink } from 'DesignSystem/Links';
import useTrack from 'lib/tracking/useTrack';
import styles from './index.module.scss';

export interface FreemiumBannerProps {
  page: string;
}

const FreemiumBanner = ({ page }: FreemiumBannerProps): JSX.Element | null => {
  const track = useTrack();
  const fireTracking = () => {
    void track({ action: page, category: 'Upgrade Banner' });
  };
  return (
    <Banner className={styles.banner} isCloseable={false}>
      <div>
        Looks like you&apos;re out of free downloads. To download this document,{' '}
        <InTextLink
          dataTestid="upgradeFreemium"
          href="/upgrade-freemium"
          onClick={fireTracking}
          target=""
        >
          upgrade
        </InTextLink>{' '}
        to a paid plan today &amp; save 20%.
      </div>
    </Banner>
  );
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FreemiumBanner;
