import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const FolderIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon height={12} viewBox="0 0 16 12" {...props}>
    <path
      d="M2 4V10H12V12H1C0.447716 12 0 11.5523 0 11V3C0 2.44772 0.447715 2 1 2H4V4H2Z"
      fill="#37455B"
    />
    <path d="M7 4H14V3C14 2.44772 13.5523 2 13 2H7V4Z" fill="#37455B" />
    <path
      d="M2 1C2 0.447715 2.44772 0 3 0H8C8.55228 0 9 0.447715 9 1V2H2V1Z"
      fill="#37455B"
    />
    <path
      clipRule="evenodd"
      d="M5.31879 7L4.03308 10H11.6812L12.9669 7H5.31879ZM4 5L1 12H13L16 5H4Z"
      fill="#37455B"
      fillRule="evenodd"
    />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FolderIcon;
