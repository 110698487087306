import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const CaretDown = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props} height={5} width={10}>
    <polygon fillRule="evenodd" points="0 0 10 0 5 5" strokeWidth="1" />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default CaretDown;
