import React, { useEffect, useRef, useState, ChangeEvent } from 'react';
import { TextInput } from 'DesignSystem/TextInputs';
import {
  useSetFocusDown,
  useSetFocusTo,
  useSetSearchTerm,
  useSearchFocus,
  useSearchMethod,
  useSearchStatus,
  useSearchTermSync,
  createKeyDownHandler,
} from 'DesignSystem/SearchBar';
import { Icon } from 'DesignSystem/Icons/Icon';
import { SearchFieldProps } from 'DesignSystem/SearchBar/types';
import { FOCUS } from 'DesignSystem/SearchBar/SearchContext/Constants';

export const DEFAULT_PLACEHOLDER = 'Enter search terms';
export const DEFAULT_SHOW_BUTTON = true;

export const hooks = {
  useFieldValue: (): [string, (fieldValue: string) => void] => useState(''),
};

const getIcon = (
  iconName?: string | null,
  iconPosition?: string | null
): JSX.Element | undefined => {
  return iconName && iconPosition === 'input' ? (
    <Icon dataTestid="search-field-icon" type={iconName} />
  ) : undefined;
};

export const SearchField: React.FC<SearchFieldProps> = ({
  ariaLabel,
  dataTestid = 'search-bar',
  iconName,
  iconPosition,
  placeholder,
  searchResultsId,
  showButton,
}: SearchFieldProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const searchFocus = useSearchFocus();
  const setFocusDown = useSetFocusDown();
  const setFocusTo = useSetFocusTo();
  const setSearchTerm = useSetSearchTerm();
  const search = useSearchMethod();
  const searchStatus = useSearchStatus();
  const [fieldValue, setFieldValue] = hooks.useFieldValue();

  const onKeyDown = createKeyDownHandler(
    fieldValue,
    setFocusDown,
    setSearchTerm,
    search,
    searchStatus
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(event.target.value);
  };

  const clearFieldValue = () => {
    setFieldValue('');
  };

  useSearchTermSync(fieldValue, showButton);

  useEffect(() => {
    if (searchFocus === FOCUS.INPUT) {
      inputRef.current?.focus();
    }
  }, [searchFocus]);

  return (
    <TextInput
      ref={inputRef}
      aria-autocomplete="list"
      aria-controls={searchResultsId}
      aria-expanded={
        searchFocus !== FOCUS.NONE &&
        (searchStatus === 'complete' || searchStatus === 'no_result')
      }
      aria-haspopup="listbox"
      aria-label={ariaLabel}
      dataTestid={dataTestid}
      id={dataTestid}
      onChange={onChange}
      onClose={clearFieldValue}
      onFocus={() => setFocusTo?.(FOCUS.INPUT)}
      onKeyDown={onKeyDown}
      placeholder={placeholder || DEFAULT_PLACEHOLDER}
      role="combobox"
      showCloseIcon
      value={fieldValue || ''}
    >
      {getIcon(iconName, iconPosition)}
    </TextInput>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default SearchField;
