import React from 'react';
import { useFeature } from '@optimizely/react-sdk';
import { NavBar } from 'Header/NavBar.dig';
import NonFreemiumMenu from '../NonFreemiumMenu';
import FreemiumMenu from '../FreemiumMenu';

const LoggedInMenu = ({
  email,
  isFreemium,
}: {
  email: string;
  isFreemium: boolean;
}): JSX.Element => {
  const [isDropboxDesignSystemEnabled] = useFeature('dropbox_design_system');

  if (isDropboxDesignSystemEnabled) {
    return <NavBar isFreemium={isFreemium} userEmail={email} />;
  }

  return isFreemium ? <FreemiumMenu userEmail={email} /> : <NonFreemiumMenu />;
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default LoggedInMenu;
