import { MyAccountConstants } from './Constants';

export const hideModal = () => (dispatch) =>
  dispatch({
    type: MyAccountConstants.MODAL_HIDE,
  });

export const showProgressModal = () => (dispatch) =>
  dispatch({
    type: MyAccountConstants.PROGRESS_MODAL_SHOW,
  });
