import PropTypes from 'prop-types';
import { withTypography } from './withTypography';

export const HeadingB = withTypography('h4', { className: 'ds-heading-b' });

HeadingB.defaultProps = {
  className: '',
};

// Also accepts all <h4 /> attributes
HeadingB.propTypes = {
  className: PropTypes.string,
};
