import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const ImageIcon = ({ ...props }: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <path
      clipRule="evenodd"
      d="M2 2V14H14V2H2ZM1 0C0.447715 0 0 0.447715 0 1V15C0 15.5523 0.447715 16 1 16H15C15.5523 16 16 15.5523 16 15V1C16 0.447715 15.5523 0 15 0H1Z"
      fillRule="evenodd"
    />
    <path d="M8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4C7.10457 4 8 4.89543 8 6Z" />
    <path d="M5 9L2 14H15V9.5L12 5.5L8 11.5L5 9Z" />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default ImageIcon;
