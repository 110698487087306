import React from 'react';
import Image from 'Image';
import { useWindowSize } from 'hooks';

const Logo = () => {
  const { isMaxWidthXxs } = useWindowSize();
  const imageFile = isMaxWidthXxs ? 'site-logo-stacked.png' : 'site-logo.png';
  return (
    <a href="/">
      <Image
        alt="FormSwift"
        src={`/static/images/${imageFile}`}
      />
    </a>
  );
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default Logo;
