import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { trackEvent } from 'Tracking/Actions';
import { DocumentNode, useLazyQuery } from '@apollo/client';
import {
  useSearchStatus,
  useSearchTerm,
  useSetSearchMethod,
  useSetSearchResults,
  useSetSearchStatus,
} from 'DesignSystem/SearchBar';

export interface IsEmptyPredicate<ResultType> {
  (result?: ResultType): boolean;
}

const defaultIsEmpty = <ResultType,>(result?: ResultType): boolean => {
  const resultObj = result as Record<string, unknown> | Array<unknown>;
  return !resultObj || Object.keys(resultObj).length === 0;
};

export const useGraphQLSearch = <ResultType,>(
  query: DocumentNode,
  isEmptyResult: IsEmptyPredicate<ResultType> = defaultIsEmpty,
  trackingCategory?: string
): void => {
  const dispatch = useDispatch();
  const searchTerm = useSearchTerm();
  const setResults = useSetSearchResults();
  const setSearchMethod = useSetSearchMethod();
  const setStatus = useSetSearchStatus();
  const status = useSearchStatus();

  const [
    search,
    { called, data, loading, variables },
  ] = useLazyQuery<ResultType>(query);

  const searchedTerm = variables?.searchTerm;

  // Change to idle state if (1) the search function hasn't been called, or (2) there is no
  // search term, or (3) the search term has changed since the last result.
  const idle = !called || !searchedTerm || searchedTerm !== searchTerm;

  // A query is complete if we are not currently loading and the current search term
  // is what was last searched.  A query might go straight from "idle" to a terminal
  // status if the results are in cache.
  const queryDone = called && !loading && searchedTerm === searchTerm;

  // If the query is complete, decide whether we need to update the status based on
  // whether there is data.
  const returnedNoResults = queryDone && isEmptyResult(data);
  const returnedResults = queryDone && !returnedNoResults;

  useEffect(() => {
    setSearchMethod?.((term: string) => {
      if (term) {
        void search({ variables: { searchTerm: term } });

        if (trackingCategory) {
          dispatch(trackEvent(trackingCategory, term));
        }
      }
    });
  }, [dispatch, search, setSearchMethod, trackingCategory]);

  useEffect(() => {
    if (idle && status !== 'idle') {
      setStatus?.('idle');
    } else if (loading && status !== 'loading') {
      setStatus?.('loading');
    } else if (returnedNoResults && status !== 'no_result') {
      setResults?.(undefined);
      setStatus?.('no_result');
    } else if (returnedResults && status !== 'complete') {
      setResults?.(data);
      setStatus?.('complete');
    }
  });
};
