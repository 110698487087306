import getConfig from 'next/config';

interface RuntimeConfig {
  APP_ENV: string;
}

/*
  Gets the page tracking event name for optimizely given the current pathname.
  Examples:
  1. /privacy -> visit_page_privacy_production
  2. /my-faxes/sent -> visit_page_my-faxes_sent_production
  3. /builder/tax/[documentType]/[sessionId]/[sessionHash] -> visit_page_builder_tax_production
  4. /my-account?show_reactivation_success=1 -> visit_page_my-account_show_reactivation_success_production
*/
const getPageTrackingEvent = (
  pathname: string,
  query: Record<string, string>
): string => {
  const {
    publicRuntimeConfig,
  }: { publicRuntimeConfig: RuntimeConfig } = getConfig();
  const { APP_ENV }: RuntimeConfig = publicRuntimeConfig;
  const segments = ['visit_page'];

  segments.push(
    `${pathname
      .slice(1)
      .replace('/[documentType]/[sessionId]/[sessionHash]', '')
      .replace(/\[/g, '')
      .replace(/\]/g, '')
      .replace(/\//g, '_')}`
  );
  if (query.show_reactivation_success && pathname === '/my-account') {
    segments.push('show_reactivation_success');
  }
  segments.push(APP_ENV);
  return segments.join('_');
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default getPageTrackingEvent;
