import { createContext, useContext } from 'react';
import { UserDevice } from 'lib/optimizely/getUserDevice';

export { UserDevice };

export interface SSRDeviceContextProps {
  device?: UserDevice;
}

export const SSRDeviceContext = createContext<SSRDeviceContextProps>({
  device: 'unknown' as UserDevice,
});

export const useSSRRenderDeviceInfo = (): SSRDeviceContextProps =>
  useContext(SSRDeviceContext);
