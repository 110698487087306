import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const PrepareToSendIcon = ({ ...props }: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <path
      clipRule="evenodd"
      d="M14.8006 0.531855C15.1965 0.383407 15.5865 0.762741 15.449 1.16256L10.6379 15.1586C10.5006 15.558 9.96057 15.6178 9.73921 15.2581L6.96726 10.7537L9.84796 7.20818C10.1013 6.89636 10.078 6.44348 9.79386 6.15939C9.50976 5.87529 9.05689 5.85193 8.74506 6.10528L5.13728 9.03661L0.763076 6.68127C0.384999 6.47769 0.422501 5.92365 0.824564 5.77287L14.8006 0.531855Z"
      fill="#37455B"
      fillRule="evenodd"
    />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default PrepareToSendIcon;
