import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import TrackingFooter from 'Tracking/Footer';
import HeadScripts from 'HeadScripts';
import { setCookieClientSide } from 'lib/cookies';
import { daysFromNowToDate } from 'lib/dateUtils';
import { ChatProviderSelector } from 'Chat/ChatProviderSelector';
import styles from './index.module.scss';

const TITLE_PREFIX = 'FormSwift';

export const getFullTitle = (title) =>
  title ? `${TITLE_PREFIX}: ${title}` : TITLE_PREFIX;

const SiteWrapper = ({
  children,
  className,
  countryIsoAlpha2,
  dbxConsentCookie,
  defaultDocumentTypeFromCookie,
  documentTypeFromUrlQuery,
  loggedIn,
  title,
  trackUnbounce,
}) => {
  useEffect(() => {
    if (documentTypeFromUrlQuery) {
      setCookieClientSide('documentType', documentTypeFromUrlQuery);
    }

    if (documentTypeFromUrlQuery !== defaultDocumentTypeFromCookie) {
      setCookieClientSide('defaultDocumentType', documentTypeFromUrlQuery, {
        expires: daysFromNowToDate(30),
      });
    }
  }, [defaultDocumentTypeFromCookie, documentTypeFromUrlQuery]);

  return (
    <div className={`${styles['site-wrapper']} ${className}`}>
      <Head>
        <title>{getFullTitle(title)}</title>
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=1"
          name="viewport"
        />
        <HeadScripts
          countryIsoAlpha2={countryIsoAlpha2}
          dbxConsentCookie={dbxConsentCookie}
          loggedIn={loggedIn}
        />
      </Head>
      {loggedIn && <ChatProviderSelector />}
      {children}
      <TrackingFooter trackUnbounce={trackUnbounce} />
    </div>
  );
};

SiteWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  countryIsoAlpha2: PropTypes.string,
  dbxConsentCookie: PropTypes.string,
  defaultDocumentTypeFromCookie: PropTypes.string,
  documentTypeFromUrlQuery: PropTypes.string,
  loggedIn: PropTypes.bool,
  title: PropTypes.string,
  trackUnbounce: PropTypes.bool,
};

SiteWrapper.defaultProps = {
  children: null,
  className: '',
  countryIsoAlpha2: '',
  dbxConsentCookie: undefined,
  defaultDocumentTypeFromCookie: '',
  documentTypeFromUrlQuery: '',
  loggedIn: false,
  title: '',
  trackUnbounce: false,
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default SiteWrapper;
