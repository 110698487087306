import React from 'react';
import Image from 'Image';
import { Text } from '@dropbox/dig-components/dist/typography';
import styles from '../SubscriptionDetailsSection.module.scss';

export const ReactivationCTA = () => {
  return (
    <div className={styles['reactivation-cta-container']}>
      <div>
        <Image
          alt="profile-logo"
          height="70"
          src="/static/images/my-account/category-personal.png"
          width="70"
        />
      </div>
      <div className={styles['reactivation-cta-text']}>
        <Text isBold size="xlarge">
          We&apos;re glad to see you again!
        </Text>
        <Text size="large">
          Reactivate now to enjoy full access to FormSwift&apos;s document
          library and PDF editing
        </Text>
      </div>
    </div>
  );
};
