import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './TextField.module.scss';

const TextField = ({
  children,
  className,
  dataTestid,
  forwardedRef,
  id,
  inputClasses,
  label,
  labelClassName,
  labelId,
  ...rest
}) => {
  const wrapperClasses = classNames(styles['ds-text-input'], className);
  return (
    <div data-testid={dataTestid}>
      <div className={wrapperClasses}>
        {label && (
          <label className={labelClassName} htmlFor={id} id={labelId}>
            {label}
          </label>
        )}
        {children({
          ...rest,
          className: inputClasses,
          id,
          name: id,
          ref: forwardedRef,
        })}
      </div>
    </div>
  );
};

TextField.defaultProps = {
  autoComplete: 'off',
  className: '',
  forwardedRef: null,
  inputClasses: '',
  label: '',
  labelClassName: '',
  labelId: '',
  onChange: null,
  placeholder: '',
  value: undefined,
};

TextField.propTypes = {
  autoComplete: PropTypes.string,
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  dataTestid: PropTypes.string.isRequired,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  id: PropTypes.string.isRequired,
  inputClasses: PropTypes.string,
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  labelId: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default TextField;
