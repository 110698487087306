import React from 'react';
import { Alert } from 'DesignSystem/Notifications';
import { useRouter } from 'next/router';
import {
  statusCodeToMessage,
  normalizeMessage,
} from 'ErrorMessage/ErrorMessageUtils';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useFeature } from '@optimizely/react-sdk';
import { Banner } from '@dropbox/dig-components/banner';
import { Text } from '@dropbox/dig-components/typography';
import { AlertStatus, useAndStoreAlert } from 'StatusBanner/useAndStoreAlert';
import { ParsedUrlQuery } from 'querystring';
import styles from './StatusBanner.module.scss';

export const getBoldAlertText = (status: AlertStatus): string =>
  status === 'warning' ? 'Alert:' : capitalize(`${status}!`);
const defaultMessage = (status: AlertStatus | undefined): JSX.Element => {
  switch (status) {
    case 'error':
      return (
        <Text>
          Something went wrong. Please try again or{' '}
          <a href="/contact">contact support.</a>
        </Text>
      );
    case 'warning':
      return (
        <Text>
          Something went wrong. You may need to try again or{' '}
          <a href="/contact">contact support.</a>
        </Text>
      );
    default:
      return <></>;
  }
};
export const getNormalizedAndDecodedMessage = (
  status: AlertStatus | undefined,
  message: string | undefined,
  query: ParsedUrlQuery
): string | JSX.Element | undefined => {
  if (message) {
    const normalizedErrorMessage = normalizeMessage(message);
    const messageFromErrorCode = statusCodeToMessage[normalizedErrorMessage];
    return messageFromErrorCode
      ? messageFromErrorCode.getMessage(query)
      : defaultMessage(status);
  }
  return undefined;
};

export const StatusBanner = ({
  additionalBannerClass = '',
  additionalWrapperClass = '',
  showBoldStatus = true,
}: {
  showBoldStatus?: boolean;
  additionalWrapperClass?: string;
  additionalBannerClass?: string;
}): JSX.Element | null => {
  const router = useRouter();
  const { clearAlertData, message, status } = useAndStoreAlert();
  const { query } = router || {};
  const normalizedAndDecodedMessage = getNormalizedAndDecodedMessage(
    status,
    message,
    query
  );
  const [isDropboxDesignSystemEnabled] = useFeature('dropbox_design_system');

  if (normalizedAndDecodedMessage && status) {
    if (isDropboxDesignSystemEnabled) {
      return (
        <Banner
          onRequestClose={clearAlertData}
          type={status === 'error' ? 'alert' : status}
          withCloseButton="close"
        >
          {showBoldStatus ? (
            <Text isBold>{capitalize(`${status} `)}</Text>
          ) : undefined}
          {normalizedAndDecodedMessage}
        </Banner>
      );
    }
    return (
      <div className={classNames(styles['alert-div'], additionalWrapperClass)}>
        <Alert
          boldText={showBoldStatus ? getBoldAlertText(status) : undefined}
          className={classNames(
            styles['status-banner-alert'],
            additionalBannerClass
          )}
          closeButton
          dataTestid="status-banner-alert"
          text={normalizedAndDecodedMessage}
          type={status}
        />
      </div>
    );
  }
  return null;
};
