import React from 'react';
import PropTypes from 'prop-types';
import { CloseSmallIcon } from 'DesignSystem/Icons';
import classNames from 'classnames';
import styles from './ModalCloseButton.module.scss';

export const ModalCloseButton = ({ onClick }) => (
  <div className={styles['ds-modal-close-btn-container']}>
    <CloseSmallIcon
      className={classNames(
        styles['ds-modal-close-btn'],
        styles['cursor-pointer']
      )}
      data-testid="close-modal"
      onClick={onClick}
    />
  </div>
);

ModalCloseButton.defaultProps = {
  onClick: () => {},
};

ModalCloseButton.propTypes = {
  onClick: PropTypes.func,
};
