import React from 'react';
import { Icon } from 'DesignSystem/Icons/Icon';
import { PrimaryButton } from 'DesignSystem/Buttons';
import { useSearchMethod, useSearchTerm } from 'DesignSystem/SearchBar';
import { SearchButtonProps } from 'DesignSystem/SearchBar/types';

export const DEFAULT_BUTTON_TEXT = 'Search';
export const DEFAULT_BUTTON_VARIATION = '';

const hasIcon = (iconName?: string, iconPosition?: string): boolean =>
  !!(iconName && iconPosition?.startsWith('button-'));

const getIconPosition = (
  iconName?: string,
  iconPosition?: string
): string | undefined => {
  return hasIcon(iconName, iconPosition)
    ? iconPosition?.replace('button-', '')
    : undefined;
};

const getIcon = (
  iconName?: string,
  iconPosition?: string
): JSX.Element | null => {
  if (iconName && iconPosition) {
    return hasIcon(iconName, iconPosition) ? (
      <Icon dataTestid="search-button-icon" type={iconName} />
    ) : null;
  }
  return null;
};

const isIconOnly = (
  buttonText?: string,
  iconName?: string,
  iconPosition?: string
): boolean => !buttonText && hasIcon(iconName, iconPosition);

const getButtonText = (
  buttonText?: string,
  iconName?: string,
  iconPosition?: string
): string | undefined => {
  if (isIconOnly(buttonText, iconName, iconPosition)) {
    return undefined;
  }
  return buttonText || DEFAULT_BUTTON_TEXT;
};

export const SearchButton: React.FC<SearchButtonProps> = ({
  buttonText,
  buttonVariation,
  dataTestid = 'search-bar',
  iconName,
  iconPosition,
  showButton,
}: SearchButtonProps): JSX.Element | null => {
  const search = useSearchMethod();
  const searchTerm = useSearchTerm();
  if (typeof showButton === 'undefined' ? true : showButton) {
    return (
      <PrimaryButton
        dataTestid={dataTestid}
        icon={getIcon(iconName, iconPosition)}
        // @ts-expect-error Type 'string | undefined' is not assignable to type 'IconPosition | undefined'
        iconPosition={getIconPosition(iconName, iconPosition)}
        onClick={() => {
          if (searchTerm) {
            search?.(searchTerm);
            // Search is debounced for autocompletion, but we want to search immediately on click
            search?.flush();
          }
        }}
        options={
          isIconOnly(buttonText, iconName, iconPosition) ? ['icon-only'] : []
        }
        size="lg"
        variation={buttonVariation || DEFAULT_BUTTON_VARIATION}
      >
        {getButtonText(buttonText, iconName, iconPosition)}
      </PrimaryButton>
    );
  }
  return null;
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default SearchButton;
