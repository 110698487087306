import React, { ReactNode } from 'react';
import { DefaultLayout } from 'layouts';
import filterPropsWithPropTypes from 'layouts/filterPropsWithPropTypes';
import DefaultLayoutProps from 'layouts/DefaultLayout/DefaultLayoutProps';
import { OfferBanner } from '../Banners/OfferBanner';

export interface TaxLayoutProps {
  children?: ReactNode;
  hideAccount?: boolean;
  loggedIn?: boolean;
  title?: string;
  trackUnbounce?: boolean;
}

export const TaxLayout = ({
  children = null,
  ...rest
}: TaxLayoutProps): JSX.Element => {
  return (
    <DefaultLayout {...rest}>
      <OfferBanner trackingActionPage="Visual Static Builder" />
      <main>{children}</main>
    </DefaultLayout>
  );
};

TaxLayout.getLayoutProps = (props: Partial<Record<string, unknown>>) =>
  filterPropsWithPropTypes(props, DefaultLayoutProps);
