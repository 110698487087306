import { useRouter } from 'next/router';

interface UseBuilderTypeResult {
  isFreewrite?: boolean;
  isStatic?: boolean;
  isVisualStatic?: boolean;
}

export const useBuilderType = (): UseBuilderTypeResult => {
  const router = useRouter();
  const { pathname } = router || { pathname: '' };

  const freewriteRegex = /\/builder\/freewrite.*|\/builder\/shared.*/;
  const isFreewrite = !!freewriteRegex.exec(pathname);
  const visualStaticRegex = /\/builder\/tax.*/;
  const isVisualStatic = !!visualStaticRegex.exec(pathname);
  const staticRegex = /\/builder\/static.*/;
  const isStatic = !!staticRegex.exec(pathname);

  return {
    isFreewrite,
    isStatic,
    isVisualStatic,
  };
};
