import { useQuery } from '@apollo/client';
import { userData } from 'layouts/gql/types/userData';
import { USER_DATA } from 'layouts/gql/userData';
import React from 'react';
import FreemiumBanner from '../FreemiumBanner';
import { ReactivationBanner } from '../ReactivationBanner';

export interface OfferBannerProps {
  trackingActionPage: string;
}

export const OfferBanner = ({
  trackingActionPage,
}: OfferBannerProps): JSX.Element | null => {
  const { data } = useQuery<userData>(USER_DATA);

  const user = data?.user;
  const credits = user?.credits;
  const isFreemium = user?.isFreemium;
  const hasPaidAccess = user?.hasPaidAccess;
  const isCancelled = user?.isCancelled;

  // Given how Freemium is defined (presence of UserPromoCode)
  // It is possible to be both freemium and cancelled with paid access expired
  // In these cases we default to the reactivation banner
  const isReactivationBannerVisible = !hasPaidAccess && isCancelled;
  const isFreemiumBannerVisible =
    isFreemium && !hasPaidAccess && !credits && !isReactivationBannerVisible;

  return (
    <>
      {isReactivationBannerVisible && (
        <ReactivationBanner trackingActionPage={trackingActionPage} />
      )}
      {isFreemiumBannerVisible && <FreemiumBanner page={trackingActionPage} />}
    </>
  );
};
