import FormSwiftAPIClient from 'FormSwiftAPIClient';
import { ChatData, GetChatInitDataResponse } from 'lib/chat/types';
import getConfig from 'next/config';

interface ApiResponse<T> {
  data: T;
}

const { publicRuntimeConfig } = getConfig();

const apiClient = new FormSwiftAPIClient(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  publicRuntimeConfig.API_URL,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  publicRuntimeConfig.INTERNAL_API_URL
);

export const getChatInitData = async (
  userId: number,
  userToken: string
): Promise<ChatData> => {
  const response = (await apiClient.request('POST', '/chat/init_data', {
    user_id: userId,
    user_token: userToken,
  })) as ApiResponse<GetChatInitDataResponse>;

  return response.data.chat_data;
};
