import { useGetUserSubscriptionDetailsQuery } from 'lib/graphql/MyAccount/__generated__/MyAccountQueries.generated';

export type UseFormatPriceProps = {
  price: number | string;
  omitDecimalIfWholeNumber?: boolean;
};

export const useFormatUserSubscriptionPrice = ({
  omitDecimalIfWholeNumber = false,
  price,
}: UseFormatPriceProps): string => {
  const { data } = useGetUserSubscriptionDetailsQuery();
  const currency = data?.user?.subscription?.currency ?? 'USD';

  const priceVal = typeof price === 'string' ? parseFloat(price) : price;
  // Set decimalDigits explicitly to 0 or 2 so e.g. $19.90 doesn't become $19.9
  const decimalDigits =
    omitDecimalIfWholeNumber && priceVal === Math.floor(priceVal) ? 0 : 2;
  const minimumFractionDigits = decimalDigits;
  const maximumFractionDigits = decimalDigits;

  let formattedPrice = price;

  formattedPrice = new Intl.NumberFormat(
    currency === 'USD' ? 'en-US' : undefined,
    {
      currency,
      maximumFractionDigits,
      minimumFractionDigits,
      style: 'currency',
    }
  ).format(priceVal);
  return formattedPrice;
};
