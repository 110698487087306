import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const HelpIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 Z M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M7.123,10.306 L8.85,10.306 L8.85,12 L7.123,12 L7.123,10.306 Z M5.352,6.676 C5.35933337,6.27999802 5.42716602,5.91700165 5.5555,5.587 C5.68383397,5.25699835 5.86349884,4.97100121 6.0945,4.729 C6.32550115,4.48699879 6.60416503,4.29816734 6.9305,4.1625 C7.25683496,4.02683265 7.62166465,3.959 8.025,3.959 C8.54566927,3.959 8.98016492,4.03049928 9.3285,4.1735 C9.67683507,4.31650071 9.95733227,4.49433227 10.17,4.707 C10.3826677,4.91966773 10.5348329,5.1488321 10.6265,5.3945 C10.7181671,5.64016789 10.764,5.86933227 10.764,6.082 C10.764,6.43400176 10.7181671,6.72366553 10.6265,6.951 C10.5348329,7.17833447 10.4211673,7.37266586 10.2855,7.534 C10.1498327,7.69533414 9.99950082,7.83283276 9.8345,7.9465 C9.66949917,8.06016723 9.5136674,8.17383276 9.367,8.2875 C9.2203326,8.40116723 9.09016723,8.5313326 8.9765,8.678 C8.86283276,8.8246674 8.79133348,9.0079989 8.762,9.228 L8.762,9.646 L7.277,9.646 L7.277,9.151 C7.29900011,8.83566509 7.3594995,8.57166773 7.4585,8.359 C7.55750049,8.14633227 7.67299934,7.96483408 7.805,7.8145 C7.93700066,7.66416591 8.0763326,7.53400055 8.223,7.424 C8.3696674,7.31399945 8.50533271,7.20400055 8.63,7.094 C8.75466729,6.98399945 8.85549961,6.86300066 8.9325,6.731 C9.00950038,6.59899934 9.04433337,6.43400099 9.037,6.236 C9.037,5.89866498 8.95450082,5.64933414 8.7895,5.488 C8.62449917,5.32666586 8.3953348,5.246 8.102,5.246 C7.90399901,5.246 7.73350071,5.28449961 7.5905,5.3615 C7.44749928,5.43850038 7.33016712,5.54116602 7.2385,5.6695 C7.14683287,5.79783397 7.07900022,5.9481658 7.035,6.1205 C6.99099978,6.29283419 6.969,6.47799901 6.969,6.676 L5.352,6.676 Z"
        fillRule="nonzero"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default HelpIcon;
