import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { animated, useTransition } from '@react-spring/web';
import { SimpleTransition } from '../SimpleTransition';
import styles from './BaseTransition.module.scss';

const BaseTransition = ({
  alternate,
  className,
  isAlternateMode,
  normal,
  transitions,
}) => {
  const classes = classNames(className, styles['transition-container']);
  return (
    <div className={classes}>
      {useTransition(
        isAlternateMode,
        transitions
      )((props, item) =>
        item ? (
          <animated.div key="alternate" style={props}>
            {alternate}
          </animated.div>
        ) : (
          <animated.div key="normal" style={props}>
            {normal}
          </animated.div>
        )
      )}
    </div>
  );
};

BaseTransition.animate = true;

BaseTransition.propTypes = {
  alternate: PropTypes.node.isRequired,
  className: PropTypes.string,
  normal: PropTypes.node.isRequired,
  isAlternateMode: PropTypes.bool.isRequired,
  transitions: PropTypes.shape({
    enter: PropTypes.object.isRequired,
    from: PropTypes.object,
    initial: PropTypes.object,
    leave: PropTypes.object.isRequired,
  }).isRequired,
};

BaseTransition.defaultProps = {
  className: '',
};

export const TransitionSelector = (props) => {
  // Retain the animate flag locally, based on its value when the component mounts.
  // This prevents some problems that can arise during testing with disabled animation.
  const { current: animate } = useRef(BaseTransition.animate);
  return animate ? (
    <BaseTransition {...props} />
  ) : (
    <SimpleTransition {...props} />
  );
};

export const enableAnimation = () => {
  BaseTransition.animate = true;
};
export const disableAnimation = () => {
  BaseTransition.animate = false;
};
