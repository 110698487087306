import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Header from 'Header';
import filterPropsWithPropTypes from 'layouts/filterPropsWithPropTypes';
import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { ChevronUpLine, ChevronDownLine } from '@dropbox/dig-icons/assets';
import { CaretIcon } from 'DesignSystem/Icons';
import { SecondaryButton } from 'DesignSystem/Buttons';
import { useFeature } from '@optimizely/react-sdk';
import { RedesignedFooter } from 'Footer';
import { useWindowSize } from 'hooks';
import FreeWriteLayoutProps from './FreeWriteLayoutProps';
import SiteWrapper from '../SiteWrapper';
import FreeWriteFooter from './FreeWriteFooter';
import styles from './index.module.scss';
import { OfferBanner } from '../Banners/OfferBanner';

const FreeWriteLayout = ({
  children,
  countryIsoAlpha2,
  dbxConsentCookie,
  hideAccount,
  loggedIn,
  title,
  trackUnbounce,
}) => {
  const [showFreeWriteFooter, setShowFreeWriteFooter] = useState(false);
  const [isDropboxDesignSystemEnabled] = useFeature('dropbox_design_system');
  const toggleFreeWriteFooter = () =>
    setShowFreeWriteFooter((prevValue) => !prevValue);
  const { isMobile } = useWindowSize();

  // Scroll to the bottom of the page when opening the footer
  useEffect(() => {
    if (showFreeWriteFooter) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [showFreeWriteFooter]);

  return (
    <SiteWrapper
      {...{
        loggedIn,
        title,
        trackUnbounce,
      }}
      className={styles.wrapper}
      countryIsoAlpha2={countryIsoAlpha2}
      dbxConsentCookie={dbxConsentCookie}
    >
      <Header hideAccount={hideAccount} />
      <OfferBanner trackingActionPage="Freewrite Builder" />
      <main className={styles.main}>{children}</main>
      {isDropboxDesignSystemEnabled ? (
        <IconButton
          alt="Toggle footer"
          className={classNames(styles['footer-toggle'], styles.dropbox)}
          data-testid="toggle-footer"
          inverse={showFreeWriteFooter}
          onClick={toggleFreeWriteFooter}
          variant={isMobile ? 'filled' : 'borderless'}
        >
          {showFreeWriteFooter ? (
            <UIIcon aria-label="toggle footer" src={ChevronDownLine} />
          ) : (
            <UIIcon aria-label="toggle footer" src={ChevronUpLine} />
          )}
        </IconButton>
      ) : (
        <SecondaryButton
          alt="Toggle footer"
          className={styles['footer-toggle']}
          dataTestid="toggle-footer"
          id="toggle-footer"
          onClick={toggleFreeWriteFooter}
          options={['square-padding']}
          size="sm"
          variation="dark"
        >
          <CaretIcon
            transform={`scale(1) ${
              showFreeWriteFooter ? '' : 'rotate(180deg)'
            }`}
          />
        </SecondaryButton>
      )}
      {showFreeWriteFooter && !isDropboxDesignSystemEnabled ? (
        <FreeWriteFooter />
      ) : null}
      {showFreeWriteFooter && isDropboxDesignSystemEnabled ? (
        <RedesignedFooter />
      ) : null}
    </SiteWrapper>
  );
};

FreeWriteLayout.getLayoutProps = (props) =>
  filterPropsWithPropTypes(props, FreeWriteLayoutProps);
FreeWriteLayout.propTypes = FreeWriteLayoutProps;

FreeWriteLayout.defaultProps = {
  children: null,
  dbxConsentCookie: '',
  hideAccount: false,
  loggedIn: false,
  title: '',
  trackUnbounce: false,
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FreeWriteLayout;
