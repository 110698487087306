import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TypographyTypes from './TypographyTypes';
import styles from './withTypography.module.scss';

export const NullElement = React.memo(() => null);

export const withTypography = (WrappedComponent, typographyProps = {}) => {
  // Check that the component is just a regular html element and not a more complex component.
  if (TypographyTypes.indexOf(WrappedComponent) === -1) {
    NullElement.displayName = 'NullElement';
    return NullElement;
  }

  class WrappedComponentWithTypography extends React.PureComponent {
    render() {
      const {
        bold,
        className,
        dataTestid,
        italic,
        variation,
        ...rest
      } = this.props;

      const variationType = styles[`${typographyProps.className}-${variation}`];

      const classes = classnames(styles[typographyProps.className], className, {
        [variationType]: variation,
        [styles['ds-font-bold']]: bold,
        [styles['ds-font-italic']]: italic,
      });

      return (
        <WrappedComponent
          className={classes}
          data-testid={dataTestid || ''}
          {...rest}
        />
      );
    }
  }

  WrappedComponentWithTypography.defaultProps = {
    bold: false,
    children: null,
    className: '',
    dataTestid: '',
    italic: false,
    variation: '',
  };
  WrappedComponentWithTypography.propTypes = {
    bold: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.any]),
    className: PropTypes.string,
    dataTestid: PropTypes.string,
    italic: PropTypes.bool,
    variation: PropTypes.string,
  };

  return WrappedComponentWithTypography;
};
