import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const DuplicateIcon = ({ ...props }: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M2,2 L2,12 L0,12 L0,2 L0,0 L12,0 L12,2 L2,2 Z M14,6 L6,6 L6,14 L14,14 L14,6 Z M16,4 L16,16 L4,16 L4,4 L16,4 Z"
        fillRule="nonzero"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default DuplicateIcon;
