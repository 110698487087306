import React from 'react';
import { ReactivationModalRedesign } from 'Modals/ReactivationModalRedesign';
import { UpdatingSubscriptionLoadingModal } from 'Modals/UpdatingSubscriptionLoadingModal';

export const GlobalModals = (): JSX.Element => {
  return (
    <>
      <ReactivationModalRedesign />
      <UpdatingSubscriptionLoadingModal />
    </>
  );
};
