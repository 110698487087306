import PropTypes from 'prop-types';
import { withTypography } from './withTypography';

export const HeadingA = withTypography('h3', { className: 'ds-heading-a' });

HeadingA.defaultProps = {
  className: '',
};

// Also accepts all <h3 /> attributes
HeadingA.propTypes = {
  className: PropTypes.string,
};
