import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const CaretIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g>
      <path
        clipRule="evenodd"
        d="M1.70711 6.48903C1.31658 6.0985 1.31658 5.46534 1.70711 5.07481C2.09763 4.68429 2.7308 4.68429 3.12132 5.07481L8.07107 10.0246L13.0208 5.07481C13.4113 4.68429 14.0445 4.68429 14.435 5.07481C14.8256 5.46534 14.8256 6.0985 14.435 6.48903L9.48528 11.4388L8.77817 12.1459C8.38765 12.5364 7.75449 12.5364 7.36396 12.1459L6.65685 11.4388L1.70711 6.48903Z"
        fill="#37455B"
        fillRule="evenodd"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default CaretIcon;
