import React from 'react';
import { RootState } from 'store/RootState';
import { useSelector } from 'react-redux';
import { MEMBERS_AREA_TABS } from 'layouts/MembersAreaLayout/MembersAreaLayoutTabs';
import { RotatingNotificationBanner } from 'layouts/Banners/RotatingNotificationBanner';
import { MembersAreaReactivationBanner } from 'layouts/MembersAreaLayout/MembersAreaBanners/MembersAreaReactivationBanner';
import { MembersAreaReactivationBannerRedesign } from 'layouts/MembersAreaLayout/MembersAreaBanners/MembersAreaReactivationBannerRedesign';
import { useIsAutocreateEligible } from 'MyDocuments/AutocreateModal';
import { AutocreateBanner } from 'layouts/Banners/AutocreateBanner';
import { useDecision } from '@optimizely/react-sdk';

export interface MembersAreaBannersProps {
  activeKey: keyof typeof MEMBERS_AREA_TABS;
}

const AutocreateOrRotatingBanner = (): JSX.Element | null => {
  const {
    eligibleDocumentNames,
    isAutocreateEligible,
  } = useIsAutocreateEligible();

  return isAutocreateEligible ? (
    <AutocreateBanner eligibleDocumentNames={eligibleDocumentNames} />
  ) : (
    <RotatingNotificationBanner />
  );
};

const MembersAreaBanners = ({
  activeKey,
}: MembersAreaBannersProps): JSX.Element => {
  const [{ variationKey }] = useDecision('reactivation_redesign');
  const isCancelled = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, regex/invalid
    ({ UserHandler }: RootState) => UserHandler.userInfo.isCancelled
  );
  const page = MEMBERS_AREA_TABS[activeKey]?.title;

  const ReactivationBanner = () => {
    return (
      <>
        {variationKey === 'on' ? (
          <MembersAreaReactivationBannerRedesign />
        ) : (
          <MembersAreaReactivationBanner page={page} />
        )}
      </>
    );
  };

  return (
    <>
      {isCancelled && <ReactivationBanner />}
      {activeKey === 'my-documents' && <AutocreateOrRotatingBanner />}
    </>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default MembersAreaBanners;
