import React, { useEffect } from 'react';
import { Banner } from '@dropbox/dig-components/banner';
import { Text } from '@dropbox/dig-components/typography';
import { Button } from '@dropbox/dig-components/buttons';
import { ThemeProvider } from '@dropbox/dig-foundations';
import useTrack from 'lib/tracking/useTrack';
import { useRouter } from 'next/router';
import { hideBannerFromUser } from '../Utils';

export const MultipleSeatsInviteTeammateBanner = () => {
  const track = useTrack();
  const router = useRouter();

  const handleCloseClick = () => {
    hideBannerFromUser('multipleSeatsBanner');
    void router.push(router, undefined, { shallow: true });
  };

  const onClickInviteMembers = () => {
    void track({
      action: 'My Documents Banner | Multiple Seats',
      category: 'Invite Members',
    });
    void router.push('/my-account?invite_teammate=1');
  };

  useEffect(() => {
    void track({
      action: 'My Documents Banner | Multiple Seats',
      category: 'Shown',
    });
  }, [track]);

  return (
    <ThemeProvider
      _dangerouslyIncludeAdditionalClassName="dig-theme--FormSwift"
      mode="bright"
    >
      {({ getThemeProps }) => (
        <div {...getThemeProps()}>
          <Banner
            onRequestClose={handleCloseClick}
            style={{ display: 'flex', justifyContent: 'center' }}
            withCloseButton="Close Banner"
          >
            <Banner.Message>
              <Text isBold>NEW!</Text> Give up to two members their own account
              and keep your documents private—for free!
            </Banner.Message>
            <Banner.Actions>
              <Button
                data-testid="multiple-seats-banner-invite-members"
                onClick={onClickInviteMembers}
                size="small"
                variant="primary"
              >
                Invite Members
              </Button>
            </Banner.Actions>
          </Banner>
        </div>
      )}
    </ThemeProvider>
  );
};
