import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const CreateIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M10.8511377,3.86249215 L12.3215117,5.62694096 L13.0856155,4.92328155 C13.1845726,4.8075729 13.1927067,4.61303512 13.1152701,4.51012618 L11.9377255,3.05370377 C11.8800728,2.98784664 11.81057,2.98326013 11.7637216,3.02474616 L10.8511377,3.86249215 Z M9.38806572,5.23090571 L2.60207858,11.4886487 L2.32803848,13.3165595 L3.9754556,13.2891532 L10.8564884,6.99301287 L9.38806572,5.23090571 Z M14.5085948,6.32959547 L14.4684955,6.36865252 L13.3660492,7.38057611 L13.1157992,7.64560754 L12.999637,7.76253528 L12.699012,8.02756671 L12.4121509,8.28023571 L4.76646805,15.2762707 L-4.22772928e-13,15.3555654 L0.728584965,10.495735 L0.987417049,10.2570513 L8.3701526,3.44935304 L8.67077762,3.18432161 L8.90561541,2.98286742 L8.95599042,2.92951693 L9.25588048,2.61545853 L10.4215469,1.542089 C11.3134604,0.748509422 12.6553187,0.837059285 13.4675198,1.76598186 L14.689457,3.27686127 C15.3848556,4.1939035 15.3088348,5.50526766 14.5085948,6.32959547 Z"
        fillRule="nonzero"
      />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default CreateIcon;
