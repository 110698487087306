import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const SignIcon = ({ ...props }: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <path
      clipRule="evenodd"
      d="M1.89443 11.4472C2.94331 9.34944 4.30304 6.90193 5.473 5.03C5.74805 4.58991 6.00955 4.18632 6.2543 3.8243C6.13824 4.31568 5.98712 4.92839 5.77986 5.75746C5.30514 7.65634 5.11817 8.44636 4.96952 9.25717C4.86836 9.80891 4.81597 10.2391 4.81838 10.5775C4.82403 11.3674 5.07916 12 6 12C6.98071 12 7.31979 11.5727 9.57264 8.313C10.0501 7.62221 10.3706 7.16335 10.6299 6.80591C10.5973 7.03008 10.5583 7.28862 10.5116 7.59264C10.3182 8.85208 10.2485 9.3751 10.2201 9.93151C10.1541 11.2205 10.3422 12 11.5 12C12.6531 12 13.9184 10.7348 15.8137 8.08124C16.1347 7.63182 16.0306 7.00728 15.5812 6.68627C15.1318 6.36526 14.5073 6.46935 14.1863 6.91876C13.4399 7.96365 12.8036 8.75849 12.2859 9.29747C12.328 8.95937 12.3918 8.52513 12.4884 7.89625C12.6818 6.63681 12.7515 6.11379 12.7799 5.55738C12.8459 4.26841 12.6578 3.48889 11.5 3.48889C10.5193 3.48889 10.1802 3.9162 7.92735 7.17589C7.6584 7.56505 7.43923 7.8806 7.25275 8.14587C7.36787 7.65956 7.51704 7.05492 7.72014 6.24254C8.19486 4.34366 8.38183 3.55364 8.53048 2.74283C8.63163 2.19109 8.68403 1.76087 8.68161 1.42254C8.67597 0.63257 8.42083 0 7.5 0C5.97749 0 2.99986 4.76421 0.105571 10.5528C-0.141419 11.0468 0.0588057 11.6474 0.552784 11.8944C1.04676 12.1414 1.64744 11.9412 1.89443 11.4472ZM2 15C2 15.5523 1.55228 16 0.999998 16C0.447713 16 -2.19379e-06 15.5523 -2.19379e-06 15C-2.19379e-06 14.4477 0.447713 14 0.999998 14C1.55228 14 2 14.4477 2 15ZM4.5 16C5.05228 16 5.5 15.5523 5.5 15C5.5 14.4477 5.05228 14 4.5 14C3.94771 14 3.5 14.4477 3.5 15C3.5 15.5523 3.94771 16 4.5 16ZM9 15C9 15.5523 8.55228 16 8 16C7.44771 16 7 15.5523 7 15C7 14.4477 7.44771 14 8 14C8.55228 14 9 14.4477 9 15ZM12.5 15C12.5 15.5523 12.0523 16 11.5 16C10.9477 16 10.5 15.5523 10.5 15C10.5 14.4477 10.9477 14 11.5 14C12.0523 14 12.5 14.4477 12.5 15ZM15 16C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14C14.4477 14 14 14.4477 14 15C14 15.5523 14.4477 16 15 16Z"
      fillRule="evenodd"
    />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default SignIcon;
