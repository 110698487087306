import React from 'react';
import PropTypes from 'prop-types';
import styles from './ModalActions.module.scss';

/**
 * A simple flexbox wrapper intended to render buttons within a modal.
 */
export const ModalActions = ({ children }) => (
  <div className={styles['ds-modal-cta-row']}>{children}</div>
);

ModalActions.propTypes = {
  children: PropTypes.node.isRequired,
};
