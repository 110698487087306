import { gql } from '@apollo/client';

export const USER_DATA = gql`
  query userData {
    user {
      id
      credits
      email
      isCancelled
      isFreemium
      hasPaidAccess
      userId
    }
  }
`;
