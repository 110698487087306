import React from 'react';
import { SearchProps } from '../types';
import { SearchButton } from './SearchButton';
import { SearchField } from './SearchField';
import styles from './index.module.scss';

/**
 * @deprecated Please see components/DocumentLibrary/index.tsx for an example of
 * how to implement search with SearchInput
 */
export const SearchBar = ({ children, ...props }: SearchProps): JSX.Element => {
  return (
    <div className={styles['search-bar-wrapper']}>
      <div className={styles['search-bar-input']}>
        <SearchField {...props} />
        <SearchButton {...props} />
      </div>
      {children}
    </div>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default SearchBar;
