import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

export const RedactIcon = ({ ...props }: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <path
      clipRule="evenodd"
      d="M3 2V14H13V2H3ZM2 0C1.44772 0 1 0.447715 1 1V15C1 15.5523 1.44772 16 2 16H14C14.5523 16 15 15.5523 15 15V1C15 0.447715 14.5523 0 14 0H2Z"
      fillRule="evenodd"
    />
    <path d="M4 3.5H12V5.5H4V3.5Z" />
    <path d="M4 7.5H8V9.5H4V7.5Z" />
    <path d="M4 8H12V9H4V8Z" />
    <path d="M4 11.5H12V12.5H4V11.5Z" />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default RedactIcon;
