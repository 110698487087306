import { KeyboardEvent } from 'react';
import {
  ISearchContext,
  SetFocusDown,
  SetSearchTerm,
  StatusType,
} from 'DesignSystem/SearchBar/types';

const createKeyDownHandler = (
  fieldValue: string | undefined,
  setFocusDown: SetFocusDown,
  setSearchTerm?: SetSearchTerm,
  search?: ISearchContext<unknown>['search'],
  searchStatus?: StatusType
) => (e: KeyboardEvent): void => {
  if (e.key === 'Enter' && fieldValue) {
    search?.(fieldValue);
    // `search` is debounced to improve performance when automatically searching,
    // when editing the search input, but on enter we want to update and search immediately.
    search?.flush();
  } else if (e.key === 'ArrowDown' && searchStatus === 'complete') {
    setFocusDown();
  }
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default createKeyDownHandler;
