import React from 'react';
import styles from './index.module.scss';

const FreemiumMenuHeaderSubText = ({
  planId,
}: {
  planId: string;
}): JSX.Element => {
  let headerSubText = 'free';

  if (planId && planId.includes('monthly')) {
    headerSubText = 'monthly';
  } else if (planId && planId.includes('annual')) {
    headerSubText = 'annual';
  }

  return (
    <p className={styles['freemium-menu-header-sub-text']}>
      {`${headerSubText} plan`}
    </p>
  );
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FreemiumMenuHeaderSubText;
