import React from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

/**
 * Images that are hosted on the next app itself must use this component instead of img tags.
 * This component prefixes assets with the ASSET_PREFIX environmental variable so assets are
 * loaded from the right URL (next.formswift.com vs www.formswift.com)
 */
const Image = (props) => {
  const { alt, src, ...imgProps } = props;
  return (
    <img
      alt={alt}
      src={`${publicRuntimeConfig.ASSET_PREFIX}${src}`}
      {...imgProps}
    />
  );
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
};

Image.defaultProps = {
  className: '',
  height: undefined,
  width: undefined,
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default Image;
