import { setCookieClientSide } from 'lib/cookies';
import isServerSide from 'Utils/isServerSide';
import Cookies from 'js-cookie';

const getHiddenBanners = (): Array<string> => {
  const hiddenBannersCookie = Cookies.get('hiddenBanners');
  if (hiddenBannersCookie === undefined) {
    return [];
  }
  let hiddenBanners: string[] = [];
  try {
    hiddenBanners = JSON.parse(hiddenBannersCookie);
    // eslint-disable-next-line no-empty
  } catch {}
  if (!Array.isArray(hiddenBanners)) {
    return [];
  }
  return hiddenBanners;
};

export const hideBannerFromUser = (banner: string): void => {
  const hiddenBannersSet = new Set(getHiddenBanners());
  hiddenBannersSet.add(banner);
  setCookieClientSide('hiddenBanners', JSON.stringify([...hiddenBannersSet]));
};

export const checkBannerHidden = (banner: string): boolean => {
  if (isServerSide()) {
    return true;
  }
  const hiddenBanners = getHiddenBanners();
  return hiddenBanners?.indexOf(banner) !== -1;
};
