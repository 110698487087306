import classNames from 'classnames';
import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';
import styles from './CircleIcon.module.scss';

const CircleIcon = ({ className, ...props }: BaseIconProps): JSX.Element => (
  <BaseIcon {...props} className={classNames(styles['circle-icon'], className)}>
    <circle cx="8" cy="8" r="7" strokeWidth="2" />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default CircleIcon;
