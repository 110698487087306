import React from 'react';
import { PrimaryButton } from './PrimaryButton';

type DestructiveButtonProps = Omit<
  React.ComponentProps<typeof PrimaryButton>,
  'variation'
>;

export const DestructiveButton = (
  props: DestructiveButtonProps
): JSX.Element => <PrimaryButton variation="destructive" {...props} />;
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default DestructiveButton;
