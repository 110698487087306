import { gql } from '@apollo/client';

const FreemiumMenuDataFragment = gql`
  fragment FreemiumMenuDataFragment on User {
    credits
    isCurrentSubscriber
    planId
  }
`;

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FreemiumMenuDataFragment;
