import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const HighlightIcon = ({ ...props }: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <path
      clipRule="evenodd"
      d="M2 0C2.55228 0 3 0.447715 3 1L3 4.16667L13 4.16667L13 1C13 0.447715 13.4477 0 14 0C14.5523 0 15 0.447715 15 1L15 5.5504L14.7433 5.83563C13.4279 7.29714 12.75 9.54979 12.75 11V12L3.25 12L3.25 11C3.25 10.1561 3.24498 9.35085 3.00684 8.51297C2.77618 7.70136 2.31214 6.81853 1.32059 5.90043L1 5.60358L1 1C1 0.447715 1.44772 0 2 0ZM12.0824 6.16667L4.13271 6.16667C4.50756 6.76905 4.76175 7.37189 4.93066 7.9662C5.13564 8.68746 5.20908 9.38016 5.23537 10H10.818C10.9726 8.80142 11.3916 7.41912 12.0824 6.16667Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M7 12V13.8388L9 13.5388V12H11V13.5388C11 14.5288 10.2757 15.3698 9.29668 15.5167L7.29668 15.8167C6.08747 15.9981 5 15.0616 5 13.8388L5 12H7Z"
      fillRule="evenodd"
    />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default HighlightIcon;
