/* eslint-disable no-underscore-dangle */
import Cookies from 'js-cookie';

/**
 * Executes the logout callback for the unified chat client.
 * This function performs the following actions:
 * 1. Checks if the chat client exists and has a logout handler function.
 * 2. Removes the 'unified_chat_init' cookie to clear the chat session state.
 * 3. Invokes the chat client's logout callback to complete the logout process.
 *
 * This ensures that the user is fully logged out from the chat service.
 * see: https://www.dropbox.com/scl/fi/668sjw70052rd8nldbhtc/Design-Doc-Unified-Chat-on-CX-Formswift-Live-Agent-Chat.paper?rlkey=lfbtp4edszl725sa65lznglts&dl=0
 */
export const executeUnifiedChatLogoutCallback = (): void => {
  if (
    window._DBX_ZOOM_CHAT_CLIENT &&
    typeof window._DBX_ZOOM_CHAT_CLIENT.handleOnLogoutCallback === 'function'
  ) {
    Cookies.remove('unified_chat_init');
    window._DBX_ZOOM_CHAT_CLIENT.handleOnLogoutCallback();
  }
};
/* eslint-enable no-underscore-dangle */
