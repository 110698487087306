import getSingleQueryParam from 'lib/getSingleQueryParam';
import { useRouter } from 'next/router';
import { useState, useEffect, useMemo } from 'react';

export type AlertStatus = 'success' | 'warning' | 'error';
export type AlertData = {
  status?: AlertStatus;
  message?: string;
};

export const getStatusString = (
  success: string | string[] | undefined,
  error: string | string[] | undefined,
  warning: string | string[] | undefined
): AlertStatus | undefined => {
  if (success) {
    return 'success';
  }
  if (error) {
    return 'error';
  }
  if (warning) {
    return 'warning';
  }
  return undefined;
};

/**
 * Custom hook that retrieves and removes AlertData from the URL and stores it
 * in state. Returns this state and a function to clear it.
 *
 * @returns {AlertData & { clearAlertData: () => void }} An object containing
 * the alert data and a function to clear the alert data.
 */
export const useAndStoreAlert = (): AlertData & {
  clearAlertData: () => void;
} => {
  const router = useRouter();
  const { query } = router || {};
  const { error, success, warning } = query || {};
  const [alertData, setAlertData] = useState<AlertData>({
    message: undefined,
    status: undefined,
  });
  const queryAlertData: AlertData = useMemo(
    () => ({
      message: getSingleQueryParam(success || error || warning),
      status: getStatusString(success, error, warning),
    }),
    [success, error, warning]
  );

  useEffect(() => {
    /* null check router.replace in case we forget to mock it in a test */
    if (queryAlertData.message && queryAlertData.status && router.replace) {
      setAlertData(queryAlertData);
      delete query.success;
      delete query.error;
      delete query.warning;
      void router.replace({ query }, undefined, {
        shallow: true,
      });
    }
  }, [router, query, queryAlertData]);

  return {
    clearAlertData: () => setAlertData({}),
    message: alertData.message || queryAlertData.message,
    status: alertData.status || queryAlertData.status,
  };
};
