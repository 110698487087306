import React from 'react';
import { TertiaryButton } from './TertiaryButton';

type WhiteTertiaryButtonProps = Omit<
  React.ComponentProps<typeof TertiaryButton>,
  'variation'
>;

export const WhiteTertiaryButton = (
  props: WhiteTertiaryButtonProps
): JSX.Element => <TertiaryButton variation="white" {...props} />;
