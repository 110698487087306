import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const DrawIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      id="clock-icon"
      stroke="none"
      strokeWidth="1"
    >
      <path
        clipRule="evenodd"
        d="M10.9595 0.405734C11.5495 -0.1461 12.4768 -0.133493 13.0513 0.434175L15.3537 2.7096C15.9395 3.28849 15.9395 4.22708 15.3537 4.80598L7.51144 12.5561L1.66704 14H15.1111C15.602 14 16 14.4477 16 15C16 15.5523 15.602 16 15.1111 16H0.888889C0.397969 16 0 15.5523 0 15C0 14.4477 0.397969 14 0.888889 14H1.66704L3.09178 7.76444L8.97799 2.25905C8.35216 1.9203 7.55327 2.01487 7.02592 2.53602L4.95019 4.58735C4.55966 4.97328 3.9265 4.97328 3.53597 4.58735C3.14545 4.20141 3.14545 3.57569 3.53597 3.18976L5.61171 1.13843C6.93402 -0.168334 9.02673 -0.264888 10.4627 0.87042L10.9595 0.405734ZM11.981 2.17167L4.9086 8.7865L4.33338 11.3039L6.48894 10.7714L13.586 3.75779L11.981 2.17167Z"
        fillRule="evenodd"
      />
      ;
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default DrawIcon;
