import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

export const EyeHideIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <path
      clipRule="evenodd"
      d="M15 2.4142L13.5858 1L11.7219 2.86389C10.5211 2.28798 9.21926 2.00003 8 2.00003C4.78671 2.00003 1 4.00003 0 8.00003C0.427794 9.7112 1.36557 11.0564 2.55028 12.0355L0.857863 13.7279L2.27208 15.1421L2.27209 15.1421L2.27208 15.1421L15 2.4142ZM14.8194 5.42964C15.3462 6.1674 15.756 7.0242 16 8.00003C15 12 11.2133 14 8 14C7.47916 14 6.94326 13.9475 6.4066 13.8424L8.2571 11.9919C10.2614 11.8648 11.8647 10.2615 11.9919 8.25713L14.8194 5.42964ZM10.0319 4.55384L8.51802 6.06777C8.3528 6.02358 8.17915 6.00003 8 6.00003C6.89543 6.00003 6 6.89546 6 8.00003C6 8.17918 6.02355 8.35283 6.06774 8.51805L4.55381 10.032C4.20193 9.43647 4 8.74183 4 8.00003C4 5.79089 5.79086 4.00003 8 4.00003C8.7418 4.00003 9.43644 4.20195 10.0319 4.55384Z"
      fillRule="evenodd"
    />
  </BaseIcon>
);
