import React from 'react';
import classnames from 'classnames';
import { BaseButton, BaseButtonProps } from './BaseButton';
import buttonStyles from './BaseButton/index.module.scss';
import { ButtonSize } from './types';

type SecondaryButtonVariation = '' | 'dark' | 'alternative' | 'destructive';

interface SecondaryButtonProps
  extends Omit<BaseButtonProps, 'buttonType' | 'size'> {
  className?: string;
  size?: ButtonSize;
  variation?: SecondaryButtonVariation;
}

export const SecondaryButton = ({
  className = '',
  size = 'md',
  variation = '',
  ...buttonProps
}: SecondaryButtonProps): JSX.Element => {
  const classes = classnames(className, buttonStyles[variation]);
  return (
    <BaseButton
      buttonType="secondary"
      className={classes}
      size={size}
      {...buttonProps}
    />
  );
};
