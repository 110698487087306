import { gql } from '@apollo/client';

const PlanInfoFragment = gql`
  fragment PlanInfoFragment on User {
    plan {
      planDescription
    }
    planId
  }
`;

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default PlanInfoFragment;
