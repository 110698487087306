import PropTypes from 'prop-types';
import { withTypography } from './withTypography';

export const HeadingE = withTypography('h5', { className: 'ds-heading-e' });

HeadingE.defaultProps = {
  className: '',
};

// Also accepts all <h5 /> attributes
HeadingE.propTypes = {
  className: PropTypes.string,
};
