import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const ReverseIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path d="M3 0L6 4H4V10H2V4H0L3 0Z" fillRule="nonzero" />
      <path d="M9 16L6 12L8 12L8 6L10 6L10 12H12L9 16Z" fillRule="nonzero" />
    </g>
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default ReverseIcon;
