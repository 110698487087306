import { gql, useQuery } from '@apollo/client';
// useUser is a thin wrapper around useQuery used for querying User data. Whenever querying User data,
// we need to query the id because of uniqueness constraints. This wrapper makes sure developers don't
// forget to do that, even if they don't need the id.
export const useUser = (fragmentName, fragment, options = {}) => {
  let GET_USER_DATA = gql`
    query useUser {
      user {
        id
      }
    }
  `;

  if (fragmentName && fragment) {
    GET_USER_DATA = gql`
    query useUser {
      user {
        id
        ...${fragmentName}
      }
    }
    ${fragment}
    `;
  }

  const results = useQuery(GET_USER_DATA, options);
  return results;
};
