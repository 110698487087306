import { isomorphicGetCookie } from 'IsomorphicUtils';
import addExperimentToCookie from './addExperimentToCookie';

/**
 * Adds an experiment to the optimizelyExperiments cookie (via delegation) if the
 * user is logged out.
 *
 * @param experimentKey: the Optimizely experiment key
 * @param experimentId the Optimizely experiment id
 * @param variationKey: the Optimizely variation key
 */
const addExperimentToCookieWhenLoggedOut = (
  experimentKey: string,
  experimentId: string,
  variationKey: string
): void => {
  if (!isomorphicGetCookie('userid')) {
    addExperimentToCookie(experimentKey, experimentId, variationKey);
  }
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default addExperimentToCookieWhenLoggedOut;
