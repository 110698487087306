import { gql } from '@apollo/client';
import { useGraphQLSearch } from 'hooks/useGraphQLSearch';
import { AutoCompleteDropdownEntry } from 'DesignSystem/SearchBar/types';
import {
  documentSearchQuery_documentSearch_documents as DocumentSearchResult,
  documentSearchQuery as DocumentSearchData,
} from './types/documentSearchQuery';

export const DocumentSearchQuery = gql`
  query documentSearchQuery($searchTerm: String!) {
    documentSearch(searchTerm: $searchTerm) {
      documents {
        documentLabel
        documentName
        seoLink
      }
      totalCount
    }
  }
`;

export const isEmptyResult = (data?: DocumentSearchData): boolean =>
  !data?.documentSearch?.totalCount;

const mapToAutoCompleteEntry = (
  item: DocumentSearchResult | undefined | null
): AutoCompleteDropdownEntry<DocumentSearchResult> => ({
  content: item?.documentLabel,
  item,
  key: item?.documentName,
});

export const getAutoCompleteItems = (
  data?: DocumentSearchData
): AutoCompleteDropdownEntry<DocumentSearchResult>[] | undefined =>
  data?.documentSearch?.documents?.map(mapToAutoCompleteEntry);

export const useDocumentSearch = (page: string): void => {
  useGraphQLSearch(
    DocumentSearchQuery,
    isEmptyResult,
    `Search Document | [search terms] | ${page}`
  );
};
