import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-modal';
import { ModalCloseButton } from './ModalCloseButton';
import { ModalContent } from './ModalContent';
import styles from './FormSwiftModal.module.scss';

export const FormSwiftModal = (props) => {
  const {
    children,
    className,
    closeOnOverlayClick,
    handleCloseModal,
    isCloseButtonVisible,
    isOpen,
    mobileFriendly,
    modalContentClass,
    overlayClass,
    size,
    ...rest
  } = props;

  const modalClasses = classNames(
    styles['ds-modal-default'],
    size ? styles[`ds-${size}-modal`] : '',
    className,
    {
      [styles['ds-modal-mobile']]: mobileFriendly,
    }
  );

  const modalOverlayClasses = classNames(
    styles['ds-modal-overlay'],
    styles[`${className}-overlay`],
    overlayClass
  );

  return (
    <Modal
      ariaHideApp={false}
      className={modalClasses}
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      overlayClassName={modalOverlayClasses}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      {...rest}
    >
      <ModalContent className={modalContentClass}>
        {isCloseButtonVisible && (
          <ModalCloseButton onClick={handleCloseModal} />
        )}
        {children}
      </ModalContent>
    </Modal>
  );
};

FormSwiftModal.defaultProps = {
  aria: {},
  bodyOpenClassName: '',
  children: null,
  className: '',
  closeOnOverlayClick: true,
  isCloseButtonVisible: true,
  mobileFriendly: false,
  modalContentClass: '',
  overlayClass: '',
  size: '',
  testId: null,
};

FormSwiftModal.propTypes = {
  // aria is defined with the object prop-type in react-modal
  // http://reactcommunity.org/react-modal/accessibility/
  // eslint-disable-next-line react/forbid-prop-types
  aria: PropTypes.object,
  bodyOpenClassName: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  closeOnOverlayClick: PropTypes.bool,
  handleCloseModal: PropTypes.func.isRequired,
  isCloseButtonVisible: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  mobileFriendly: PropTypes.bool,
  modalContentClass: PropTypes.string,
  overlayClass: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', '']),
  testId: PropTypes.string,
};
