import {
  DEV_SNAPENGAGE_WIDGET_ID,
  PROD_SNAPENGAGE_WIDGET_ID,
} from 'lib/chat/constants';
import getConfig from 'next/config';

interface Config {
  publicRuntimeConfig: {
    APP_ENV: string;
  };
}

export const { publicRuntimeConfig } = getConfig() as Config;

export const getSnapEngageWidgetId = (): string => {
  if (publicRuntimeConfig.APP_ENV === 'production') {
    return PROD_SNAPENGAGE_WIDGET_ID;
  }
  return DEV_SNAPENGAGE_WIDGET_ID;
};
