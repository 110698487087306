import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TertiaryButton } from 'DesignSystem/Buttons';
import styles from './ModalTertiaryButton.module.scss';

export const ModalTertiaryButton = ({
  btnCopy,
  className,
  dataTestid,
  leadingCopy,
  onClick,
  variation,
}) => {
  const tertiaryClasses = classNames(
    styles['ds-modal-tertiary-btn'],
    className
  );

  return (
    <div>
      {leadingCopy && (
        <span className={styles['ds-modal-leading-tertiary-btn-copy']}>
          {leadingCopy}
        </span>
      )}
      <div className={styles['ds-modal-tertiary-btn-container']}>
        <TertiaryButton
          className={tertiaryClasses}
          dataTestid={dataTestid}
          onClick={onClick}
          variation={variation}
        >
          {btnCopy}
        </TertiaryButton>
      </div>
    </div>
  );
};

ModalTertiaryButton.defaultProps = {
  className: '',
  leadingCopy: '',
  variation: '',
};

ModalTertiaryButton.propTypes = {
  btnCopy: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataTestid: PropTypes.string.isRequired,
  leadingCopy: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  variation: PropTypes.oneOf(['', 'alternative']),
};
