import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

const ShareIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <path d="M12.5 4.34595V8.5C12.5 8.5 9.34799 8.14728 6.03755 9.78947C1.58142 12 1.08141 14.5 1.08141 14.5C1.08141 14.5 0.299661 10.4426 3.58142 7.5C7.69021 3.81579 12.5 4.34595 12.5 4.34595Z" />
    <path d="M16 6.49997L10.75 12L10.75 1L16 6.49997Z" />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default ShareIcon;
