import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

export interface BaseLinkProps {
  className?: string;
  children: ReactNode;
  dataTestid: string;
  href: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  target?: '' | '_blank' | '_self';
}

export const BaseLink = ({
  children,
  className = '',
  dataTestid,
  href,
  onClick,
  target = '_blank',
}: BaseLinkProps): JSX.Element => (
  <span>
    <a
      className={classNames(styles.link, className)}
      data-testid={dataTestid}
      href={href}
      onClick={onClick}
      rel="noreferrer noopener"
      target={target}
    >
      {children}
    </a>
  </span>
);
