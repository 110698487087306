import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTypes } from 'DesignSystem/Notifications/Alert';
import classNames from 'classnames';
import styles from './ModalError.module.scss';

export const ModalError = ({ children, className, errorCopy }) => (
  <Alert
    className={classNames(styles['ds-modal-error-banner'], className)}
    dataTestid="modal-error-banner"
    text={errorCopy}
    type={AlertTypes.Error}
  >
    {children}
  </Alert>
);

ModalError.defaultProps = {
  children: null,
  className: '',
  errorCopy: '',
};

ModalError.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  errorCopy: PropTypes.string,
};
