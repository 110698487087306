import React from 'react';
import BaseIcon, { BaseIconProps } from 'DesignSystem/Icons/BaseIcon';

const BoldIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <path
      clipRule="evenodd"
      d="M10.2302 13.1657C12.8648 9.86343 14 8.44054 14 5.84946C14 2.61889 11.3137 0 8 0C4.68629 0 2 2.61889 2 5.84946C2 8.66045 3.06887 9.96629 6.36916 13.9983C6.86177 14.6001 7.40409 15.2626 8 16C8.83838 14.9102 9.57995 13.9807 10.2302 13.1657ZM8 9C9.65685 9 11 7.65685 11 6C11 4.34315 9.65685 3 8 3C6.34315 3 5 4.34315 5 6C5 7.65685 6.34315 9 8 9Z"
      fillRule="evenodd"
    />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default BoldIcon;
