import React from 'react';
import { useDispatch } from 'react-redux';
import { trackEvent } from 'Tracking/Actions';
import { useRouter } from 'next/router';
import { useFeature } from '@optimizely/react-sdk';
import { Menu } from '@dropbox/dig-components/dist/menu';
import { Button } from '@dropbox/dig-components/dist/buttons';
import { PrimaryButton } from 'DesignSystem/Buttons';
import { useBuilderType } from 'hooks';
import useTrack from 'lib/tracking/useTrack';
import styles from './index.module.scss';

const FreemiumMenuCta = ({
  isCurrentSubscriber,
}: {
  isCurrentSubscriber: boolean;
}): JSX.Element => {
  const [isDropboxDesignSystemEnabled] = useFeature('dropbox_design_system');
  const { isVisualStatic } = useBuilderType();
  const dispatch = useDispatch();
  const router = useRouter();
  const track = useTrack();

  const onClick = () => {
    if (isVisualStatic) {
      const { documentType } = router?.query || {};
      const parsedDocumentType = (documentType as string) || '';
      const isMemberPage = router.pathname.endsWith('/member');
      void track({
        action: `${parsedDocumentType} | Visual Static Builder${
          isMemberPage ? ' [Member]' : ''
        }`,
        category: 'Upgrade | Account Dropdown',
      });
      if (!isMemberPage) {
        void track({
          action: `${parsedDocumentType} | Visual Static Builder`,
          category: 'No Credits Upgrade | Account Dropdown',
        });
      }
    } else {
      dispatch(trackEvent('Account Dropdown', 'Upgrade'));
    }
    void router.push('/upgrade-pricing');
  };

  if (isDropboxDesignSystemEnabled && !isCurrentSubscriber) {
    return (
      <Menu.Row>
        <Button onClick={onClick} size="medium" variant="primary">
          Upgrade
        </Button>
      </Menu.Row>
    );
  }

  return (
    <>
      {!isCurrentSubscriber && (
        <PrimaryButton
          className={`${styles['freemium-menu-cta']}`}
          dataTestid="freemium-menu-cta"
          onClick={onClick}
          size="sm"
        >
          Upgrade
        </PrimaryButton>
      )}
    </>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FreemiumMenuCta;
