import React from 'react';
import { Container } from 'react-bootstrap';
import { ThemeProvider } from '@dropbox/dig-foundations';
import filterPropsWithPropTypes from 'layouts/filterPropsWithPropTypes';
import DefaultLayout from 'layouts/DefaultLayout';
import { StatusBanner } from 'StatusBanner/StatusBanner';
import MembersAreaLayoutProps from './MembersAreaLayoutProps';
import { MembersAreaLayoutTabs } from './MembersAreaLayoutTabs';
import MembersAreaBanners from './MembersAreaBanners';

const MembersAreaLayout = ({ activeKey, children, ...rest }) => (
  <ThemeProvider
    _dangerouslyIncludeAdditionalClassName="dig-theme--FormSwift"
    mode="bright"
  >
    {({ getThemeProps }) => (
      <div {...getThemeProps()}>
        <DefaultLayout {...rest}>
          <Container
            className="no-padding"
            data-testid="members-area-layout"
            fluid
          >
            <MembersAreaBanners activeKey={activeKey} />
          </Container>
          <Container bsPrefix="container members-area-container">
            <StatusBanner />
            <MembersAreaLayoutTabs activeKey={activeKey} />
            {children}
          </Container>
        </DefaultLayout>
      </div>
    )}
  </ThemeProvider>
);

MembersAreaLayout.getLayoutProps = (props) =>
  filterPropsWithPropTypes(props, MembersAreaLayoutProps);

MembersAreaLayout.propTypes = MembersAreaLayoutProps;

MembersAreaLayout.defaultProps = {
  children: null,
  defaultDocumentTypeFromCookie: '',
  documentTypeFromUrlQuery: '',
  hideAccount: false,
  loggedIn: false,
  title: '',
  trackUnbounce: false,
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default MembersAreaLayout;
