import React from 'react';
import classnames from 'classnames';
import { BaseButton, BaseButtonProps } from './BaseButton';
import buttonStyles from './BaseButton/index.module.scss';
import { ButtonSize } from './types';

type TertiaryButtonVariation =
  | ''
  | 'alternative'
  | 'destructive'
  | 'white'
  | 'dark';

interface TertiaryButtonProps
  extends Omit<BaseButtonProps, 'buttonType' | 'size'> {
  variation?: TertiaryButtonVariation;
  size?: ButtonSize;
}

export const TertiaryButton = ({
  className = '',
  size = 'md',
  variation = '',
  ...buttonProps
}: TertiaryButtonProps): JSX.Element => {
  const classes = classnames(className, buttonStyles[variation]);
  return (
    <BaseButton
      buttonType="tertiary"
      className={classes}
      size={size}
      {...buttonProps}
    />
  );
};
