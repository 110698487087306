import { useMediaQuery } from 'react-responsive';
import { UserDevice, useSSRRenderDeviceInfo } from 'Utils/SSRDeviceContext';
import { useEffect, useState } from 'react';

export interface UseWindowSizeResult {
  isMaxWidthSm?: boolean;
  isMaxWidthXxs?: boolean;
  isMinWidthLg?: boolean;
  isMinWidthMd: boolean;
  isMinWidthXl?: boolean;
  isMobile?: boolean;
}

export const SMALL_WIDTH = 767;
export const MEDIUM_WIDTH = 768;
export const LARGE_WIDTH = 992;
export const XL_WIDTH = 1200;
export const MOBILE_WIDTH = 575;
export const XXS_WIDTH = 455;

export const useWindowSize = (): UseWindowSizeResult => {
  const ssrRenderDeviceInfo = useSSRRenderDeviceInfo();
  const mobileDevice = ssrRenderDeviceInfo.device === UserDevice.Mobile;
  const tabletDevice = ssrRenderDeviceInfo.device === UserDevice.Tablet;
  const desktopDevice = ssrRenderDeviceInfo.device === UserDevice.Desktop;

  const [size, setSize] = useState<UseWindowSizeResult>({
    isMaxWidthSm: mobileDevice,
    isMaxWidthXxs: false,
    isMinWidthLg: desktopDevice,
    isMinWidthMd: tabletDevice || desktopDevice,
    isMinWidthXl: desktopDevice,
    isMobile: mobileDevice,
  });

  const mobile = useMediaQuery({ maxWidth: MOBILE_WIDTH });
  const maxWidthSm = useMediaQuery({ maxWidth: SMALL_WIDTH });
  const maxWidthXxs = useMediaQuery({ maxWidth: XXS_WIDTH });
  const minWidthMd = useMediaQuery({ minWidth: MEDIUM_WIDTH });
  const minWidthLg = useMediaQuery({ minWidth: LARGE_WIDTH });
  const minWidthXl = useMediaQuery({ minWidth: XL_WIDTH });

  useEffect(() => {
    setSize({
      isMaxWidthSm: maxWidthSm,
      isMaxWidthXxs: maxWidthXxs,
      isMinWidthLg: minWidthLg,
      isMinWidthMd: minWidthMd,
      isMinWidthXl: minWidthXl,
      isMobile: mobile,
    });
  }, [maxWidthSm, maxWidthXxs, minWidthLg, minWidthMd, minWidthXl, mobile]);

  return size;
};
