import React from 'react';
import classNames from 'classnames';
import BaseIcon, { BaseIconProps } from './BaseIcon';
import styles from './CheckmarkIcon.module.scss';

export interface CheckmarkIconProps extends BaseIconProps {
  strokeWidth?: string;
}

const CheckmarkIcon = ({
  className,
  strokeWidth = '2',
  ...props
}: CheckmarkIconProps): JSX.Element => (
  <BaseIcon
    {...props}
    className={classNames(styles['checkmark-icon'], className)}
  >
    <line
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      x1="2"
      x2="6"
      y1="7"
      y2="12"
    />
    <line
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      x1="6"
      x2="14"
      y1="12"
      y2="3"
    />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default CheckmarkIcon;
