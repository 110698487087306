import React from 'react';
import PropTypes from 'prop-types';
import { TransitionSelector as BaseTransition } from './BaseTransition';

export const SlideOverTransition = ({ direction, ...transitionProps }) => {
  let translateX = 0;
  let translateY = 0;

  switch (direction) {
    case 'down':
      translateY = '-100%';
      break;
    case 'left':
      translateX = '100%';
      break;
    case 'right':
      translateX = '-100%';
      break;
    case 'up':
      translateY = '100%';
      break;
    default:
      break;
  }

  return (
    <BaseTransition
      transitions={{
        initial: { opacity: 1, transform: 'translate(0, 0)' },
        from: {
          opacity: 0,
          transform: `translate(${translateX}, ${translateY})`,
        },
        enter: { opacity: 1, transform: 'translate(0, 0)' },
        leave: {
          opacity: 0,
          position: 'absolute',
          transform: `translate(${translateX}, ${translateY})`,
        },
      }}
      {...transitionProps}
    />
  );
};

SlideOverTransition.defaultProps = {
  direction: 'right',
};

// All BaseTransition properties are also supported.
SlideOverTransition.propTypes = {
  direction: PropTypes.oneOf(['down', 'left', 'right', 'up']),
};
