import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'Image';
import styles from './ModalIcon.module.scss';

export const ModalIcon = ({ alt, className, src }) => {
  if (!src) {
    return null;
  }

  const iconClasses = classNames(styles['ds-modal-icon'], className);

  return (
    <div className={styles['ds-modal-icon-container']}>
      <Image alt={alt} className={iconClasses} src={src} />
    </div>
  );
};

ModalIcon.defaultProps = {
  alt: '',
  className: '',
  src: '',
};

ModalIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
};
