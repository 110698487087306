import React from 'react';
import classNames from 'classnames';
import { ProductContent } from 'DesignSystem/Typography';
import { useSearchResults } from 'DesignSystem/SearchBar';
import AutoCompleteItem, {
  IconPosition,
  IconDisplay,
} from './AutoCompleteItem';

import styles from './index.module.scss';
import { AutoCompleteDropdownEntry } from '../types';

export interface AutoCompleteDropdownProps<SearchResultType, ItemType> {
  ariaLabel: string;
  getItems: (
    results?: SearchResultType
  ) => AutoCompleteDropdownEntry<ItemType>[] | undefined;
  iconClassName?: string;
  iconDisplay?: IconDisplay;
  iconName?: string;
  iconPosition?: IconPosition;
  id: string;
  itemContainerClass?: string;
  labelId?: string;
  listClassName?: string;
  onSelect: (item: ItemType | null | undefined) => void;
  noResultsMessage: string;
  resultsRole?: string;
}

/**
 * @deprecated Please see components/DocumentLibrary/index.tsx for an example of
 * how to implement search with SearchInput
 */
const AutoCompleteDropdown = <SearchResultType, ItemType>({
  ariaLabel,
  children,
  getItems,
  iconClassName,
  iconDisplay,
  iconName,
  iconPosition,
  id,
  itemContainerClass,
  labelId,
  listClassName,
  noResultsMessage,
  onSelect,
  resultsRole,
}: React.PropsWithChildren<
  AutoCompleteDropdownProps<SearchResultType, ItemType>
>): JSX.Element => {
  const results = useSearchResults<SearchResultType>();
  const resultsList = (results && getItems?.(results)) || null;
  const listClasses = classNames(
    styles['autocomplete-dropdown-list'],
    listClassName
  );

  return (
    <ul
      aria-label={ariaLabel}
      aria-labelledby={labelId}
      className={listClasses}
      id={id}
    >
      {resultsList ? (
        resultsList.map((entry: AutoCompleteDropdownEntry<ItemType>, index) => (
          <AutoCompleteItem
            key={entry.key || ''}
            containerClass={itemContainerClass}
            entry={entry}
            iconClassName={iconClassName}
            iconDisplay={iconDisplay}
            iconName={iconName}
            iconPosition={iconPosition}
            index={index}
            isLastItem={index === resultsList.length - 1}
            onSelect={onSelect}
            role={resultsRole}
          />
        ))
      ) : (
        <ProductContent className={styles['no-search-result-message']}>
          {noResultsMessage}
        </ProductContent>
      )}
      {children}
    </ul>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default AutoCompleteDropdown;
