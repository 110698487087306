import React from 'react';
import classNames from 'classnames';
import { BaseLink, BaseLinkProps } from '../BaseLink';
import styles from './index.module.scss';

export interface SoloLinkProps extends BaseLinkProps {
  bold?: boolean;
  variation?: 'default' | 'small' | 'smallest';
}

export const SoloLink = ({
  bold = false,
  children,
  className,
  variation = 'default',
  ...linkProps
}: SoloLinkProps): JSX.Element => {
  const soloLinkClasses = classNames(
    styles['ds-solo-link'],
    {
      [styles.bold]: bold,
    },
    styles[variation],
    className
  );

  return (
    <div className={soloLinkClasses}>
      <BaseLink {...linkProps}>{children} &rsaquo;</BaseLink>
    </div>
  );
};
