import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

export const BoldClockIcon = (props: BaseIconProps): JSX.Element => (
  <BaseIcon {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      id="clock-icon"
      stroke="none"
      strokeWidth="1"
    >
      <path
        clipRule="evenodd"
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#66748A"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8 3C8.55228 3 9 3.44772 9 4V7.43381L11.0145 8.64251C11.4881 8.92666 11.6416 9.54092 11.3575 10.0145C11.0733 10.4881 10.4591 10.6416 9.9855 10.3575L7 8.56619V4C7 3.44772 7.44772 3 8 3Z"
        fill="#66748A"
        fillRule="evenodd"
      />
    </g>
  </BaseIcon>
);
