/*
  Given a dictionary (`props`) extract only the props listed in propTypes.
  Example:
    Component.propTypes = {
      a: Proptypes.string,
      b: Proptypes.bool,
    }
    const allProps = { a, b, c, d }
    filterPropsWithPropTypes(allProps, Component.propTypes) -> { a, b }
  Args:
    @props (Object): Props to filter on
    @propTypes (PropTypes): propTypes of Component
*/
const filterPropsWithPropTypes = (props, propTypes) =>
  Object.keys(propTypes).reduce((layoutProps, prop) => {
    const propValue = props[prop];
    // Only set the prop if we have a defined value. We should
    // allow falsy values like 'null', 0, and false.
    if (typeof propValue !== 'undefined') {
      // TODO: Consider fixing linting error when editing file next
      // eslint-disable-next-line no-param-reassign
      layoutProps[prop] = props[prop];
    }
    return layoutProps;
  }, {});

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default filterPropsWithPropTypes;
