import * as Types from '../../schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserSubscriptionDetailsForLayoutQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserSubscriptionDetailsForLayoutQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    hasPaidAccess?: boolean | null;
    isCancelled?: boolean | null;
    isCurrentSubscriber?: boolean | null;
    organization?: {
      __typename?: 'Organization';
      id?: number | null;
      organizationMemberDetails?: {
        __typename?: 'OrganizationMemberDetails';
        numberOfSeatsRemaining: number;
      } | null;
    } | null;
  } | null;
};

export const GetUserSubscriptionDetailsForLayoutDocument = gql`
  query getUserSubscriptionDetailsForLayout {
    user {
      id
      hasPaidAccess
      isCancelled
      isCurrentSubscriber
      organization {
        id
        organizationMemberDetails {
          numberOfSeatsRemaining
        }
      }
    }
  }
`;

/**
 * __useGetUserSubscriptionDetailsForLayoutQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionDetailsForLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionDetailsForLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionDetailsForLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSubscriptionDetailsForLayoutQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserSubscriptionDetailsForLayoutQuery,
    GetUserSubscriptionDetailsForLayoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserSubscriptionDetailsForLayoutQuery,
    GetUserSubscriptionDetailsForLayoutQueryVariables
  >(GetUserSubscriptionDetailsForLayoutDocument, options);
}
export function useGetUserSubscriptionDetailsForLayoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSubscriptionDetailsForLayoutQuery,
    GetUserSubscriptionDetailsForLayoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserSubscriptionDetailsForLayoutQuery,
    GetUserSubscriptionDetailsForLayoutQueryVariables
  >(GetUserSubscriptionDetailsForLayoutDocument, options);
}
export type GetUserSubscriptionDetailsForLayoutQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionDetailsForLayoutQuery
>;
export type GetUserSubscriptionDetailsForLayoutLazyQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionDetailsForLayoutLazyQuery
>;
export type GetUserSubscriptionDetailsForLayoutQueryResult = Apollo.QueryResult<
  GetUserSubscriptionDetailsForLayoutQuery,
  GetUserSubscriptionDetailsForLayoutQueryVariables
>;
