import React from 'react';
import { Banner } from 'DesignSystem/Banner';
import { InTextLink } from 'DesignSystem/Links';
import useTrack from 'lib/tracking/useTrack';
import { useRouter } from 'next/router';
import { TaxPageQuery } from 'VisualStaticBuilder/taxPageState/TaxPageProvider';
import styles from './index.module.scss';

export interface ReactivationBannerProps {
  trackingActionPage: string;
}

export const ReactivationBanner = ({
  trackingActionPage,
}: ReactivationBannerProps): JSX.Element | null => {
  const router = useRouter();
  const track = useTrack();

  const fireTracking = () => {
    const action = router.pathname.startsWith('/builder/tax')
      ? `${(router.query as TaxPageQuery).documentType} | ${trackingActionPage}`
      : trackingActionPage;
    void track({ action, category: 'Reactivate Banner' });
  };

  return (
    <Banner className={styles.banner} isCloseable={false}>
      <div>
        Looks like you&apos;re out of free downloads. To download this document,{' '}
        <InTextLink
          dataTestid="reactivateFreemium"
          href="/my-account?subscription_settings_scroll=1"
          onClick={fireTracking}
          target=""
        >
          reactivate
        </InTextLink>{' '}
        your paid plan today!
      </div>
    </Banner>
  );
};
