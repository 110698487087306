import PropTypes from 'prop-types';
import { withTypography } from './withTypography';

export const HeadingF = withTypography('h6', { className: 'ds-heading-f' });

HeadingF.defaultProps = {
  className: '',
};

// Also accepts all <h6 /> attributes
HeadingF.propTypes = {
  className: PropTypes.string,
};
