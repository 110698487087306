import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Banner } from '@dropbox/dig-components/banner';
import { Modal } from '@dropbox/dig-components/modal';
import { RootState } from 'store/RootState';
import {
  setReactivationModalOpen,
  setUpdatingSubscriptionModalOpen,
} from 'layouts/GlobalModals/GlobalModalsReducer';
import { useReactivateCustomerMutation } from 'lib/graphql/MyAccount/__generated__/MyAccountMutations.generated';
import useTrack from 'lib/tracking/useTrack';
import { useOptimizelyClient } from 'lib/optimizely/useOptimizelyClient';
import { ReactivatePlanDetails } from 'MyAccount/SubscriptionSection/ReactivatePlanDetailsRow';
import { ReactivateCTA } from 'components/ReactivateCTA';
import styles from './ReactivationModalRedesign.module.scss';

const PLAN_HANDLE = 'legal_monthly_12_reactivation';

export const ReactivationModalRedesign = (): JSX.Element => {
  const router = useRouter();
  const track = useTrack();
  const dispatch = useDispatch();
  const optimizely = useOptimizelyClient();
  const isReactivationModalOpen = useSelector(
    (state: RootState) => state.GlobalModalsHandler.isReactivationModalOpen
  );
  const [reactivatePlan] = useReactivateCustomerMutation();

  const [reactivationState, setReactivationState] = useState<
    'success' | 'alert' | undefined
  >();

  const handleCloseModal = () => {
    if (reactivationState) {
      void track({
        action: 'Reactivation',
        category: 'Dismissed Completed Modal v2',
      });
      router.reload();
    } else {
      void track({
        action: 'Reactivation',
        category: 'Dismissed Reactivate Modal v2',
      });
    }
    dispatch(setReactivationModalOpen(false));
  };

  const ReactivationStatusLabel = () => {
    return (
      <Banner type={reactivationState}>
        <Banner.Message>
          {reactivationState === 'success'
            ? 'Your plan has been reactivated. Enjoy!'
            : 'Something went wrong, please refresh the page and try again'}
        </Banner.Message>
      </Banner>
    );
  };

  const reactivateSubscription = async () => {
    void track({
      action: 'Reactivation',
      category: 'Clicked Reactivate within Modal v2',
    });

    dispatch(setReactivationModalOpen(false));
    dispatch(setUpdatingSubscriptionModalOpen(true));

    try {
      const response = await reactivatePlan({
        variables: { planHandle: PLAN_HANDLE },
      });

      if (response.data?.reactivateCustomer?.ok) {
        void track({
          action: 'Reactivation',
          category: 'Shown Your Reactivation is Reactivated',
        });
        await optimizely?.onReady({ timeout: 3000 });
        void optimizely?.track('reactivated_subscription');
        setReactivationState('success');
        dispatch(setUpdatingSubscriptionModalOpen(false));
        dispatch(setReactivationModalOpen(true));
      } else {
        setReactivationState('alert');
        dispatch(setUpdatingSubscriptionModalOpen(false));
        dispatch(setReactivationModalOpen(true));
      }
    } catch (e) {
      setReactivationState('alert');
      dispatch(setUpdatingSubscriptionModalOpen(false));
      dispatch(setReactivationModalOpen(true));
    }
  };

  useEffect(() => {
    if (isReactivationModalOpen && !reactivationState) {
      void track({
        action: 'Reactivation',
        category: 'Shown Modal v2',
      });
    }
  }, [isReactivationModalOpen, reactivationState, track]);

  return (
    <Modal
      ariaHideApp={false}
      fullScreen={false}
      onRequestClose={handleCloseModal}
      open={isReactivationModalOpen}
      width="standard"
      withCloseButton="close"
    >
      <Modal.Header>
        <Modal.Title
          className={styles['reactivate-modal-title-container']}
          size="medium"
          weightVariant="emphasized"
        >
          Subscription Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles['reactivate-modal-body-container']}>
          <div>
            <ReactivatePlanDetails />
            <span className={styles['section-divider']} />
          </div>
          {reactivationState ? (
            <ReactivationStatusLabel />
          ) : (
            <ReactivateCTA reactivateSubscription={reactivateSubscription} />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};
