import React from 'react';
import PropTypes from 'prop-types';
import { CloseSmallIcon } from 'DesignSystem/Icons';
import styles from './TooltipCloseButton.module.scss';

export const TooltipCloseButton = ({ dataTestid, onClick }) => (
  <CloseSmallIcon
    className={styles['ds-tooltip-close-btn']}
    dataTestid={dataTestid}
    onClick={onClick}
  />
);

TooltipCloseButton.propTypes = {
  dataTestid: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
