import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';

interface PageRightProps extends BaseIconProps {
  fill?: string;
}

const PageRight = ({ ...rest }: PageRightProps): JSX.Element => (
  <BaseIcon {...rest} height={10} width={5}>
    <polygon fillRule="evenodd" points="0 0 0 10 5 5" strokeWidth="1" />
  </BaseIcon>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default PageRight;
