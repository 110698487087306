import { gql } from '@apollo/client';

const PaidAccessFragment = gql`
  fragment PaidAccessFragment on User {
    hasPaidAccess
  }
`;
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default PaidAccessFragment;
