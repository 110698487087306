import React from 'react';
import CheckmarkIcon from './CheckmarkIcon';
import { BaseIconProps } from './BaseIcon';

/**
 * This icon should only be used with dark backgrounds,
 * the CheckmarkIcon should be used by default.
 */
const CheckmarkBoldIcon = (props: BaseIconProps): JSX.Element => (
  <CheckmarkIcon {...props} strokeWidth="3" />
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default CheckmarkBoldIcon;
