import styles from 'components/Chat/styles.module.css';
import { SNAPENGAGE_IFRAME_ORIGIN } from 'lib/chat/constants';
import {
  ChatData,
  IncomingMessage,
  SnapEngageClientProps,
} from 'lib/chat/types';
import { getSnapEngageWidgetId } from 'lib/chat/utils';

import { OutwardsMessageData, OutwardsMessageType } from './types';

/**
 * SnapEngageHandler handles communication with the SnapEngage iframe.
 *
 * It sends messages to the iFrame using postMessage.
 *
 * It subscribes to messages from the iframe to respond to SnapEngage events,
 * such as opening/closing the chat window.
 */
export class SnapEngageHandler {
  iframe: HTMLIFrameElement;

  private chatData: ChatData;

  constructor({ chatData, iframe }: SnapEngageClientProps) {
    this.iframe = iframe;
    this.chatData = chatData;

    // subscribe to messages from the iframe
    window.addEventListener('message', this.processIncomingMessage, false);

    // instruct the iframe to initialize the SnapEngage javascript library
    this.sendMessage('createSnapEngageScriptV2');
  }

  maximize = (): void => {
    this.iframe.classList.remove(styles.minimized);
    this.iframe.classList.add(styles.maximized);
  };

  minimize = (): void => {
    this.iframe.classList.remove(styles.maximized);
    this.iframe.classList.add(styles.minimized);
  };

  showChatWindow = (): void => {
    this.iframe.classList.remove(styles.hidden);
    this.iframe.classList.add(styles.visible);
  };

  processIncomingMessage = (event: IncomingMessage): void => {
    const { data, origin } = event;
    if (origin !== SNAPENGAGE_IFRAME_ORIGIN || !data) {
      return;
    }
    if (data.message_type === 'Maximize') {
      this.maximize();
      this.showChatWindow();
    } else if (data.message_type === 'Minimize') {
      this.minimize();
    } else if (data.message_type === 'Open') {
      this.showChatWindow();
    }
  };

  private sendMessage = (messageType: OutwardsMessageType): void => {
    const data: OutwardsMessageData = {
      chatData: this.chatData,
      chatId: getSnapEngageWidgetId(),
      message_type: messageType,
    };
    if (this.iframe.contentWindow) {
      this.iframe.contentWindow.postMessage(data, SNAPENGAGE_IFRAME_ORIGIN);
    }
  };
}
