import {
  ISearchContext,
  SearchFocus,
  SetFocusDown,
  SetFocusTo,
  SetFocusUp,
  SetResults,
  SetSearchMethod,
  SetSearchTerm,
  SetStatus,
  StatusType,
} from 'DesignSystem/SearchBar/types';
import { useContextSelector } from 'use-context-selector';
import { SearchContext } from 'DesignSystem/SearchBar/SearchContext/index';

export const useSearchContextSelector = <ResultType,>(
  selector: (context: ISearchContext<ResultType>) => unknown
  // If you see this ignore please consider refactoring to remove implicit any type as arguments
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
): unknown => useContextSelector(SearchContext, selector);

export const useSearchFocus = (): SearchFocus =>
  useSearchContextSelector((c) => c.searchFocus) as SearchFocus;

export const useSetFocusDown = (): SetFocusDown =>
  useSearchContextSelector((c) => c.setFocusDown) as SetFocusDown;

export const useSetFocusTo = (): SetFocusTo =>
  useSearchContextSelector((c) => c.setFocusTo) as SetFocusTo;

// This hook returns a function that dispatches the setFocusUp action in the searchContextReducer,
// This action changes the searchFocus to the input value if searchFocus is currently 0 or decrements searchFocus
// by 1 if searchFocus is an integer greater than zero. It is not intended to be called if searchFocus is
// already on the input value (because there is nothing to focus up on from the input), and it will silently
// do nothing if called from such a context.
export const useSetFocusUp = (): SetFocusUp =>
  useSearchContextSelector((c) => c.setFocusUp) as SetFocusUp;

export const useSearchMethod = (): ISearchContext<unknown>['search'] =>
  useSearchContextSelector(
    (c) => c.search
  ) as ISearchContext<unknown>['search'];

export const useSetSearchMethod = (): SetSearchMethod | undefined =>
  useSearchContextSelector((c) => c.setSearchMethod) as
    | SetSearchMethod
    | undefined;

export const useSearchResults = <ResultType,>(): ResultType | undefined =>
  useSearchContextSelector((c) => c.results) as ResultType | undefined;

export const useSetSearchResults = <ResultType,>():
  | SetResults<ResultType>
  | undefined =>
  useSearchContextSelector((c) => c.setResults) as
    | SetResults<ResultType>
    | undefined;

export const useSearchStatus = (): StatusType =>
  useSearchContextSelector((c) => c.status) as StatusType;

export const useSetSearchStatus = (): SetStatus | undefined =>
  useSearchContextSelector((c) => c.setStatus) as SetStatus | undefined;

export const useSearchTerm = (): string | undefined =>
  useSearchContextSelector((c) => c.searchTerm) as string | undefined;

export const useSetSearchTerm = (): SetSearchTerm | undefined =>
  useSearchContextSelector((c) => c.setSearchTerm) as SetSearchTerm | undefined;
