import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { HeadingF } from 'DesignSystem/Typography';
import { ModalIcon } from 'DesignSystem/FormSwiftModal/ModalIcon';
import styles from './ModalHeader.module.scss';

const getIcons = (modalIcon, iconClassName, orientation) => ({
  BottomIcon: () => {
    const bottomModalIcon = {
      ...modalIcon,
      className: classNames('ds-modal-icon-bottom', iconClassName),
    };
    return modalIcon && orientation === 'bottom' ? (
      <ModalIcon {...bottomModalIcon} />
    ) : null;
  },
  TopIcon: () => {
    const topModalIcon = {
      ...modalIcon,
      className: classNames('ds-modal-icon-top', iconClassName),
    };
    return modalIcon && orientation === 'top' ? (
      <ModalIcon {...topModalIcon} />
    ) : null;
  },
});

export const ModalHeader = (props) => {
  const {
    className,
    dataTestid,
    headerCopy,
    iconOrientation,
    modalIcon,
  } = props;

  const { BottomIcon, TopIcon } = getIcons(
    modalIcon,
    modalIcon.className,
    iconOrientation
  );

  return (
    <div>
      <TopIcon />
      {headerCopy && (
        <HeadingF
          className={`${styles['ds-modal-header']} ${className}`}
          dataTestid={dataTestid}
        >
          {headerCopy}
        </HeadingF>
      )}
      <BottomIcon />
    </div>
  );
};

ModalHeader.defaultProps = {
  className: '',
  dataTestid: '',
  headerCopy: '',
  iconOrientation: 'top',
  modalIcon: {},
};

ModalHeader.propTypes = {
  className: PropTypes.string,
  dataTestid: PropTypes.string,
  headerCopy: PropTypes.string,
  iconOrientation: PropTypes.string,
  modalIcon: PropTypes.shape({
    alt: PropTypes.string,
    className: PropTypes.string,
    src: PropTypes.string,
  }),
};
