import React from 'react';
import classNames from 'classnames';
import { BaseLink, BaseLinkProps } from '../BaseLink';
import styles from './index.module.scss';

export const InTextLink = ({
  children,
  className,
  ...linkProps
}: BaseLinkProps): JSX.Element => (
  <BaseLink
    {...linkProps}
    className={classNames(styles['in-text-link'], className)}
  >
    {children}
  </BaseLink>
);
