import React from 'react';
import { Text } from '@dropbox/dig-components/dist/typography';

export const FreemiumMenuDownloads = ({
  credits,
  isCurrentSubscriber,
}: {
  credits: number;
  isCurrentSubscriber: boolean;
}): JSX.Element => {
  let remainingDownloads;

  if (isCurrentSubscriber) {
    remainingDownloads = 'Unlimited Downloads';
  } else if (credits === 1) {
    remainingDownloads = '1 download remaining';
  } else {
    remainingDownloads = `${credits} downloads remaining`;
  }

  return (
    <Text isBold size="small" variant="label">
      {remainingDownloads}
    </Text>
  );
};
