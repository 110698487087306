import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Button } from '@dropbox/dig-components/dist/buttons';
import { Checkbox } from '@dropbox/dig-components/dist/controls';
import { Text } from '@dropbox/dig-components/dist/typography';
import { InTextLink } from 'DesignSystem/Links';
import { isomorphicGetCookie } from 'IsomorphicUtils';
import useTrack from 'lib/tracking/useTrack';
import { useOptimizelyClient } from 'lib/optimizely/useOptimizelyClient';
import {
  GetEmailRemindersDocument,
  useGetUserSubscriptionPreviewQuery,
  useGetUserSubscriptionDetailsQuery,
} from 'lib/graphql/MyAccount/__generated__/MyAccountQueries.generated';
import { useReactivateCustomerMutation } from 'lib/graphql/MyAccount/__generated__/MyAccountMutations.generated';
import {
  hideModal,
  showProgressModal,
} from 'MyAccount/MyAccountModals/Legacy/Actions';
import { useFormatUserSubscriptionPrice } from 'hooks/useFormatUserSubscriptionPrice';
import { ReactivationCTA } from './ReactivateCTA';
import styles from '../SubscriptionDetailsSection.module.scss';

const PLAN_HANDLE = 'legal_monthly_12_reactivation';

const CancelTerms = () => {
  const { data } = useGetUserSubscriptionDetailsQuery();
  const currency = data?.user?.subscription?.currency ?? 'USD';
  return (
    <>
      <InTextLink
        dataTestid="reactivation-cancel-link"
        href="https://help.formswift.com/knowledge/how-do-i-cancel-my-trial-or-membership"
      >
        cancel
      </InTextLink>
      . Charges{' '}
      <InTextLink dataTestid="reactivation-terms-link" href="/terms">
        won&apos;t be refunded
      </InTextLink>{' '}
      unless it&apos;s legally required. All amounts shown are in {currency}.
    </>
  );
};

const TaxExclusivePricing = ({
  formattedSubscriptionSubtotal,
  formattedSubscriptionTax,
  formattedSubscriptionTotal,
}: {
  formattedSubscriptionSubtotal: string;
  formattedSubscriptionTax: string;
  formattedSubscriptionTotal: string;
}) => {
  return (
    <div className={styles['reactivate-plan-agreement-terms']}>
      <Text size="large">
        {formattedSubscriptionSubtotal}/month, plus applicable taxes
      </Text>

      <Text>
        By clicking the &quot;Reactivate Now&quot; button, you agree to be
        charged {formattedSubscriptionTotal} ({formattedSubscriptionSubtotal}{' '}
        subtotal, {formattedSubscriptionTax} tax) immediately for your
        subscription, and {formattedSubscriptionSubtotal} plus applicable taxes
        per month thereafter until you <CancelTerms />
      </Text>
    </div>
  );
};

const TaxInclusivePricing = ({
  formattedSubscriptionTotal,
}: {
  formattedSubscriptionTotal: string;
}) => {
  return (
    <div className={styles['reactivate-plan-agreement-terms']}>
      <Text size="large">{formattedSubscriptionTotal}/month</Text>

      <Text>
        By clicking the &quot;Reactivate Now&quot; button, you agree to be
        charged {formattedSubscriptionTotal} immediately for your subscription,
        and every month thereafter until you <CancelTerms />
      </Text>
    </div>
  );
};

export const ReactivatePlanDetailsRow = (): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const track = useTrack();
  const optimizely = useOptimizelyClient();
  const userIdAsString: string | undefined = isomorphicGetCookie('userid');
  const userId = userIdAsString ? parseInt(userIdAsString, 10) : null;

  const [termsAgreed, setTermsAgreed] = useState({
    hideErrorMessage: true,
    isChecked: false,
  });

  const {
    data: reactivationPreviewData,
    error,
    loading,
  } = useGetUserSubscriptionPreviewQuery({
    skip: !PLAN_HANDLE,

    variables: { planHandle: PLAN_HANDLE },
  });
  const [reactivatePlan] = useReactivateCustomerMutation();

  const nextBillingManifest =
    reactivationPreviewData?.user?.subscriptionPreview?.nextBillingManifest;
  const subscriptionTax = (nextBillingManifest?.totalTaxInCents || 0) / 100;
  const subscriptionSubtotal =
    (nextBillingManifest?.subtotalInCents || 0) / 100;
  const subscriptionTotal = (nextBillingManifest?.totalInCents || 0) / 100;
  const taxExclusive = !reactivationPreviewData?.user?.subscription
    ?.taxIncluded;

  const formattedSubscriptionTax = useFormatUserSubscriptionPrice({
    omitDecimalIfWholeNumber: true,
    price: subscriptionTax,
  });
  const formattedSubscriptionSubtotal = useFormatUserSubscriptionPrice({
    omitDecimalIfWholeNumber: true,
    price: subscriptionSubtotal,
  });
  const formattedSubscriptionTotal = useFormatUserSubscriptionPrice({
    omitDecimalIfWholeNumber: true,
    price: subscriptionTotal,
  });

  const toggleCheckbox = () => {
    setTermsAgreed((state) => ({
      ...state,
      hideErrorMessage: true,
      isChecked: !state.isChecked,
    }));
  };

  const clickReactivateNow = async () => {
    if (!termsAgreed.isChecked) {
      setTermsAgreed((state) => ({ ...state, hideErrorMessage: false }));
      return;
    }
    void track({
      action: 'Account Settings',
      category: 'Reactivate Now',
    });
    dispatch(showProgressModal());

    try {
      const response = await reactivatePlan({
        refetchQueries: [
          {
            query: GetEmailRemindersDocument,
            variables: { senderUserId: userId },
          },
        ],
        variables: { planHandle: PLAN_HANDLE },
      });

      if (response.data?.reactivateCustomer?.ok) {
        await optimizely?.onReady({ timeout: 3000 });
        void optimizely?.track('reactivated_subscription');
        dispatch(hideModal());
        void router.push('/my-account?show_reactivation_success=1');
      } else {
        dispatch(hideModal());
        const errorMessage = 'Reactivation failed';
        void router.push(
          `/my-account?error=${encodeURIComponent(errorMessage)}`
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch(hideModal());
        void router.push(`/my-account?error=${encodeURIComponent(e.message)}`);
      }
    }
  };

  return (
    <div className={styles['reactivate-plan-container']}>
      <Text isBold size="xlarge">
        Reactivate Plan
      </Text>
      <div className={styles['reactivate-plan-row']}>
        <div className={styles['reactivate-plan-agreement-terms']}>
          {taxExclusive ? (
            <TaxExclusivePricing
              formattedSubscriptionSubtotal={formattedSubscriptionSubtotal}
              formattedSubscriptionTax={formattedSubscriptionTax}
              formattedSubscriptionTotal={formattedSubscriptionTotal}
            />
          ) : (
            <TaxInclusivePricing
              formattedSubscriptionTotal={formattedSubscriptionTotal}
            />
          )}
          <div className={styles['agree-to-terms-container']}>
            <Checkbox
              checked={termsAgreed.isChecked}
              data-testid="reactivate-plan-checkbox"
              onChange={toggleCheckbox}
            />
            <Text>
              I agree to the{' '}
              <InTextLink
                dataTestid="reactivation-terms-link"
                href="/terms"
                target="_self"
              >
                FormSwift Terms
              </InTextLink>{' '}
              and the <strong>Automatic Renewal Terms</strong> above. Learn
              about how we use and protect your data in our{' '}
              <InTextLink
                dataTestid="reactivation-privacy-link-"
                href="/privacy"
                target="_self"
              >
                Privacy Policy
              </InTextLink>
              .
            </Text>
          </div>
          <Text
            className={styles['terms-of-use-checkbox-error-message']}
            hidden={termsAgreed.hideErrorMessage}
            variant="label"
          >
            Please agree to the terms of service
          </Text>
          <div>
            <Button
              disabled={!!error || loading}
              onClick={clickReactivateNow}
              size="large"
              variant="primary"
            >
              Reactivate Now
            </Button>
          </div>
        </div>
        <ReactivationCTA />
      </div>
    </div>
  );
};
