import React from 'react';
import { useFeature } from '@optimizely/react-sdk';
import { Button } from '@dropbox/dig-components/buttons';
import { useRouter } from 'next/router';
import { generateQueryString } from 'Utils';

const SignInButton = () => {
  const router = useRouter();
  const [isDropboxDesignSystemEnabled] = useFeature('dropbox_design_system');
  const queryString = generateQueryString(router?.query) || ''; // fallback in case we forget to mock router.query in tests
  const signInHrefWithQuery = `/sign-in?${queryString}`;

  return isDropboxDesignSystemEnabled ? (
    <Button
      className="sign-in-button"
      hasNoUnderline
      href={signInHrefWithQuery}
      size="small"
      variant="transparent"
    >
      Sign in
    </Button>
  ) : (
    <a href={signInHrefWithQuery}>Sign-In</a>
  );
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default SignInButton;
